import { motion } from "framer-motion";
import styled from "styled-components";
import { transitionTween } from "Constants";

const ReplySubmit = styled(motion.button)`
	position: relative;
	right: 0;
	/*margin: -11px -12px -9px -12px;*/
	padding: 11px 12px 9px 12px;

	font-size: 14px;
	font-weight: 700;
	line-height: 14px;

	background: ${({ theme }) => theme.backgroundReplyButton};
	color: ${({ theme }) => theme.colorTextActive};
	border-radius: 6px;
	border: none;
	cursor: ${({ isFocused }) => (isFocused ? "pointer" : "initial")};

	opacity: 1;

	${transitionTween}

	@media (hover: hover) {
		&:hover {
			background: ${({ theme }) => theme.backgroundReplyButtonHover};
		}
	}
`;

export default ReplySubmit;
