import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { transitionTween } from 'Constants';

const Item = styled.div`
	/*width: 100%;*/
	display: inline-block;
	margin: 0 auto 8px auto;
	padding: 10px 8px 6px 8px;
	cursor: pointer;
	/*background: white;*/
`;

const ItemText = styled.h4`
	width: 100%;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	opacity: 0.45;
	opacity: ${(props) => (props.isActive ? 0.66 : 0.45)};
	/*border: 1px solid red;*/
	/*background: rgba(255, 0, 0, 0.33);*/
	${transitionTween}
`;

function SuggestionsItem({
	refsItems,
	children,
	updateSearchValue,
	isActive,
	index,
	updateActiveIndex,
	recentAction,
}) {
	const onMouseEnter = () => {
		if (recentAction == 'MOUSE') {
			updateSearchValue(children);
			updateActiveIndex(index);
		}
	};

	const handleClick = () => {
		updateActiveIndex(-1);
		// alert('yo');
	};

	// const handleClick = () => {
	// 	updateSearchValue(children);
	// 	updateActiveIndex(index);
	// };

	return (
		<Item onClick={handleClick} onMouseEnter={onMouseEnter} ref={refsItems.current[index]}>
			<Link to={`/vc/${children}`}>
				<ItemText isActive={isActive}>{children}</ItemText>
			</Link>
		</Item>
	);
}

export default SuggestionsItem;
