import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Cookies from "universal-cookie";

import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import { API_ROOT, DEVICE, transitionMotionTweenSlow } from "Constants";
import {
  ContainerReview,
  ReviewWrite,
  ReviewPreview,
  ReviewPreviewContent,
  ReviewSuccess,
} from "layout/LayoutFounderReview";
import { H1, H3, P } from "components/Typography";
import Form from "components/Form";
import InputText from "components/Input/InputText";
import InputTextSelect from "components/Input/InputTextSelect";
import InputTextarea from "components/Input/InputTextarea";
import InputSelectHorizontal from "components/Input/InputSelectHorizontal";
import InputSelectVertical from "components/Input/InputSelectVertical";
import InputPassword from "components/Input/InputPassword";
import ButtonForm from "components/Button/ButtonForm";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Spacer4, Spacer8, Spacer16 } from "components/Spacer";

import InputSuccessText from "components/Input/InputSuccessText";
import InputErrorText from "components/Input/InputErrorText";

import Review from "components/Review";

const LoginMessage = styled.div`
  margin: -16px 0 0 0;
`;

function FounderReviewPage() {
  const { currentUser } = useAuth();
  const { isFounder, isVC } = useUser();

  const [loginSuccessMessage, setLoginSuccessMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const history = useHistory();
  const cookies = new Cookies();

  // const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorObject, setErrorObject] = useState({});

  const [vc, setVC] = useState("");
  const [investor, setInvestor] = useState("");
  const [content, setContent] = useState(null);
  const [termSheet, setTermSheet] = useState("");
  const [score, setScore] = useState("");
  const [situationYear, setSituationYear] = useState("");
  const [round, setRound] = useState("");

  const [isTunnelVisioned, setIsTunnelVisioned] = useState(false);

  useEffect(() => {
    // console.log("auth logs", currentUser);

    if (!currentUser && !isFounder) {
      history.push("/founders");
    }
  }, []);

  const handleUpdate = (name, value) => {
    switch (name) {
      case "vc":
        setVC(value);
        break;
      case "investor":
        setInvestor(value);
        break;
      case "content":
        setContent(value);
        break;
      case "termSheet":
        setTermSheet(value);
        if (errorObject["termSheet"]) {
          let errorObjectCopy = errorObject;
          errorObjectCopy["termSheet"] = null;
          setErrorObject(errorObjectCopy);
        }
        break;
      case "score":
        setScore(
          value
            ? value.replace(" 적극 추천 반대", "").replace(" 적극 추천", "")
            : value
        );
        if (errorObject["score"]) {
          let errorObjectCopy = errorObject;
          errorObjectCopy["score"] = null;
          setErrorObject(errorObjectCopy);
        }
        break;
      case "situationYear":
        setSituationYear(value);
        break;
      case "round":
        setRound(value);
        if (errorObject["round"]) {
          let errorObjectCopy = errorObject;
          errorObjectCopy["round"] = null;
          setErrorObject(errorObjectCopy);
        }
        break;
      default:
        break;
    }
  };

  const handleSubmit = (formData) => {
    currentUser.getIdToken().then((IdToken) => {
      console.log("handleSubmit, IdToken: ", IdToken);
      axios
        .post(`${API_ROOT()}/reviews/write`, formData, {
          headers: { Authorization: `Bearer ${IdToken}` },
        })
        .then(function (response) {
          setHasSubmitted(true);
          console.log("response", response);
          return response.data;
        })
        .catch(function (error) {
          console.log("error", error.response.data);
          if (error.response && error.response.data) {
            setErrorObject(error.response.data);
          }

          return { error: true };
        });
    });
  };

  const resetForm = () => {
    setHasSubmitted(false);
    setVC("");
    setInvestor("");
    setContent(null);
    setTermSheet("");
    setScore("");
    setSituationYear("");
    setRound("");
  };

  return (
    <>
      <AnimatePresence>
        {!hasSubmitted && (
          <ContainerReview
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={transitionMotionTweenSlow}
          >
            <ReviewWrite>
              <H1>VC 리뷰 작성하기</H1>
              <P>
                당신이 남기는 리뷰는 스타트업계 내 창업자와 투자사간의 정보의
                비대칭성 해소에 큰 도움이 됩니다.
              </P>
              <P>
                리뷰는 직접 경험하신 투자사들에 한해, 최대한 솔직하고 구체적으로
                기입해주세요. 선후배 창업가들이 실질적인 도움을 얻기 위함이므로
                감정적이고 사실에 기반하지 않은 리뷰는 삼가주시기 바랍니다.
                욕설과 스팸 리뷰들은 간단한 검수 후 누락될 수 있음을 미리 알려
                드립니다.
              </P>
              <P>모든 리뷰는 익명으로 게시됩니다.</P>
              <Spacer16 />
              {/* <Form onSubmit="/review/write"> */}
              <Form handleSubmit={handleSubmit}>
                <InputTextSelect
                  name="vc"
                  label="VC / 펀드 명 / 엔젤 이름"
                  placeholder="누구 벤처스"
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  onTunnelVision={() => setIsTunnelVisioned(true)}
                  onTunnelVisionOff={() => setIsTunnelVisioned(false)}
                  required
                />
                <InputText
                  name="investor"
                  label="담당 심사역 / 파트너 이름"
                  description="사이트에 공개 되지 않습니다. 허위 사실 방지를 위해 이 정보를 수집하고 있습니다."
                  placeholder="김누구"
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  isOffFocus={isTunnelVisioned}
                />
                <InputTextarea
                  name="content"
                  label="리뷰 작성"
                  description="이 투자사(자)에 대해 다른 창업자들에게 어떤 조언을 해주고 싶으신가요?"
                  placeholder="인신 공격이나 비하발언은 자제해주세요."
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  isOffFocus={isTunnelVisioned}
                  required
                />
                <InputSelectHorizontal
                  name="termSheet"
                  label="투자 상황"
                  placeholder="이 VC와 투자협상 (텀시트 조정)을 진행하였나요?"
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  required
                  data={[
                    { text: "텀시트 받음", value: "Yes" },
                    { text: "못 받음", value: "No" },
                  ]}
                  isPrivateEnabled
                  error={errorObject["termSheet"]}
                  isOffFocus={isTunnelVisioned}
                />
                <InputSelectVertical
                  name="score"
                  label="별점"
                  description="다른 창업자에게 이 VC를 추천하시겠어요?"
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  data={[
                    { text: "1 적극 추천 반대", value: "1" },
                    { text: "2", value: "2" },
                    { text: "3", value: "3" },
                    { text: "4", value: "4" },
                    { text: "5 적극 추천", value: "5" },
                  ]}
                  error={errorObject["score"]}
                  isOffFocus={isTunnelVisioned}
                  required
                />
                <InputText
                  name="situationYear"
                  label="상황 시기"
                  description="러프하게라도 남겨 주시면 선후배 창업자들에게 도움이 됩니다."
                  placeholder="2018년 여름"
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  isOffFocus={isTunnelVisioned}
                  isPrivateEnabled
                  required
                />
                <InputSelectVertical
                  name="round"
                  label="투자 라운드"
                  description="어떤 투자 라운드에서 이 투자사를 만나셨나요?"
                  isTransparent={true}
                  updateOnChange={handleUpdate}
                  // data={[
                  //   { text: '엔젤', value: 'angel' },
                  //   { text: '시드', value: 'seed' },
                  //   { text: '시리즈 A', value: 'series a' },
                  //   { text: '시리즈 B', value: 'series b' },
                  //   { text: '시리즈 C', value: 'series c' },
                  //   { text: '기타', value: 'other' },
                  // ]}
                  data={[
                    { text: "엔젤", value: "엔젤" },
                    { text: "시드", value: "시드" },
                    { text: "시리즈 A", value: "시리즈 A" },
                    { text: "시리즈 B", value: "시리즈 B" },
                    { text: "시리즈 C", value: "시리즈 C" },
                    { text: "기타", value: "기타" },
                  ]}
                  isOffFocus={isTunnelVisioned}
                  isPrivateEnabled
                  error={errorObject["round"]}
                  required
                />
                <ButtonForm action="submit" isOffFocus={isTunnelVisioned}>
                  리뷰 제출하기
                </ButtonForm>
              </Form>
            </ReviewWrite>
            <ReviewPreview>
              <ReviewPreviewContent>
                <H3 opacity={0.5}>
                  제출 후 누구머니 사이트에서는 이렇게 보입니다.
                </H3>
                <Spacer16 />
                <H1 opacity={vc && vc != "" ? 1 : 0.33}>
                  {vc && vc != "" ? vc : "VC 이름"}
                </H1>
                <Review
                  score={score && score != "" ? score : "?"}
                  isTrending={false}
                  content={
                    content && content != ""
                      ? content
                      : "리뷰를 작성하시면 여기에 보입니다"
                  }
                  isContentPlaceholder={content == null || content == ""}
                  termSheet={termSheet}
                  round={round}
                  situationYear={situationYear}
                  isExpanded={true}
                  isManipulatable={true}
                />
              </ReviewPreviewContent>
            </ReviewPreview>
          </ContainerReview>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {hasSubmitted && (
          <ContainerReview
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={transitionMotionTweenSlow}
          >
            <ReviewSuccess>
              <H1>
                리뷰를 작성해주셔서 감사합니다.
                <br />
                귀하가 남겨주신 리뷰는 현재와 미래의 선후배 창업자들을 위해 큰
                도움이 됩니다.
              </H1>
              {/* <P> */}
              {/*   누구머니는 특정 투자사와 관계가 없으며, 최근에 늘어난 욕설, 악플과 스팸 리뷰들을 필터하기 위해 기본적인 검수만을 진행하고 등록됩니다. */}
              {/* </P> */}
              <P>
                혹시 누구머니를 위한 피드백 있으신가요?{" "}
                <a href="mailto:founder@nugu.money">founder@nugu.money</a>에
                남겨 주십시오.
              </P>
              <P>감사합니다.</P>
              <Spacer16 />
              <ButtonPrimary onClick={resetForm}>
                리뷰 더 작성하기
              </ButtonPrimary>
            </ReviewSuccess>
          </ContainerReview>
        )}
      </AnimatePresence>
    </>
  );
}

export default FounderReviewPage;
