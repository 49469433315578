import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_ROOT, DEVICE } from "Constants";

import { useAuth } from "contexts/AuthContext";
import { useRating } from "contexts/RatingContext";

import ReviewRateHelpful from "./ReviewRateHelpful";
import ReviewRateUnhelpful from "./ReviewRateUnhelpful";

const ContainerReviewRate = styled.div`
	display: inline-grid;
	/*grid-auto-flow: column;*/
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 16px;
	padding: 8px 0 0 0;
	width: 100%;
`;

function ReviewRate({ reviewId }) {
	const { currentUser, fingerprint } = useAuth();
	const { userRatings, setUserRatings } = useRating();

	const [rating, setRatingState] = useState(userRatings[reviewId] ?? "");

	useEffect(() => {
		setRatingState(userRatings[reviewId] ?? "");
	}, [userRatings]);

	const setRating = (newRating) => {
		let finalRating = rating == newRating ? "" : newRating;
		setRatingState(finalRating);

		axios
			.post(`${API_ROOT()}/user/rateReview`, {
				uuid: currentUser ? currentUser.uid : fingerprint,
				reviewId: reviewId,
				rating: finalRating,
			})
			.then((res) => {})
			.catch((err) => {});

		let tempUserRatings = {};
		Object.assign(tempUserRatings, userRatings);
		tempUserRatings[reviewId] = finalRating;

		setUserRatings(tempUserRatings);
	};

	return (
		<ContainerReviewRate>
			<ReviewRateHelpful rating={rating} setRating={setRating} />
			<ReviewRateUnhelpful rating={rating} setRating={setRating} />
		</ContainerReviewRate>
	);
}

export default ReviewRate;
