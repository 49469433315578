import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { auth } from "services/firebase";

import { useAuth } from "contexts/AuthContext";
import { API_ROOT } from "Constants";

const RatingContext = React.createContext();

export function useRating() {
  return useContext(RatingContext);
}

export function RatingProvider({ children }) {
  const { currentUser, fingerprint } = useAuth();
  const [userRatings, setUserRatings] = useState({});

  useEffect(() => {
    // console.log(
    //   "GetRated",
    //   currentUser ? currentUser.uid : fingerprint,
    //   fingerprint,
    //   `${API_ROOT()}/user/getRated?uuid=${
    //     currentUser ? currentUser.uid : fingerprint
    //   }`
    // );

    axios
      .get(
        `${API_ROOT()}/user/getRated?uuid=${
          currentUser ? currentUser.uid : fingerprint
        }`
      )
      .then(function (response) {
        // console.log("loadedData", response.data, response.data.ratedReviews);
        setUserRatings(response.data.ratedReviews ?? {});
      })
      .catch(function (error) {});
  }, [currentUser, fingerprint]);

  const value = {
    userRatings,
    setUserRatings,
  };

  return (
    <RatingContext.Provider value={value}>{children}</RatingContext.Provider>
  );
}
