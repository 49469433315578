import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { stylesNoSelect, transitionCurveMotion } from "Constants";
// import {Colors} from '/constants';

const ContainerExpandCollapse = styled.div`
	position: relative;
	width: 100%;
	margin: 8px 0 0 0;
	padding: 8px 0 4px 0;
	${stylesNoSelect}
`;

const ContentCutoff = styled.div`
	position: absolute;
	top: calc(-27px * 3);
	left: 0;
	display: ${(props) => (props.shouldCutoff ? "block" : "none")};
	width: 100%;
	height: calc(27px * 3);
	background: ${({ theme }) => theme.gradientContentCutoff};
	opacity: ${(props) => (props.hasExpanded ? 0 : 1)};
	pointer-events: none;
	${transitionCurveMotion}
`;

const ExpandCollapse = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 32px;
	background: ${({ theme }) => theme.backgroundCardAction};
	border: 0;
	border-radius: 8px;
	outline: none;
	overflow: hidden;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			background: ${({ theme }) => theme.backgroundCardActionHover};

			.container__icon {
				transform: ${(props) =>
					props.hasExpanded
						? "rotate(180deg) translateY(2px)"
						: "rotate(0deg) translateY(2px)"};
			}
		}
	}

	${transitionCurveMotion}
`;

const ExpandCollapseContent = styled.div`
	transform: ${(props) =>
		props.hasExpanded ? "translateY(-25%)" : "translateY(25%)"};
	/*background: rgba(255, 0, 0, 0.1);*/

	${transitionCurveMotion}
`;

const ExpandCollapseText = styled.h4`
	height: 32px;
	margin: 0;
	padding: 1px 0 0 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 32px;
	opacity: 0.75;
	text-align: right;
	color: ${({theme}) => theme.colorText};
`;

const ExpandCollapseIcon = styled.div`
	width: 16px;
	height: 16px;
	margin: 0 0 0 8px;
	padding: 0;
	opacity: 0.8;
	transform: ${(props) =>
		props.hasExpanded
			? "rotate(180deg) translateY(0px)"
			: "rotate(0deg) translateY(0px)"};
	${transitionCurveMotion}
`;

function ReviewExpandCollapse({ hasExpanded, setHasExpanded, shouldCutoff }) {
	const theme = useTheme();

	return (
		<ContainerExpandCollapse onClick={() => setHasExpanded(!hasExpanded)}>
			<ContentCutoff
				hasExpanded={hasExpanded}
				shouldCutoff={shouldCutoff}
			/>
			<ExpandCollapse hasExpanded={hasExpanded}>
				<ExpandCollapseContent hasExpanded={hasExpanded}>
					<ExpandCollapseText>더보기</ExpandCollapseText>
					<ExpandCollapseText>접기</ExpandCollapseText>
				</ExpandCollapseContent>
				<ExpandCollapseIcon
					hasExpanded={hasExpanded}
					className="container__icon"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="none"
						viewBox="0 0 16 16"
					>
						<path
							stroke={theme.colorText}
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M4 6l4 4 4-4"
							opacity="0.8"
						/>
					</svg>
				</ExpandCollapseIcon>
			</ExpandCollapse>
		</ContainerExpandCollapse>
	);
}

export default ReviewExpandCollapse;
