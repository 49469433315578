import styled from 'styled-components';
import { DEVICE } from 'Constants';

const ContainerVCHeader = styled.div`
	/*display: flex;
	flex-direction: column;
	align-items: flex-end;*/

	display: flex;
	flex-direction: ${(props) => (props.context == 'SEARCH_PREVIEW' ? 'row' : 'column')};
	align-items: flex-start;
	width: 100%;
	height: auto;
	max-width: 536px;
	margin: 0 0 24px 0;
	padding: 0;

	@media (min-width: ${DEVICE.minTablet}) {
		/*position: sticky;
		top: calc(186px + 16px);*/
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		position: ${(props) => (props.context == 'SEARCH_PREVIEW' ? 'relative' : 'sticky')};
		/*top: 186px;*/
		top: ${(props) => (props.context == 'SEARCH_PREVIEW' ? 0 : 'calc(142px + 16px)')};
		/*top: calc(186px + 20px);*/ /*?????? overshoot?*/

		max-width: ${(props) => (props.context == 'SEARCH_PREVIEW' ? '100%' : 'calc(266px + 32px)')};
		align-items: flex-end;
		padding: 8px 32px 0 0;
		/*background: rgba(0, 0, 255, 0.3);*/
	}
`;

export default ContainerVCHeader;
