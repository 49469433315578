import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const ReviewContentTextGhost = styled.span`
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 27px;
	opacity: 0;
	white-space: pre-wrap;
	word-break: break-all;

	${stylesNoSelect}
`;

export default ReviewContentTextGhost;