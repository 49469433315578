import { useState } from "react";
import styled, { useTheme } from "styled-components";
import NavbarCTAButton from "./NavbarCTAButton";
import NavbarCTAText from "./NavbarCTAText";
import NavbarCTAIcon from "./NavbarCTAIcon";
import { DEVICE } from "Constants";

function NavbarCTA({
	shouldRenderMenu,
	isDarkModeNavbar,
	isDarkModeMenu,
	text,
}) {
	const [isHovering, setIsHovering] = useState(false);

	const theme = useTheme();

	return (
		<NavbarCTAButton
			isDarkModeNavbar={isDarkModeNavbar}
			isDarkModeMenu={isDarkModeMenu}
		>
			{/* <NavbarCTAButton isDarkModeNavbar={isDarkModeNavbar} isDarkModeMenu={isDarkModeMenu}> */}
			{/* <NavbarCTAText> */}
			<NavbarCTAText
				isDarkModeNavbar={isDarkModeNavbar}
				isDarkModeMenu={isDarkModeMenu}
			>
				{text}
			</NavbarCTAText>
			<NavbarCTAIcon>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="17"
					fill="none"
					viewBox="0 0 16 17"
				>
					<path
						stroke={
							isDarkModeNavbar || isDarkModeMenu
								? theme.colorBase
								: theme.colorBaseOpposite
								// ? '#000'
								// : '#fff'
						}
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M7.333 3.167H2.667A1.333 1.333 0 001.333 4.5v9.333a1.333 1.333 0 001.334 1.334H12a1.333 1.333 0 001.333-1.334V9.167"
					/>
					<path
						stroke={
							isDarkModeNavbar || isDarkModeMenu
								? theme.colorBase
								: theme.colorBaseOpposite
								// ? '#000'
								// : '#fff'
						}
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M12.333 2.167a1.414 1.414 0 112 2L8 10.5l-2.667.667L6 8.5l6.333-6.333z"
					/>
				</svg>
			</NavbarCTAIcon>
		</NavbarCTAButton>
	);
}

export default NavbarCTA;
