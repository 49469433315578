import { motion } from 'framer-motion';
import styled from 'styled-components';

const ReplyInput = styled(motion.textarea)`
	flex: 1;
	width: 100%;
	/*height: 48px;*/
	margin: 0;
	padding: 16px 16px 0 16px;

	background: transparent;
	color: ${({theme}) => theme.colorTextActive};
	border: none;
	outline: none;

	font-size: 14px;
	line-height: 14px;
	line-height: 20px;
	/*resize: vertical;*/
	resize: none;
`;

export default ReplyInput;
