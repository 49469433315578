import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { H5, H6 } from 'components/Typography';
import { Spacer8 } from 'components/Spacer';

import Input from '../Input';
import InputLabel from '../InputLabel';
import InputField from '../InputField';
import InputError from '../InputError';
import InputErrorText from '../InputErrorText';
import SubmitPassword from './SubmitPassword';

function InputPassword({
	name,
	description,
	label,
	placeholder,
	defaultValue,
	onChange,
	updateOnChange,
	onFocus,
	onBlur,
	index,
	isFaded,
	required,
	error,
	disabled,
	allowChangePassword,
	isTransparent,
	validate,
	isPrivate,
}) {
	const [value, setValue] = useState(defaultValue);
	const [delayedError, setDelayedError] = useState(error);
	const [valueOnDelayedError, setValueOnDelayedError] = useState(value);
	const [isHovering, setIsHovering] = useState(false);
	const [isPasswordVisible, setIsPasswordVisible] = useState(true);

	useEffect(() => {
		onChangeHelper(defaultValue);
	}, []);

	useEffect(() => {
		if (error != '' && error != null) {
			setDelayedError(error);
			setValueOnDelayedError(value);
		}
	}, [error]);

	const onChangeHelper = (newValue) => {
		setValue(newValue);
		onChange(name, newValue);
		if (updateOnChange) updateOnChange(name, isPrivate ? '비공개' : newValue);
	};

	const onMouseOver = () => {
		setIsHovering(true);
		// console.log('mouse enter input field');
	};

	const onMouseLeave = () => {
		setIsHovering(false);
		// console.log('mouse LEAVE input field');
	};

	const onToggleIsPasswordVisible = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	return (
		<Input isFaded={isFaded}>
			<InputLabel error={error} required={required}>
				<H5
					opacity={0.5}
					// opacity={error ? 1 : 0.5}
					// color={error && theme.colors.colorRed}
				>
					{label}
				</H5>
			</InputLabel>
			<InputField
				type={isPasswordVisible ? 'text' : 'password'}
				placeholder={placeholder}
				onChange={(e) => onChangeHelper(e.target.value)}
				onFocus={() => onFocus(index)}
				onBlur={() => onBlur(index)}
				// onMouseEnter={onMouseEnter}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				isHovering={isHovering}
				value={value}
				required={required}
				isFaded={isFaded}
				error={error != null && error != '' && valueOnDelayedError == value}
				disabled={disabled}
				defaultValue={defaultValue}
				isTransparent={isTransparent}
				alignment="center"
			/>
			{description && (
				<>
					<Spacer8 />
					<H6 opacity={0.5}>{description}</H6>
				</>
			)}
			<SubmitPassword value={value} isHovering={isHovering} validate={validate}>
				로그인 하기
			</SubmitPassword>
			<InputError error={error} alignment="center">
				<InputErrorText error={error}>{delayedError}</InputErrorText>
			</InputError>
		</Input>
	);
}

export default InputPassword;
