import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { H5 } from 'components/Typography';
import { stylesNoSelect, transitionTween, COLOR } from 'Constants';

import Input from '../Input';
import InputLabel from '../InputLabel';
import InputDescription from '../InputDescription';
import InputField from '../InputField';
import InputError from '../InputError';
import InputErrorText from '../InputErrorText';
import InputPrivate from '../InputPrivate';

import ContainerSelect from './ContainerSelect';
import SelectOption from './SelectOption';
import SelectOptionRadio from './SelectOptionRadio';
import SelectOptionText from './SelectOptionText';

function InputSelectHorizontal({
	name,
	label,
	description,
	data,
	error,
	onChange,
	updateOnChange,
	defaultValue,
	isTransparent,
	isFaded,
	required,
	isPrivateEnabled,
	isOffFocus
}) {
	const [value, setValue] = useState(defaultValue);
	const [valueIndex, setValueIndex] = useState(defaultValue);
	const [delayedError, setDelayedError] = useState(error);
	const [valueOnDelayedError, setValueOnDelayedError] = useState(value);
	const [isPrivate, onChangePrivate] = useState(false);

	useEffect(() => {
		onChangeHelper(defaultValue);
	}, []);

	useEffect(() => {
		if (error != '' && error != null) {
			setDelayedError(error);
			setValueOnDelayedError(value);
		}
	}, [error]);

	useEffect(() => {
		onChangeHelper(value, valueIndex, true);
	}, [isPrivate]);

	const onChangeHelper = (newValue, index, hasPrivateChanged) => {
		if (newValue == value && !hasPrivateChanged) {
			newValue = null;
			index = -1;
		}

		setValue(newValue);
		setValueIndex(index);
		onChange(name, valueForText(newValue) + (isPrivate ? ';private/' : ''));
		if (updateOnChange) updateOnChange(name, isPrivate ? '비공개' : newValue);
	};

	const valueForText = (text) => {
		let itemValue = text;
		data.map((item, index) => {
			if (item.text == text) itemValue = item.value;
		});
		return itemValue;
	};

	return (
		<Input isFaded={isFaded} isOffFocus={isOffFocus}>
			<InputLabel required={required}>
				<H5 opacity={0.66} isLabel={true}>
					{label}
				</H5>
			</InputLabel>
			{description && <InputDescription>{description}</InputDescription>}
			<ContainerSelect>
				{data.map((option, index) => (
					<SelectOption
						isTransparent={isTransparent}
						isSelected={index == valueIndex}
						onClick={() => onChangeHelper(option.text, index, false)}
					>
						<SelectOptionRadio isSelected={index == valueIndex} />
						<SelectOptionText>{option.text}</SelectOptionText>
					</SelectOption>
				))}
			</ContainerSelect>
			{isPrivateEnabled && <InputPrivate isPrivate={isPrivate} onChangePrivate={onChangePrivate} />}
			<InputError error={error}>
				<InputErrorText error={error}>{delayedError}</InputErrorText>
			</InputError>
		</Input>
	);
}

export default InputSelectHorizontal;
