import styled from 'styled-components';

const MenuContentItemText = styled.h1`
  flex: 0 auto;

  margin: 0;
  padding: 0 24px 0 0;

  color: ${({theme}) => theme.colorBaseOpposite};

  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
`;

export default MenuContentItemText;