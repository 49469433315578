import styled from 'styled-components';
import Cookies from 'universal-cookie';

import ContainerVC from './ContainerVC';
import VCHeader from './VCHeader';
import VCContent from './VCContent';
import Review from 'components/Review';
import ReviewPlaceholder from 'components/Review/ReviewPlaceholder';
import NoReview from 'components/Review/NoReview';

function VC({ vc, count, score, dataReviews, hasDataSet }) {
	return (
		<ContainerVC>
			{hasDataSet && (dataReviews && dataReviews.length == 0) ? (
				<NoReview vc={vc} />
			) : (
				<>
					<VCHeader vc={vc} count={count} score={score} />
					<VCContent>
						{dataReviews && dataReviews.length > 0 ? (
							dataReviews.map((review, index) => (
								<Review
									key={`${vc.replace(' ', '')}-${index}`}
									vc={vc}
									// isReplyable={isReplyable}
									{...review}
								/>
							))
						) : (
							<>
								<ReviewPlaceholder />
								<ReviewPlaceholder />
								<ReviewPlaceholder />
								<ReviewPlaceholder />
								<ReviewPlaceholder />
							</>
						)}
					</VCContent>
				</>
			)}
		</ContainerVC>
	);
}

export default VC;