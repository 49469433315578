import styled from "styled-components";
import { stylesNoSelect, transitionTween, COLOR } from "Constants";

// background: ${props =>
// 	props.disabled ? "rgba(255, 255, 255, 0.12)" : "rgba(255, 255, 255, 0)"};

const SelectOption = styled.div`
	max-width: ${(props) => (props.maxWidth ? "672px" : "100%")};

	display: flex;
	align-items: center;

	width: 100%;
	height: 44px;

	margin: 0 0 8px 0;
	padding: 0 22px 0 16px;

	font-size: 15px;
	line-height: 27px;
	font-weight: 400;

	background: ${({ theme }) => theme.backgroundCard};
	color: ${(props) =>
		props.disabled
			? props.theme.colorTextFaded
			: props.theme.colorTextHalf};

	${(props) =>
		props.disabled &&
		props.defaultValue != null &&
		`background: ${props.theme.backgroundCard} !important;`}

	border: ${(props) =>
		props.error
			? `1px solid ${COLOR.NEGATIVE}`
			: props.disabled
			? `1px solid ${props.theme.colorBorderInputDisabled}`
			: `1px solid ${props.theme.colorBorderInputTransparent}`};

	${(props) =>
		props.isSelected &&
		`
			background: ${props.theme.backgroundInputSelected};
			border: 1px solid ${props.theme.colorBorderSelectInput} !important;
			color: ${props.theme.colorBaseOpposite} !important;
		`};

	border-radius: 8px;
	outline: none;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

	@media (hover: hover) {
		&:hover {
			background: ${(props) =>
				props.isTransparent
					? props.theme.backgroundSelectInputHoverTransparent
					: props.theme.backgroundSelectInputHover};
			border: ${({ theme }) =>
				`1px solid ${theme.colorBorderSelectInputHover}`};

			${(props) =>
				props.isSelected &&
				`
					background: ${props.theme.backgroundInputSelected};
					border: 1px solid ${props.theme.colorBorderSelectInput} !important;
					color: ${props.theme.colorBaseOpposite} !important;
				`};
		}
	}

	${stylesNoSelect}
	${transitionTween}

	&:last-of-type {
		margin: 0;
	}
`;

export default SelectOption;
