import Placeholder from './Placeholder';
import PlaceholderText from './PlaceholderText';

function ReviewPlaceholder() {
	return (
		<Placeholder>
			<PlaceholderText line={0} />
			<PlaceholderText line={1} />
			<PlaceholderText line={2} />
			<PlaceholderText line={3} />
			<PlaceholderText line={4} />
			<PlaceholderText line={5} />
			<PlaceholderText line={6} />
			<PlaceholderText line={7} />
		</Placeholder>
	);
}

export default ReviewPlaceholder;