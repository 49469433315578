import styled from 'styled-components';

const MenuDescription = styled.div`
	max-width: 540px;
	width: 100%;
	margin: 0 auto;
	padding: 0 16px 72px 16px;
	color: ${({theme}) => theme.colorBaseOpposite};
`;

export default MenuDescription;
