import styled from 'styled-components';
import { transitionCurveMotion, DEVICE } from '../../Constants';

const ReviewHeaderVC = styled.h2`
	display: inline-block;
	margin: 0 0 8px 0;
	padding: 0;
	color: ${(props) => (props.isHovering ? props.theme.colorTextActive : props.theme.colorTextNormal)};
	font-size: 22px;
	font-weight: 400;
	line-height: 28px;
	cursor: pointer;
	${transitionCurveMotion}

	@media (min-width: ${DEVICE.minDesktop}) {
		font-size: 32px;
		line-height: 40px;
		/*background: rgba(0, 255, 0, 0.3);*/
	}
`;

export default ReviewHeaderVC;
