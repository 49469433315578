import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { motion, useAnimation } from "framer-motion";
import {
	transitionMotionSpring,
	transitionCurveMotion,
	stylesNoSelect,
} from "../../../Constants";

const ButtonShare = styled.div`
	flex: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	max-width: 144px;
	width: 100%;
	height: 44px;
	margin: 8px 0 0 0;
	background: ${(props) =>
		props.active ? props.theme.backgroundCardButton : props.theme.backgroundTransparent};
	border-radius: 8px;
	cursor: pointer;
	border: ${({theme}) => `1px solid ${theme.backgroundCardButton}`};

	overflow: hidden;

	${transitionCurveMotion}
	${stylesNoSelect}

	@media (hover: hover) {
		&:hover {
			background: ${(props) =>
				props.active ? props.theme.backgroundCardButton : props.theme.backgroundCardButtonOnewayHover};
		}
	}
`;

const ShareIcon = styled.div`
	margin: 2px 8px 0 0;
`;

const WrapperShareText = styled(motion.div)`
	display: flex;
	flex-direction: column;
	height: 44px;
`;

const ShareText = styled.h4`
	margin: 0;
	padding: 0;
	/*padding: 2px 0 0 0;*/
	font-size: 14px;
	font-weight: 400;
	line-height: 44px;
	opacity: 0.66;
	${transitionCurveMotion}

	@media (hover: hover) {
		&:hover {
			opacity: 0.75;
		}
	}
`;

function ShareReviewButton({ reviewId }) {
	const animateIcon = useAnimation();
	const animateText = useAnimation();
	const theme = useTheme();

	const onCopy = () => {
		navigator.clipboard.writeText(`https://nugu.money/reviews/${reviewId}`);

		animateIcon.start({ scale: 1.3, rotate: 15 });
		animateText.start({ y: -44 });
		// animateIcon.start({ scale: 1.3, y: -2 });

		setTimeout(() => {
			animateIcon.start({ scale: 1, rotate: 0 });
			// animateIcon.start({ scale: 1, y: 0 });
		}, 400);

		setTimeout(() => {
			animateText.start({ y: -88 });
		}, 1000);

		setTimeout(() => {
			animateText.start({
				y: 0,
				transition: { type: "ease", duration: 0 },
			});
		}, 1450);
	};

	return (
		<ButtonShare onClick={onCopy}>
			<ShareIcon>
				<motion.div
					animate={animateIcon}
					transition={transitionMotionSpring}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="none"
						viewBox="0 0 16 16"
					>
						<g
							stroke={theme.colorText}
							strokeLinecap="round"
							strokeLinejoin="round"
							opacity="0.75"
						>
							<path d="M2.5 11.25A1.75 1.75 0 01.75 9.5V2.75a2 2 0 012-2H9.5c.966 0 1.75.784 1.75 1.75"></path>
							<path d="M13.25 4.75h-6.5a2 2 0 00-2 2v6.5a2 2 0 002 2h6.5a2 2 0 002-2v-6.5a2 2 0 00-2-2z"></path>
						</g>
					</svg>
				</motion.div>
			</ShareIcon>
			<WrapperShareText
				initial={{ y: 0 }}
				animate={animateText}
				transition={transitionMotionSpring}
			>
				<ShareText>링크 복사하기</ShareText>
				<ShareText>복사 완료!</ShareText>
				<ShareText>링크 복사하기</ShareText>
			</WrapperShareText>
		</ButtonShare>
	);
}

export default ShareReviewButton;
