import styled from 'styled-components';
import { DEVICE } from 'Constants';

const NavbarLogo = styled.div`
	position: relative;
	z-index: 9999999;
	width: auto;
	margin: 10px 0 0 0;
	transform: scale(0.8125);
	transform-origin: 0 0;

	@media (min-width: ${DEVICE.minDesktop}) {
		flex: 0 calc(104px + 76px);
		padding: 0 76px 0 0;
		transform: scale(1);
	}
`;

export default NavbarLogo;
