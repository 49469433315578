import styled from 'styled-components';

const MenuContent = styled.div`
	max-width: 540px;
	width: 100%;
	margin: 0 auto;
	padding: 144px 16px 0 16px;

	// Link > motion.div > MenuContentItem
	a:last-of-type > div > div {
		margin: 0;
	}
`;

export default MenuContent;
