import styled, { css } from 'styled-components';
import { transitionTween } from 'Constants';

const Input = styled.div`
	position: relative;
	margin: 0 0 40px 0;
	opacity: ${(props) => (props.isFaded ? 0.4 : 1)};

	${({isOffFocus}) => isOffFocus && css`
		filter: blur(10px);
		opacity: 0.5;
	`};

	${transitionTween}

	&:last-of-type {
		margin: 0;
	}

	/*background: rgba(255, 0, 0, 0.33);*/
`;

export default Input;
