import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useLocationChange } from 'utils/Helpers';

import ContainerSearch from './ContainerSearch';
import SearchPreview from './SearchPreview';
import SearchForm from './SearchForm';
import SearchSuggestions from './SearchSuggestions';
import SearchBar from './SearchBar';
import SearchBarIcon from './SearchBarIcon';
import SearchBarInput from './SearchBarInput';

function Search({ match, shouldRenderMenu }) {
	const history = useHistory();
	const [searchValue, setSearchValue] = useState(match && match.params.vc ? match.params.vc : '');
	const [freezedSearchValue, setFreezedSearchValue] = useState(
		match && match.params.vc ? match.params.vc : ''
	);
	const [activeIndex, setActiveIndex] = useState(-1);
	const [suggestionsCount, setSuggestionsCount] = useState(0);
	const [isFocused, setIsFocused] = useState(false);
	const [recentAction, setRecentAction] = useState('KEYBOARD');
	const [suggestionsListParent, setSuggestionsListParent] = useState([]);

	useEffect(() => {});

	useLocationChange((location, prevLocation) => {
		// console.log('from', prevLocation, 'to', location);
		// if (prevLocation != )
		if (location && location.pathname.includes('/vc/')) {
		} else {
			// INCLUDE OTHER ROUTES
			if (location.pathname == '/') {
				if (prevLocation && prevLocation.pathname.includes('/vc/')) {
					setSearchValue('');
					setFreezedSearchValue('');
				}
			}
		}
	});

	const onChange = (e) => {
		// console.log('onchange', e, e.target.value, searchValue);
		setSearchValue(e.target.value);
		setFreezedSearchValue(e.target.value);
		setActiveIndex(-1);
		setRecentAction('KEYBOARD');
	};

	const onKeyDown = (e) => {
		// alert(e.keyCode);
		setRecentAction('KEYBOARD');

		if (suggestionsCount > 0) {
			if (e.keyCode == 40) {
				setActiveIndex(activeIndex < suggestionsCount - 1 ? activeIndex + 1 : 0);
			} else if (e.keyCode == 38) {
				setActiveIndex(activeIndex > 0 ? activeIndex - 1 : suggestionsCount - 1);
			}

			if (!isFocused) {
				setIsFocused(true);
			}
		}
	};

	const onFocus = () => {
		setIsFocused(true);
	};

	const onBlur = () => {
		setIsFocused(false);
	};

	const handleSubmit = () => {
		// alert('searchValue ' + searchValue);
		history.push(`/vc/${searchValue}`);
	};

	const updateSearchValue = (value) => {
		setSearchValue(value);
	};

	const updateActiveIndex = (index) => {
		setActiveIndex(index);
	};

	return (
		<ContainerSearch>
			<SearchForm onSubmit={handleSubmit}>
				<SearchBar>
					<SearchBarIcon />
					<SearchBarInput
						placeholder="투자사 검색하기"
						value={searchValue}
						onChange={onChange}
						onKeyDown={onKeyDown}
						onFocus={onFocus}
						onBlur={onBlur}
					/>
				</SearchBar>
				<SearchSuggestions
					activeIndex={activeIndex}
					updateActiveIndex={updateActiveIndex}
					searchValue={freezedSearchValue}
					updateSearchValue={updateSearchValue}
					suggestionsCount={suggestionsCount}
					setSuggestionsCount={setSuggestionsCount}
					isFocused={isFocused}
					recentAction={recentAction}
					setRecentAction={setRecentAction}
					setSuggestionsListParent={setSuggestionsListParent}
				/>
			</SearchForm>
			<SearchPreview
				value={searchValue}
				isFocused={isFocused}
				suggestionsList={suggestionsListParent}
				updateActiveIndex={updateActiveIndex}
				updateSearchValue={updateSearchValue}
			/>
		</ContainerSearch>
	);
}

export default Search;
