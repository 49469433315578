import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { transitionTween, stylesNoSelect } from 'Constants';

const Person = styled(motion.div)`
	display: inline-block;
	font-size: 14px;
	line-height: 14px;
`;

export default Person;