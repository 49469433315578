import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import styled from "styled-components";
import ScrollLock from "react-scrolllock";
import { DEVICE } from "Constants";

// import VCHeader from 'components/VC/VCHeader';
import SearchPreviewVC from "./SearchPreviewVC";
import { transitionMotionTween } from "Constants";

const OverlayPreview = styled(motion.div)`
	position: fixed;
	z-index: -4;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: ${({ theme }) => theme.backgroundBody};
`;

const OverlayPreviewEnd = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 128px;
	background: ${({theme}) => `linear-gradient(${theme.gradientSearchPreview})`};
`;

const PreviewContainer = styled.div`
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 0 16px;

	@media (min-width: ${DEVICE.minDesktop}) {
		padding: 0;
		transform: translateX(-81px);
	}
`;

const PreviewContainerContent = styled.div`
	max-width: 536px;
	width: 100%;
	height: 300px;
	margin: calc(154px + 42px * 5) auto 0 auto;
	/*margin: calc(154px + 42px * 6) auto 0 auto;*/
	/*align-items: center;*/

	@media (min-width: ${DEVICE.minDesktop}) {
		max-width: calc(266px + 32px + 536px);
		align-items: flex-end;
		margin: calc(134px + 42px * 5) auto 0 auto;
	}
`;

function SearchPreview({
	value,
	isFocused,
	suggestionsList,
	updateSearchValue,
	updateActiveIndex,
}) {
	const [isRendering, setIsRendering] = useState(false);
	const animateOverlay = useAnimation();

	useEffect(() => {
		setIsRendering(value && value.length > 0 && isFocused);

		if (value && value.length > 0 && isFocused) {
			animateOverlay.start({
				opacity: 1,
				transition: transitionMotionTween,
			});
		} else {
			animateOverlay.start({
				opacity: 0,
				transition: transitionMotionTween,
			});
		}
	}, [value, isFocused]);

	return (
		<>
			{isRendering && false && <ScrollLock />}
			{isRendering && (
				<OverlayPreview
					animate={animateOverlay}
					initial={{ opacity: 0 }}
				>
					<PreviewContainer>
						<PreviewContainerContent>
							{suggestionsList.map((firm, index) => (
								<SearchPreviewVC
									vc={firm}
									updateSearchValue={updateSearchValue}
									updateActiveIndex={updateActiveIndex}
								/>
							))}
						</PreviewContainerContent>
					</PreviewContainer>
					<OverlayPreviewEnd />
				</OverlayPreview>
			)}
		</>
	);
}

export default SearchPreview;
