import styled from 'styled-components';
import { DEVICE } from 'Constants';

const SearchBar = styled.div`
	position: relative;
	width: 100%;
	max-width: 833px;
	flex: 1;
	height: 44px;
	margin: 0 auto;
	padding: 0;
	background: ${({theme}) => theme.backgroundCard};
	border-radius: 8px;
	box-shadow: ${({theme}) => theme.shadowCard};

	@media (min-width: ${DEVICE.minDesktop}) {
		/*margin: 0 auto 0 76px;*/
	}
`;

export default SearchBar;
