import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { transitionTween, stylesNoSelect } from 'Constants';

const SwitcherChangerContent = styled(motion.div)`
	display: flex;
	flex-direction: column;
	height: 28px;
`;

export default SwitcherChangerContent;