import styled from 'styled-components';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import { H1, H2 } from 'components/Typography';
import { Link } from 'react-router-dom';

const Card = styled.div`
	max-width: 100%;
	/*width: 536px;*/
	/*height: 330px;*/

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 96px 40px 0 40px;
	/*border: 1px solid rgba(0, 0, 0, 0.2);*/
	border-radius: 8px;
`;

function NoReview({ vc }) {
	return (
		<Card>
			<H1>{vc ? `${vc}에 대해 ` : ''}아직 작성 된 리뷰가 없습니다.</H1>
			<Link to="/founders/write-review">
				<ButtonPrimary>투자사 리뷰 쓰기</ButtonPrimary>
			</Link>
		</Card>
	);
}

export default NoReview;
