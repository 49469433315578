import styled from "styled-components";

const ContainerConnector = styled.div`
	position: relative;
	z-index: -1;
	flex: 0 16px;
	width: 16px;
	height: 40px;
`;

const Connector = styled.div`
	position: absolute;
	/*top: ${({ index }) => (index > 0 ? index * -72 : -16)}px;*/
	/*top: ${({ height, index }) => (index > 0 ? -height - 16 : -16)}px;*/
	top: ${({ height, index }) => -height + 40}px;

	width: 16px;
	height: ${({ height }) => height}px;

	border-top: none;
	border-right: none;
	/*border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	border-left: 1px solid rgba(0, 0, 0, 0.15);*/
	border-bottom: ${({ theme }) => `1px solid ${theme.colorReplyConnector}`};
	border-left: ${({ theme }) => `1px solid ${theme.colorReplyConnector}`};
	border-radius: 0 0 0 16px;
`;

function ResponseConnector({ height, index }) {
	return (
		<ContainerConnector>
			<Connector height={height} index={index} />
		</ContainerConnector>
	);
}

export default ResponseConnector;
