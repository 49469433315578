import styled from 'styled-components';
import { DEVICE, transitionFade } from 'Constants';

const ContainerSearch = styled.div`
	position: absolute;
	z-index: 1;
	top: calc(34px + 13px + 34px);
	left: 0;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	padding: 0 16px;
	/*background: rgba(255, 0, 0, 0.3);*/

	@media (min-width: ${DEVICE.minTablet}) {
		position: relative;
		top: 0;
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		max-width: calc(1352px + 40px + 40px);
		margin: 0 auto;
		padding: 0 56px 0 0;
	}

	${transitionFade}
`;

export default ContainerSearch;
