import styled from 'styled-components';

const SearchBarInput = styled.input`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 14px 16px 12px 16px;
	/*padding: 13px 16px;*/
	text-align: center;
	font-size: 15px;
	font-weight: 400;
	line-height: 15px;
	border: none;
	outline: none;
	opacity: 0.66;
	background: none;
	
	color: ${({theme}) => theme.colorText};
	/*background: rgba(255, 0, 0, 0.3);*/
`;

export default SearchBarInput;
