import styled from 'styled-components';
import { motion } from 'framer-motion';
import { COLOR } from 'Constants';

const ReplyContentMessage = styled(motion.h6)`
	margin: 0;
	padding: 14px 16px 14px 16px;

	background: transparent;
	/*color: rgba(0, 0, 0, 0.75);*/
	color: ${COLOR.POSITIVE};
	opacity: 1;

	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
`;

export default ReplyContentMessage;
