import styled from "styled-components";

import { useVC } from "contexts/VCContext";

import ContainerVCHeader from "./ContainerVCHeader";
import VCHeaderName from "./VCHeaderName";
import VCHeaderCount from "./VCHeaderCount";
import VCHeaderScore from "./VCHeaderScore";
import Verified from "components/Verified";

function VCHeader({ vc, count, score, context }) {
	const { VCData } = useVC();

	return (
		<ContainerVCHeader context={context}>
			<VCHeaderName>
				{vc} <Verified vc={vc} />
			</VCHeaderName>
			<VCHeaderScore score={VCData[vc]?.score ?? score} />
			<VCHeaderCount>총 {count}개의 리뷰 수</VCHeaderCount>
		</ContainerVCHeader>
	);
}

export default VCHeader;
