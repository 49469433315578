import styled from 'styled-components';
import { DEVICE, transitionFade } from 'Constants';

const NavbarCTAButton = styled.button`
	position: relative;
	z-index: 9999999;

	display: none;

	/*display: flex;*/

	/*display: flex;*/
	flex-direction: row;
	align-items: center;

	width: auto;
	min-width: 44px;
	height: 44px;
	/*margin: 0 64px;*/
	/*margin: 0 auto;*/
	/*margin: 0 56px;*/
	margin: 0;
	padding: 0;

	background: ${(props) => (props.isDarkModeNavbar || props.isDarkModeMenu ? props.theme.colorBaseOpposite : props.theme.colorBase)};
	/*background: ${(props) => (props.isDarkModeNavbar || props.isDarkModeMenu ? '#fff' : '#000')};*/

	border: 0;
	border-radius: 8px;
	outline: 0;
	opacity: 1;
	cursor: pointer;

	@media (min-width: ${DEVICE.minDesktop}) {
		display: flex;
		justify-content: center;
		min-width: 194px;
		padding: 0 32px;
	}

	${transitionFade}
`;

export default NavbarCTAButton;
