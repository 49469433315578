import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

import { auth } from "services/firebase";
import { useAuth } from "contexts/AuthContext";
import { API_ROOT } from "Constants";

const UserContext = React.createContext();

// useAuth hook returns the value object
export function useUser() {
  return useContext(UserContext);
}

// the UserInfoProvider wrapper that is wrapped around our App
export function UserProvider({ children }) {
  const { currentUser } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const [isFounder, setIsFounder] = useState(false);
  const [isVC, setIsVC] = useState(false);

  // useEffect(() => {
  //   setIsVC(true);
  //   setUserInfo({ type: 'VC', vc: "카카오벤처스카카오벤처스" });
  // }, []);

  // const [isVC, setIsVC] = useState(false);

  const getUser = (IdToken) => {
    // console.log("IDTOKEN", IdToken);
    axios
      .get(`${API_ROOT()}/user/getUser`, {
        headers: { Authorization: `Bearer ${IdToken}` },
      })
      .then((response) => {
        const user = response.data;
        setUserInfo(user);

        if (user.type == "FOUNDER") setIsFounder(true);
        if (user.type == "VC") setIsVC(true);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error @ UserContext", error);
        if (currentUser && IdToken) {
          setTimeout(() => getUser(IdToken), 1000);
          // This is for first-time login when the info might not exist and might be slow to exist.
        }
      });
  };

  useEffect(() => {
    if (currentUser) {
      auth.currentUser.getIdToken().then((IdToken) => {
        getUser(IdToken);
      });
    }
  }, [currentUser]);

  const value = {
    isLoading,
    userInfo,
    isFounder,
    isVC,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
