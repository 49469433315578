import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { motion, useAnimation } from "framer-motion";

const Overlay = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999999999999;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100vw;
	height: 100vh;
	background: #000000;
`;
	// background: ${({ theme }) => theme.colorBase};

const LogoBlock = styled(motion.div)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

function Loader({ isInitialLoad, setIsLoadingParent }) {
	const [isLoading, setIsLoading] = useState(true);
	const animateOverlay = useAnimation();
	const theme = useTheme();

	useEffect(() => {
		if (isInitialLoad) animateOverlay.start({ opacity: 1 });

		setTimeout(() => {
			animateOverlay.start({ opacity: 0 });
			setTimeout(() => {
				setIsLoading(false);
				setIsLoadingParent(false);
			}, 300);
		}, 800);
	}, []);

	if (isLoading) {
		return (
			<Overlay
				initial={isInitialLoad ? { opacity: 1 } : { opacity: 0 }}
				animate={animateOverlay}
				transition={{ type: "tween", duration: 0.3 }}
			>
				<LogoBlock
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ type: "tween", duration: 0.15, delay: 0.3 }}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="240"
						height="38"
						fill="none"
						viewBox="0 0 240 38"
					>
						<path
							fill="#fff"
							d="M44.914 29.058v7.966H0V.531h10.09v28.527h34.824z"
						></path>
					</svg>
				</LogoBlock>
				<LogoBlock
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ type: "tween", duration: 0.15, delay: 0.4 }}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="240"
						height="38"
						fill="none"
						viewBox="0 0 240 38"
					>
						<path
							fill="#fff"
							d="M99.654 0v37.631h-9.863V7.966H55.5V0h44.155z"
						></path>
					</svg>
				</LogoBlock>
				<LogoBlock
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ type: "tween", duration: 0.15, delay: 0.5 }}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="240"
						height="38"
						fill="none"
						viewBox="0 0 240 38"
					>
						<path
							fill="#fff"
							d="M169.675.152V37.1h-44.231V.152h44.231zm-34.368 8.042v20.94h24.43V8.194h-24.43z"
						></path>
					</svg>
				</LogoBlock>
				<LogoBlock
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ type: "tween", duration: 0.15, delay: 0.6 }}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="240"
						height="38"
						fill="none"
						viewBox="0 0 240 38"
					>
						<path
							fill="#fff"
							d="M240 29.058v7.966h-44.914V.531h10.09v28.527H240z"
						></path>
					</svg>
				</LogoBlock>
			</Overlay>
		);
	} else {
		return <></>;
	}
}

export default Loader;
