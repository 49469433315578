import styled from 'styled-components';
import { DEVICE } from 'Constants';

const NavbarLogoFlex = styled.div`
	position: relative;
	width: auto;

	display: flex;
	flex-direction: row;

	@media (min-width: ${DEVICE.minTablet}) {
		flex-direction: column;
	}
`;

export default NavbarLogoFlex;