import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { auth } from "services/firebase";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { API_ROOT } from "Constants";

const AuthContext = React.createContext();

// useAuth hook returns the value object
export function useAuth() {
  return useContext(AuthContext);
}

// the AuthProvider wrapper that is wrapped around our App
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();

  const [fingerprint, setFingerprint] = useState();

  // when the app mounts first time, loading will be true
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setCurrentUser(true);
  // }, []);

  function requestLogin(email, onSuccess, onError) {
    console.log("Requesting to log in..", email, onSuccess, onError);

    axios
      .post(`${API_ROOT()}/founder/request-login`, {
        email: email,
      })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  function login(url, email, type, onSuccess, onError) {
    console.log("Trying to login..", url, email, onSuccess, onError);

    if (auth.isSignInWithEmailLink(url)) {
      axios
        .post(`${API_ROOT()}/${type.toLowerCase()}/login`, {
          url: url,
          email: email,
        })
        .then((res) => {
          auth
            .signInWithEmailLink(email, url)
            .then((res) => {
              console.log("SIGN IN", res?.user, res?.user?.getIdToken);

              res.user.getIdToken().then((IdToken) => {
                console.log("Got IdToken", IdToken);
                axios
                  .post(
                    `${API_ROOT()}/user/verifyLogin`,
                    {},
                    {
                      headers: { Authorization: `Bearer ${IdToken}` },
                    }
                  )
                  .then((response) => {
                    onSuccess(res);
                  })
                  .catch((error) => {
                    // Something went wrong
                    console.log("verifyLogin Fail", error, onError);
                    logout();
                    onError({});
                  });
              });
            })
            .catch((error) => {
              console.log("Login Fail", error, onError);
              onError(error);
            });
        })
        .catch((err) => onError(err));
    }
  }

  function requestVCLogin(email, onSuccess, onError) {
    console.log("Requesting to log in..", email, onSuccess, onError);

    axios
      .post(`${API_ROOT()}/vc-mode/request-login`, {
        email: email,
      })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  }

  function logout() {
    return auth.signOut();
  }

  useEffect(() => {
    (async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();

      const visitorId = result.visitorId;
      setFingerprint(visitorId);
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // NEED TO FIGURE OUT WHAT USER OBJECT HOLDS
      // CAN WE DISTINGUISH BETWEEN FOUNDER AND VC?
      console.log("currentUser", user);
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  const value = {
    loading,
    currentUser,
    fingerprint,
    requestLogin,
    login,
    requestVCLogin,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
