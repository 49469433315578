import styled from 'styled-components';

const ContainerSuggestions = styled.div`
	position: absolute;
	/*top: 56px;*/
	top: 12px;

	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;

	/*outline: 1px solid red;*/
`;

export default ContainerSuggestions;
