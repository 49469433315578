import styled from 'styled-components';
import { DEVICE } from '../../Constants';

const ContainerVC = styled.div`
	display: flex;
	/*justify-content: flex-end;*/
	flex-direction: column;
	align-items: center;
	margin: 186px auto 0 auto;
	padding: 0 16px;

	@media (min-width: ${DEVICE.minTablet}) {
		margin: 142px auto 0 auto;
	}	

	@media (min-width: ${DEVICE.minDesktop}) {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		transform: translateX(-81px);
		/*background: rgba(255, 0, 0, 0.3);*/
	}
`;

export default ContainerVC;
