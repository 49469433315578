import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const TextDetails = styled.div`
	/*display: flex;*/
	flex: 0 auto;
	
	display: inline-grid;
	grid-auto-flow: column;
	grid-gap: 24px;
	margin: 20px 0 4px 0;
	padding: 0;
	opacity: ${(props) => (props.hasExpanded ? 1 : 1)};
`;

export default TextDetails;