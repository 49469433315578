import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { useWindowWidth } from "@react-hook/window-size";
import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import { H6 } from "components/Typography";
import {
	DEVICE,
	transitionMotionSpring,
	transitionTween,
	stylesNoSelect,
} from "Constants";
import { useOuterClick } from "utils/Helpers";

const User = styled(motion.div)`
	/*position: absolute;*/
	/*left: 50%;*/
	position: relative;
	top: -12px;
	/*transform: translateY(-25%);*/

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;

	max-width: 100%;
	margin: 0 0 0 16px;
	padding: 4px 6px;

	background: ${({ theme, isDropdownOpen }) =>
		isDropdownOpen
			? theme.backgroundDropdownHover
			: theme.backgroundDropdown};
	border-radius: 4px;

	cursor: pointer;

	@media (min-width: ${DEVICE.minTablet}) {
		position: absolute;
		top: 100%;
		left: auto;
		justify-content: flex-end;
		margin: 0;
		transform: translateY(10px);
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		top: auto;
		right: -2px;
		bottom: -26px;
		left: auto;

		transform: translateY(16px);

		@media (hover: hover) {
			&:hover {
				background: ${({ theme }) => theme.backgroundDropdownHover};
			}
		}
	}

	${transitionTween}
`;

const UserText = styled(H6)`
	margin: 0;
	top: 0;
	text-align: left;
	font-weight: 600;
	
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	${stylesNoSelect}

	@media (min-width: ${DEVICE.minTablet}) {
		text-align: right;
	}
`;

const UserTextVC = styled.span`
	display: block;
	width: 100%;

	margin: 0;
	top: 0;
	font-weight: 400;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	${stylesNoSelect}
`;

const UserDropdown = styled(motion.div)`
	position: absolute;
	top: 0;
	left: calc(100% + 8px);
	/*top: calc(-100% + 4px);*/
	width: 100%;

	background: ${({ theme }) => theme.backgroundDropdown};
	border-radius: 4px;

	overflow: hidden;

	@media (min-width: ${DEVICE.minTablet}) {
		top: calc(100% + 4px);
		left: 0;
	}
`;

const UserDropdownItem = styled(motion.div)`
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 6px;
	background: ${({ theme }) => theme.backgroundTransparent};

	@media (hover: hover) {
		&:hover {
			background: ${({ theme }) => theme.backgroundDropdownHover};
		}
	}

	@media (min-width: ${DEVICE.minTablet}) {
		height: 28px;
	}

	${transitionTween}
`;

const UserDropdownIcon = styled(motion.div)`
	transform: rotate(-90deg);

	@media (min-width: ${DEVICE.minTablet}) {
		transform: rotate(0);
	}
`;

const UserDropdownItemText = styled(H6)`
	top: 0;
	margin: 0;
	white-space: nowrap;
	${stylesNoSelect}
`;

function NavbarUser() {
	const { logout, currentUser } = useAuth();
	const { isFounder, isVC, userInfo } = useUser();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const windowWidth = useWindowWidth();
	const [scrollbarWidth, setScrollbarWidth] = useState(0);
	const [innerWidth, setInnerWidth] = useState(windowWidth);

	useEffect(() => {
		setScrollbarWidth(window.innerWidth - document.body.offsetWidth);
		setInnerWidth(
			windowWidth + window.innerWidth - document.body.offsetWidth
		);
	}, [windowWidth]);

	const refDropdown = useOuterClick((e) => {
		setIsDropdownOpen(false);
	});

	const handleLogout = () => {
		setIsDropdownOpen(false);
		logout();
	};

	if (currentUser && (isFounder || isVC)) {
		return (
			<User
				ref={refDropdown}
				onClick={() => setIsDropdownOpen(!isDropdownOpen)}
				isDropdownOpen={isDropdownOpen}
			>
				{isFounder ? (
					<UserText>창업자 모드</UserText>
				) : (
					<UserText>
						VC 모드
						<UserTextVC>{userInfo.vc}</UserTextVC>
					</UserText>
				)}
				<UserDropdownIcon>
					<motion.div
						initial="dropdownClosed"
						animate={
							isDropdownOpen ? "dropdownOpened" : "dropdownClosed"
						}
						variants={{
							dropdownClosed: { y: 2, rotate: 0 },
							dropdownOpened: { y: -3, rotate: 180 },
						}}
						transition={transitionMotionSpring}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="none"
							viewBox="0 0 16 16"
						>
							<path
								stroke="#fff"
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M4 6l4 4 4-4"
								opacity="0.8"
							></path>
						</svg>
					</motion.div>
				</UserDropdownIcon>
				{innerWidth < 568 ? (
					<UserDropdown
						initial="dropdownClosed"
						animate={
							isDropdownOpen ? "dropdownOpened" : "dropdownClosed"
						}
						variants={{
							dropdownClosed: {
								width: 0,
								height: "100%",
								opacity: 0,
							},
							dropdownOpened: {
								width: "100%",
								height: "100%",
								opacity: 1,
							},
						}}
						transition={transitionMotionSpring}
					>
						<UserDropdownItem onClick={handleLogout}>
							<UserDropdownItemText>
								로그아웃
							</UserDropdownItemText>
						</UserDropdownItem>
					</UserDropdown>
				) : (
					<UserDropdown
						initial="dropdownClosed"
						animate={
							isDropdownOpen ? "dropdownOpened" : "dropdownClosed"
						}
						variants={{
							dropdownClosed: { height: 0, opacity: 0 },
							dropdownOpened: {
								height: "auto",
								opacity: 1,
							},
						}}
						transition={transitionMotionSpring}
					>
						<UserDropdownItem onClick={handleLogout}>
							<UserDropdownItemText>
								로그아웃
							</UserDropdownItemText>
						</UserDropdownItem>
					</UserDropdown>
				)}
			</User>
		);
	} else {
		return <></>;
	}
}

export default NavbarUser;
