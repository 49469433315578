import styled, { css } from 'styled-components';
import { DEVICE, transitionFade, transitionTween, COLOR } from 'Constants';

const ContainerButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 48px 0 0 0;

	${({isOffFocus}) => isOffFocus && css`
		filter: blur(10px);
		opacity: 0.5;
	`};

	${transitionTween}
`;

const Button = styled.button`
	min-width: 194px;
	height: 44px;
	margin: 0;
	padding: 3px 32px 0 32px;

	background: ${({ theme }) => theme.colorBase};
	color: ${({ theme }) => theme.colorBaseOpposite};
	/*font-weight: 500;*/
	font-weight: 400;
	text-align: center;

	border: 0;
	border-radius: 8px;
	outline: 0;
	cursor: pointer;

	${transitionFade}
`;

const SuccessMessage = styled.p`
	margin: 0 0 0 32px;
	color: ${COLOR.POSITIVE};
	opacity: ${(props) => (props.shouldRender ? 1 : 0)};
	${transitionTween}
`;

function ButtonForm({ children, action, onClick, isFormElement, hasSubmitted, messageSuccess, isOffFocus }) {
	return (
		<ContainerButton isOffFocus={isOffFocus}>
			<Button action={action} onClick={onClick}>
				{children}
			</Button>
			<SuccessMessage shouldRender={isFormElement && hasSubmitted}>{messageSuccess}</SuccessMessage>
		</ContainerButton>
	);
}

export default ButtonForm;
