// import { useState } from 'react';
import styled, { useTheme } from "styled-components";
import { transitionCurveMotion, COLOR } from "../../Constants";

const ContainerReviewHeaderReviewCount = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	margin: 0;
	cursor: pointer;
`;

const ReviewCountText = styled.p`
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	color: ${(props) =>
		props.color
			? props.color
			: props.isHovering
			? props.theme.colorTextActive
			: props.theme.colorTextNormal};
	/*background: rgba(255, 0, 0, 0.1);*/
	${transitionCurveMotion}
`;

const ReviewCountIcon = styled.div`
	/*position: relative;*/
	display: flex;
	align-items: center;
	flex: 0 auto;
	margin: ${(props) => (props.isHovering ? "0 0 0 8px" : "0 0 0 6px")};
	padding: 0 0 1px 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	opacity: ${(props) => (props.isHovering ? 0.7 : 0.6)};

	&::after {
		content: " ";
		position: absolute;
		top: calc(50% - 1px);
		right: 4px;
		width: calc(100% - 4px);
		height: 1px;

		background: ${({ theme }) => theme.colorText};
		border-radius: 2px;
		transform: ${(props) => (props.isHovering ? "scaleX(1)" : "scaleX(0)")};
		transform-origin: 100% 0;

		${transitionCurveMotion}
	}

	${transitionCurveMotion}
`;

function ReviewHeaderReviewCount({
	vcReviewCount,
	setIsHoveringReviewCount,
	isHovering,
}) {
	const theme = useTheme();

	return (
		<ContainerReviewHeaderReviewCount
			onMouseEnter={() => setIsHoveringReviewCount(true)}
			onMouseLeave={() => setIsHoveringReviewCount(false)}
		>
			<ReviewCountText>
				총 {vcReviewCount ? vcReviewCount : 1}개의 리뷰 보기
			</ReviewCountText>
			<ReviewCountIcon isHovering={isHovering}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="13"
					height="13"
					fill="none"
					viewBox="0 0 13 13"
				>
					<g
						stroke={theme.colorText}
						strokeLinecap="round"
						strokeLinejoin="round"
						opacity="1"
					>
						<path d="M2.708 6.5h7.584M6.5 2.708L10.292 6.5 6.5 10.292" />
					</g>
				</svg>
			</ReviewCountIcon>
		</ContainerReviewHeaderReviewCount>
	);
}

export default ReviewHeaderReviewCount;
