import styled from 'styled-components';
import { DEVICE } from 'Constants';

const NavbarCTAIcon = styled.div`
	width: 16px;
	height: 16px;
	margin: -4px auto 0 auto;
	padding: 0 0 0 1px;

	@media (min-width: ${DEVICE.minDesktop}) {
		margin: -4px 0 0 10px;
	}
`;

export default NavbarCTAIcon;
