import { useState, useEffect } from "react";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import ReviewScore from "./ReviewScore";
import ReviewTrending from "./ReviewTrending";
import ReviewExpandCollapse from "./ReviewExpandCollapse";
import ReviewContent from "./ReviewContent";
import ReviewRate from "./ReviewRate";
import ReviewResponse from "./ReviewResponse";
import ReviewReply from "./ReviewReply";
import ReviewDisclaimer from "./ReviewDisclaimer";

import ContainerCardReview from "./ContainerCardReview";
import CardReview from "./CardReview";
import CardReviewHeader from "./CardReviewHeader";

function Review({
  reviewId,
  score,
  isTrending,
  content,
  funded,
  termSheet,
  round,
  situationYear,
  isExpanded,
  isContentPlaceholder,
  // isReplyable,
  vc,
  replies,
  isManipulatable,
  isRejected
}) {
  const { currentUser } = useAuth();
  const { userInfo, isVC } = useUser();

  const [hasExpandedPreviewChanged, setHasExpandedPreviewChanged] =
    useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  const [shouldCutoff, setShouldCutoff] = useState(false);
  const [responseHeights, setResponseHeights] = useState([]);

  useEffect(() => {
    // if (isExpanded && !hasExpandedPreviewChanged) {
    if (isExpanded) {
      if (termSheet || round || situationYear) {
        setHasExpanded(true);
        setHasExpandedPreviewChanged(true);
      }
    }
  }, [termSheet, round, situationYear, isExpanded]);

  return (
    <ContainerCardReview>
      <CardReview>
        <CardReviewHeader>
          <ReviewScore score={score} />
          {isTrending && <ReviewTrending />}
        </CardReviewHeader>
        <ReviewDisclaimer isRejected={isRejected}>
          <ReviewContent
            hasExpanded={hasExpanded}
            setShouldCutoff={setShouldCutoff}
            reviewId={reviewId}
            termSheet={termSheet}
            round={round}
            situationYear={situationYear}
            isContentPlaceholder={isContentPlaceholder}
            isManipulatable={isManipulatable}
          >
            {content}
          </ReviewContent>
          <ReviewExpandCollapse
            hasExpanded={hasExpanded}
            setHasExpanded={setHasExpanded}
            shouldCutoff={shouldCutoff}
          />
          <ReviewRate reviewId={reviewId} />
        </ReviewDisclaimer>
      </CardReview>
      {isVC && replies && replies.length > 0 && (
        <ReviewResponse
          replies={replies}
          responseHeights={responseHeights}
          setResponseHeights={setResponseHeights}
        />
      )}
      {isVC && userInfo.vc == vc && (
        <ReviewReply
          vc={vc}
          author={userInfo.name}
          reviewId={reviewId}
          replyCount={replies ? replies.length : 0}
          responseHeights={responseHeights}
        />
      )}
    </ContainerCardReview>
  );
}

export default Review;
