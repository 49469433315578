import styled from "styled-components";
import { DEVICE, transitionFade } from "Constants";
import { motion } from "framer-motion";

const NavbarFade = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 224px;

  background: ${({theme}) => `linear-gradient(${theme.gradientNavbarFade})`};

  @media (min-width: ${DEVICE.minTablet}) {
    height: 112px;
    background: ${({theme}) => `linear-gradient(${theme.gradientNavbarFade})`};
  }

  @media (min-width: ${DEVICE.minDesktop}) {
    height: 112px; /* Should be 78px but 112px works well */
  }

  pointer-events: none; // Just so the fade doesn't get in the way.

  ${transitionFade}
`;

export default NavbarFade;
