import styled from "styled-components";

const Placeholder = styled.div`
	max-width: 100%;
	width: 536px;
	height: auto;
	margin: 0 0 56px 0;
	padding: 16px 16px 12px 16px;
	background: ${({ theme }) => theme.backgroundCard};
	border-radius: 8px;
	filter: ${({ theme }) => `drop-shadow(${theme.shadowCard})`};
`;

export default Placeholder;
