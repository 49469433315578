import styled from 'styled-components';

const DisclaimerButtonText = styled.h4`
	height: 32px;
	margin: 0;
	padding: 1px 0 0 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 32px;
	opacity: 0.75;
	text-align: right;
	color: ${({ theme }) => theme.colorText};
`;

export default DisclaimerButtonText;