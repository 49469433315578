import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const TextDetailsDetailHeader = styled.h5`
	margin: 0 0 4px 0;
	padding: 0;
	font-size: 12px;
	font-weight: 700;
	line-height: 12px;
	opacity: 0.33;
`;

export default TextDetailsDetailHeader;