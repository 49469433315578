import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { motion, useAnimation, AnimateSharedLayout } from "framer-motion";

import { API_ROOT, transitionMotionSpring, transitionTween } from "Constants";
import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import Reply from "./Reply";
import ReplyConnector from "./ReplyConnector";
import ReplyContent from "./ReplyContent";
import ReplyContentMessage from "./ReplyContentMessage";
import ReplyInput from "./ReplyInput";
import ReplyActions from "./ReplyActions";
import ReplyPerson from "./ReplyPerson";
import ReplySubmit from "./ReplySubmit";

function ReviewReply({ vc, author, reviewId, replyCount, responseHeights }) {
	const [replyValue, setReplyValue] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const [isHoveringAction, setIsHoveringAction] = useState(false);
	const [hasReplied, setHasReplied] = useState(false);
	const [isAnon, setIsAnon] = useState(true);

	const { currentUser } = useAuth();
	const { userInfo } = useUser();

	const animateSubmit = useAnimation();

	useEffect(() => {
		if (currentUser) {
			currentUser.getIdToken().then((IdToken) => {
				console.log(IdToken);
			});
		}
	}, [currentUser]);

	useEffect(() => {}, [isFocused]);

	const onReplyChange = (e) => {
		setReplyValue(e.target.value);
	};

	const onFocusChange = (value) => {
		setIsFocused(value);
	};

	const onHoverChange = (value) => {
		setIsHoveringAction(value);
	};

	const onSubmit = (e) => {
		// alert('onsubmit');
		console.log("onSubmit has been called", isFocused);

		currentUser.getIdToken().then((IdToken) => {
			console.log("handleSubmit, IdToken: ", IdToken);

			axios
				.post(
					`${API_ROOT()}/vc-mode/reply-review`,
					{
						reviewId: reviewId,
						content: replyValue,
						isAnonymous: isAnon,
					},
					{
						headers: { Authorization: `Bearer ${IdToken}` },
					}
				)
				.then((res) => {
					setIsFocused(false);
					setHasReplied(true);
					console.log("ON-SUBMIT RES", res);
				})
				.catch((err) => {
					console.log(
						"Error while replying to VC review",
						err,
						err?.response?.data
					);
				});
		});

	};

	return (
		<Reply>
			<ReplyConnector responseHeights={responseHeights} />
			<ReplyContent
				isFocused={isFocused}
				hasReplied={hasReplied}
				onMouseEnter={() => onHoverChange(true)}
				onMouseLeave={() => onHoverChange(false)}
			>
				{hasReplied ? (
					<ReplyContentMessage>
						답변하기 — 귀하가 남겨 주신 답변은 서비스 업데이트 후 보여질 예정입니다.
					</ReplyContentMessage>
				) : (
					<AnimateSharedLayout>
						<motion.div layout>
							<ReplyInput
								initial={"collapsed"}
								animate={isFocused ? "expanded" : "collapsed"}
								variants={{
									expanded: { height: 144 },
									collapsed: { height: 48 },
								}}
								transition={transitionMotionSpring}
								placeholder="답변하기 — 귀하가 남겨 주신 답변은 서비스 업데이트 후 보여질 예정입니다."
								value={replyValue}
								onChange={onReplyChange}
								onFocus={() => onFocusChange(true)}
								onBlur={() => {
									if (!isHoveringAction) {
										onFocusChange(false);
									}
								}}
							/>
							<ReplyActions
								// initial={'collapsed'}
								isFocused={isFocused}
								animate={animateSubmit}
								transition={transitionMotionSpring}
							>
								<ReplyPerson
									vc={vc}
									isAnon={isAnon}
									setIsAnon={setIsAnon}
								/>
								<ReplySubmit
									isFocused={isFocused}
									onClick={isFocused ? onSubmit : () => {}}
								>
									답변하기
								</ReplySubmit>
							</ReplyActions>
						</motion.div>
					</AnimateSharedLayout>
				)}
			</ReplyContent>
		</Reply>
	);
}

export default ReviewReply;
