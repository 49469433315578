import styled from "styled-components";
import { motion } from "framer-motion";

const ReplyActions = styled(motion.div)`
	position: absolute;
	bottom: 0;

	display: flex;
	align-items: center;
	justify-content: space-between;

	width: calc(100% - 32px);
	padding: 4px 16px 10px 16px;

	background: ${({ theme }) => theme.backgroundReplyCover};
	backdrop-filter: blur(10px);
	border-radius: 8px;

	/*outline: 1px solid red;*/
	opacity: ${({ isFocused }) => (isFocused ? 1 : 0)};
	pointer-events: ${({ isFocused }) => (isFocused ? "initial" : "none")};

	-webkit-transition: opacity 150ms ease-in-out;
	-moz-transition: opacity 150ms ease-in-out;
	-ms-transition: opacity 150ms ease-in-out;
	-o-transition: opacity 150ms ease-in-out;
	transition: opacity 150ms ease-in-out;
`;

export default ReplyActions;