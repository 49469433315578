import styled from 'styled-components';


const DisclaimerContent = styled.div`
	filter: ${({ isHidden }) => (isHidden ? "blur(20px)" : "blur(0)")};
	pointer-events: ${({ isHidden }) => (isHidden ? "none" : "auto")};

	-webkit-transition: filter 330ms ease-in-out;
	-moz-transition: filter 330ms ease-in-out;
	-ms-transition: filter 330ms ease-in-out;
	-o-transition: filter 330ms ease-in-out;
	transition: filter 330ms ease-in-out;
`;

export default DisclaimerContent;