import { useState } from "react";
import styled from "styled-components";

import Disclaimer from "./Disclaimer";
import DisclaimerMessage from "./DisclaimerMessage";
import DisclaimerMessageContent from "./DisclaimerMessageContent";
import DisclaimerMessageContentText from "./DisclaimerMessageContentText";
import DisclaimerButton from "./DisclaimerButton";
import DisclaimerButtonText from "./DisclaimerButtonText";
import DisclaimerContent from "./DisclaimerContent";

function ReviewDisclaimer({ children, isRejected }) {
	const [isHidden, setIsHidden] = useState(isRejected);

	const onShowReview = () => {
		setIsHidden(false);
	};

	return (
		<Disclaimer>
			{isHidden && (
				<DisclaimerMessage>
					<DisclaimerMessageContent>
						<DisclaimerMessageContentText>
							스팸이거나 사실이 아닐 수 있습니다.
						</DisclaimerMessageContentText>
						<DisclaimerButton onClick={onShowReview}>
							<DisclaimerButtonText>
								그래도 보기
							</DisclaimerButtonText>
						</DisclaimerButton>
					</DisclaimerMessageContent>
				</DisclaimerMessage>
			)}
			<DisclaimerContent isHidden={isHidden}>
				{children}
			</DisclaimerContent>
		</Disclaimer>
	);
}

export default ReviewDisclaimer;
