import styled from "styled-components";
import { motion } from "framer-motion";

const Active = styled(motion.div)`
	flex: 1;
	width: 100%;
	height: 1px;
	margin: -8px 0 0 0;
	background: ${({ theme }) => theme.colorBaseOpposite};
	border: none;
	border-radius: 1px;
`;

function MenuContentItemActive() {
	return <Active initial={{ opacity: 0 }} animate={{ opacity: 1 }} />;
}

export default MenuContentItemActive;
