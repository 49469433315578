import { COLOR, DEVICE } from 'Constants';
import ContainerVCHeaderScore from './ContainerVCHeaderScore';
import VCHeaderScoreScore from './VCHeaderScoreScore';
import VCHeaderScoreBase from './VCHeaderScoreBase';

function VCHeaderScore({ score }) {
	return (
		<ContainerVCHeaderScore>
			<VCHeaderScoreScore
				color={score >= 4 ? COLOR.POSITIVE : score >= 2.5 ? COLOR.NEUTRAL : COLOR.NEGATIVE}
			>
				{score ? score.toFixed(1) : '?'}
			</VCHeaderScoreScore>
			<VCHeaderScoreBase> / 5</VCHeaderScoreBase>
		</ContainerVCHeaderScore>
	);
}

export default VCHeaderScore;
