import styled from 'styled-components';
import { DEVICE } from 'Constants';

const ContainerAuth = styled.div`
	width: calc(100% + 32px);
	min-height: 100vh;
	margin: 0 -16px;
	/*background: #111111;
	color: #ffffff;*/
`;

const AuthContent = styled.div`
	max-width: calc(536px + 32px);
	padding: 96px 16px 96px 16px;

	@media (min-width: ${DEVICE.minTablet}) {
		padding: 128px 16px 128px 16px;
		max-width: calc(680px + 32px);
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		/*padding: 142px 40px 142px 40px;*/
		max-width: calc(680px + 80px);
		padding: calc((100vh - 181px) / 2) 40px 142px 40px;
	}

	@media (min-width: ${DEVICE.minDesktopLargeNav}) {
		max-width: calc(680px + 108px);
		padding: calc((100vh - 181px) / 2) 108px 142px 108px;
	}

	margin: 0 auto;
`;
export { ContainerAuth, AuthContent };
