import styled from 'styled-components';
import { DEVICE } from 'Constants';

const ContainerNavbarMenu = styled.div`
	position: absolute;
	z-index: 9999999;
	top: 34px;
	right: 16px;

	display: flex;
	align-items: center;

	width: 32px;
	height: 32px;
	margin: -1px 0 0 56px;
	/*background: rgba(0, 0, 255, 0.3);*/
	cursor: pointer;
	transform-style: preserve-3d;

	@media (min-width: ${DEVICE.minTablet}) {
		position: relative;
		top: 0;
		right: 0;
		flex: 0 32px;
	}

	@media (hover: hover) {
		&:hover {
			div span {
				${(props) => !props.shouldRenderMenu && 'width: 40px; margin-left: -4px;'}
				${(props) => false && props.shouldRenderMenu && 'transform: scale(1.08);'}
			}
		}
	}
`;

export default ContainerNavbarMenu;
