import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import { API_ROOT, DEVICE } from "Constants";
import {
  ContainerAuth,
  AuthRegister,
  AuthLogin,
  AuthLoginContent,
} from "layout/LayoutFounderAuth";
import { H1, H3, H6, P } from "components/Typography";
import Form from "components/Form";
import InputText from "components/Input/InputText";
import InputTextSelect from "components/Input/InputTextSelect";
import InputSelectVertical from "components/Input/InputSelectVertical";
import InputSelectHorizontal from "components/Input/InputSelectHorizontal";
import InputPassword from "components/Input/InputPassword";
import ButtonForm from "components/Button/ButtonForm";
import { Spacer4, Spacer8 } from "components/Spacer";

import InputSuccessText from "components/Input/InputSuccessText";
import InputErrorText from "components/Input/InputErrorText";

const LoginMessage = styled.div`
  margin: -16px 0 0 0;
`;

function VCAuthPage() {
  const { currentUser, requestVCLogin } = useAuth();
  const { isFounder, isVC, userInfo } = useUser();

  const [loginSuccessMessage, setLoginSuccessMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [defaultEmail, setDefaultEmail] = useState("");
  const [hasEmailSent, setHasEmailSent] = useState(false);

  const [isTunnelVisioned, setIsTunnelVisioned] = useState(false);

  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    if (currentUser && isVC && userInfo) {
      history.push(`/vc/${userInfo.vc}`);
    }
  }, [currentUser, isVC, userInfo]);

  function handleLoginSuccess(res) {
    // console.log("Login successful", res);
    setLoginSuccessMessage("이메일로 로그인 링크를 보내드렸습니다.");
    setLoginErrorMessage("");
    setIsLoading(false);
  }

  function handleLoginError(err) {
    // console.log("handleLoginError", err);
    setLoginSuccessMessage("");
    setLoginErrorMessage(
      "이메일을 다시 확인해주세요. 올바르지 않거나 초대가 안 되어있는 이메일 입니다."
    );
    setIsLoading(false);
  }

  const handleLoginRequest = (formData) => {
    // console.log("handleLogin", formData);
    setIsLoading(true);
    setLoginSuccessMessage("");
    setLoginErrorMessage("");
    requestVCLogin(formData.email, handleLoginSuccess, handleLoginError);
  };

  return (
    <ContainerAuth>
      <AuthRegister>
        <H1>VC 모드가 곧 오픈됩니다</H1>
        <P>
          누구머니는 스타트업계 내 창업자와 투자사 간의 정보 비대칭을 해소하면 더 건강한 창업 생태계가 조성될 수 있을 것이라 믿습니다.
        </P>
        <P>
          하지만 창업자들이 투자사에 일방적으로 리뷰를 남기는 시스템이 아니라, 투자사들도 그에 대한 답장을 할 수 있다면 이 업계에 더욱 더 공정하고 균형 잡힌 시각을 전달해줄 것입니다.
        </P>
        <Spacer8 />
        <H3>VC 엑세스 요청하기</H3>
        <Form onSubmit="/vc-mode/request-access">
          <InputText
            name="name"
            label="본명"
            placeholder="김누구"
            isTransparent={true}
            isOffFocus={isTunnelVisioned}
            required
          />
          {/* <InputText */}
          {/*   name="vc" */}
          {/*   label="투자사" */}
          {/*   placeholder="누구 벤처스" */}
          {/*   isTransparent={true} */}
          {/*   required */}
          {/* /> */}
          <InputTextSelect
            name="vc"
            label="VC / 펀드 명 / 엔젤 이름"
            placeholder="누구 벤처스"
            isTransparent={true}
            // updateOnChange={handleUpdate}
            onTunnelVision={() => setIsTunnelVisioned(true)}
            onTunnelVisionOff={() => setIsTunnelVisioned(false)}
            required
          />
          <InputText
            name="position"
            label="직위"
            placeholder="심사위원"
            isTransparent={true}
            isOffFocus={isTunnelVisioned}
            required
          />
          <InputText
            name="email"
            label="회사 이메일"
            placeholder="founder@nugu.money"
            isTransparent={true}
            isOffFocus={isTunnelVisioned}
            required
          />
          <InputText
            name="profile"
            label="프로필"
            description="링크드인 프로필, 블로그, 개인 웹사이트 등 본인 확인 및 레퍼런스 체크에 이용될 수 있는 자료 또는 리소스를 자유롭게 남겨 주세요."
            placeholder=""
            isTransparent={true}
            isOffFocus={isTunnelVisioned}
          />
          <ButtonForm action="submit" isOffFocus={isTunnelVisioned}>
            VC로 등록하기
          </ButtonForm>
        </Form>
      </AuthRegister>
      <AuthLogin>
        <AuthLoginContent>
          <H1>VC 모드 (Beta)</H1>
          <P>이미 초대 받으셨으면, 초대 받은 회사 이메일로 로그인 해주세요.</P>
          <Spacer8 />
          {/* <Form onSubmit="/vc-mode/auth"> */}
          <Form handleSubmit={handleLoginRequest}>
            <InputPassword
              name="email"
              label={false}
              placeholder="회사 이메일"
              defaultValue={cookies.get("nugu-vc-email")}
              isTransparent={false}
              isPasswordVisible={true}
              validate={(value) => {
                return (
                  value &&
                  value.length > 3 &&
                  value.includes("@") &&
                  value.includes(".")
                );
              }}
              required
            />
            <LoginMessage>
              {isLoading &&
                loginSuccessMessage == "" &&
                loginErrorMessage == "" && (
                  <H6 alignment="center">연결중입니다..</H6>
                )}
              {loginSuccessMessage && loginErrorMessage == "" && (
                <InputSuccessText alignment="center">
                  {loginSuccessMessage}
                </InputSuccessText>
              )}
              {InputErrorText && loginSuccessMessage == "" && (
                <InputErrorText alignment="center">
                  {loginErrorMessage}
                </InputErrorText>
              )}
            </LoginMessage>
          </Form>
        </AuthLoginContent>
      </AuthLogin>
    </ContainerAuth>
  );
}

export default VCAuthPage;
