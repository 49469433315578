import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

const useLocationChange = (action) => {
	const location = useLocation();
	const prevLocation = usePrevious(location);

	useEffect(() => {
		action(location, prevLocation);
	}, [location]);
};

function useOuterClick(callback) {
	const callbackRef = useRef();
	const innerRef = useRef();

	useEffect(() => {
		callbackRef.current = callback;
	});

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => document.removeEventListener("click", handleClick);
		function handleClick(e) {
			if (
				innerRef.current &&
				callbackRef.current &&
				!innerRef.current.contains(e.target)
			)
				callbackRef.current(e);
		}
	}, []);

	return innerRef;
}

export { usePrevious, useLocationChange, useOuterClick };
