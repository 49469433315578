import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { API_ROOT, DEVICE } from 'Constants';
import { AuthRegister, AuthLogin, AuthLoginContent } from 'layout/LayoutFounderAuth';
import { ContainerAuth, AuthContent } from 'layout/LayoutVCAuth';
import { H1, H3, H6, P } from 'components/Typography';
import Form from 'components/Form';
import InputText from 'components/Input/InputText';
import InputSelectVertical from 'components/Input/InputSelectVertical';
import InputSelectHorizontal from 'components/Input/InputSelectHorizontal';
import InputPassword from 'components/Input/InputPassword';
import ButtonForm from 'components/Button/ButtonForm';
import { Spacer4, Spacer8 } from 'components/Spacer';

import InputSuccessText from 'components/Input/InputSuccessText';
import InputErrorText from 'components/Input/InputErrorText';

const LoginMessage = styled.div`
  margin: -16px 0 0 0;
`;

function VCModeAuthPage({ match }) {
  const history = useHistory();
  const location = useLocation();
  const cookies = new Cookies();

  const [requiresEmail, setRequiresEmail] = useState(true);
  const [email, setEmail] = useState(null);
  const [verifiedName, setVerifiedName] = useState(null);
  const [verifiedVC, setVerifiedVC] = useState(null);
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checkValidAuth();
  }, []);

  const checkValidAuth = () => {
    let emailValue = email ?? cookies.get('nugu-email');

    if (requiresEmail) {
      setIsLoading(true);
      setError(null);

      axios
        .post(`${API_ROOT()}/vc-mode/valid-auth`, {
          token: match ? match.params.token : '',
          email: emailValue,
        })
        .then((res) => {
          setIsLoading(false);
          cookies.set('nugu-email', emailValue, {});
          cookies.set('nugu-vc', res.data.vc, {});
          console.log('then', res);
          console.log('check', res, res.data, res.data.status);
          if (res && res.data && res.status == 200) {
            setVerifiedName(res.data.name);
            setRequiresEmail(false);

            history.push(`/vc/${res.data.vc}`);
          }
        })
        .catch((err) => {
          // alert('error!');
          setIsLoading(false);
          let errorData = err && err.response && err.response.data;
          let code = errorData ? errorData.code : 'unknown';

          if (email) {
            setError(`${code} 오류가 났습니다`);
          }

          console.log('ERROR', code);

          if (code == 'auth/invalid-action-code') {
            console.log('Code is no longer valid');
          }
        });
    }
  };

  const handleUpdate = (name, value) => {
    if (name == 'email') setEmail(value);
  };

  return (
    <ContainerAuth>
      <AuthContent>
        {verifiedName ? (
          <H1>환영합니다 {verifiedName}님.</H1>
        ) : (
          <>
            <H1>
              환영합니다.
              <br />
              보안을 위해 이메일을 다시 확인해주세요.
            </H1>
            <P>
              초대권을 받은 이메일로만 로그인 가능합니다. <Link to="/vc-mode">초대권을 못 받으셨나요?</Link>
            </P>
            <Spacer8 />
          </>
        )}
        {requiresEmail && (
          <Form handleSubmit={checkValidAuth}>
            <InputPassword
              name="email"
              label={false}
              placeholder="회사 이메일"
              isTransparent={false}
              isPasswordVisible={true}
              validate={(value) => {
                return value && value.length > 3 && value.includes('@') && value.includes('.');
              }}
              updateOnChange={handleUpdate}
              error={error}
              required
            />
            <React.Fragment />
            {isLoading && !error && <H6>연결 중입니다..</H6>}
          </Form>
        )}
      </AuthContent>
    </ContainerAuth>
  );
}

export default VCModeAuthPage;
