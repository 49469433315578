import { useState, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

import { useUser } from "contexts/UserContext";
import { transitionTween, stylesNoSelect } from 'Constants';

import Switcher from './Switcher';
import SwitcherIcon from './SwitcherIcon';
import SwitcherChanger from './SwitcherChanger';
import SwitcherChangerContent from './SwitcherChangerContent';
import Person from './Person';

function ReplyPerson({ vc, isAnon, setIsAnon }) {
	const theme = useTheme();

	const { userInfo } = useUser();

	const toggleAnonStatus = () => {
		setIsAnon(!isAnon);
		// some parent call too
	};

	return (
		<Switcher onClick={toggleAnonStatus}>
			<SwitcherIcon>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="20"
					height="20"
					fill="none"
					viewBox="0 0 20 20"
				>
					<path
						stroke={theme.colorBase}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="1.2"
						d="M10.625 13.125l2.708 2.917 2.709-2.917M3.958 6.875l2.709-2.917 2.708 2.917M13.333 7.292v8.75M6.667 3.958v8.75"
					/>
				</svg>
			</SwitcherIcon>
			<SwitcherChanger>
				<SwitcherChangerContent
					animate={isAnon ? 'isAnon' : 'isReal'}
					variants={{
						isAnon: { y: 0 },
						isReal: { y: -14 },
					}}
				>
					<Person
						animate={isAnon ? 'isAnon' : 'isReal'}
						variants={{
							isAnon: { opacity: 1 },
							isReal: { opacity: 0 },
						}}
					>
						{vc} 심사위원
					</Person>
					<Person
						animate={isAnon ? 'isAnon' : 'isReal'}
						variants={{
							isAnon: { opacity: 0 },
							isReal: { opacity: 1 },
						}}
					>
						{userInfo.name} {userInfo.position}
					</Person>
				</SwitcherChangerContent>
			</SwitcherChanger>
		</Switcher>
	);
}

export default ReplyPerson;
