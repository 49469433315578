import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import { useVC } from "contexts/VCContext";

import VCHeaderName from "components/VC/VCHeader/VCHeaderName";
import VCHeaderScore from "components/VC/VCHeader/VCHeaderScore";
import ReviewHeaderReviewCount from "components/IndividualReview/ReviewHeaderReviewCount";
import { DEVICE } from "Constants";

const PreviewVC = styled.div`
	/*border: 1px solid blue;*/
	margin: 0;
	padding: 32px 0;
	border-top: ${({ theme }) => `1px solid ${theme.colorBorderSearchPreview}`};
`;

const PreviewVCContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	@media (min-width: ${DEVICE.minDesktop}) {
		justify-content: center;
		transform: translateX(-81px);
	}
`;

const PreviewVCTitle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	max-width: 512px;
	flex: 1;
	margin: 0 24px 0 0;

	@media (min-width: ${DEVICE.minDesktop}) {
		width: 100%;
		flex: 1;
		max-width: calc(266px + 32px);
	}
`;

const PreviewVCScore = styled.div`
	flex: 0.5;

	@media (min-width: ${DEVICE.minDesktop}) {
		flex: 0 auto;
	}
`;

function SearchPreviewVC({ vc, updateSearchValue, updateActiveIndex }) {
	const { VCData } = useVC();

	const [count, setCount] = useState(0);
	const [score, setScore] = useState(0);

	const [isHoveringVC, setIsHoveringVC] = useState(false);
	const [isHoveringReviewCount, setIsHoveringReviewCount] = useState(false);
	const [isHovering, setIsHovering] = useState(false);

	const history = useHistory();

	useEffect(() => {
		setCount(VCData[vc]?.count);
		setScore(VCData[vc]?.score);
	}, [VCData]);

	useEffect(() => {
		setIsHovering(isHoveringVC || isHoveringReviewCount);
	}, [isHoveringVC, isHoveringReviewCount, isHovering]);

	const onClick = () => {
		history.push(`/vc/${vc}`);
		updateSearchValue(vc);
		updateActiveIndex(-1);
	};

	return (
		<PreviewVC onClick={onClick}>
			<Link to={`/vc/${vc}`}>
				<PreviewVCContent>
					<PreviewVCTitle
						onMouseEnter={() => setIsHoveringVC(true)}
						onMouseLeave={() => setIsHoveringVC(false)}
					>
						<VCHeaderName>{vc}</VCHeaderName>
						<ReviewHeaderReviewCount
							vcReviewCount={count}
							setIsHoveringReviewCount={setIsHovering}
							isHovering={isHovering}
						/>
					</PreviewVCTitle>
					<PreviewVCScore>
						<VCHeaderScore score={score} />
					</PreviewVCScore>
				</PreviewVCContent>
			</Link>
		</PreviewVC>
	);
}

export default SearchPreviewVC;
