import styled from "styled-components";
import { stylesNoSelect, transitionTween, COLOR } from "Constants";

// background: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.12)' : 'rgba(255, 255, 255, 0.33)')};
// background: ${(props) => (props.disabled ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0)')};

const InputField = styled.input`
	max-width: ${(props) => (props.maxWidth ? "672px" : "100%")};

	width: 100%;
	height: 44px;

	margin: 0;
	padding: 0 22px 0 22px;

	font-size: 15px;
	line-height: 15px;
	font-weight: 400;

	/*background: rgba(255, 237, 237, 0.2);*/
	/*background: rgba(0, 0, 0, 0.02);*/
	background: ${({ theme }) => theme.backgroundCard};
	color: ${(props) =>
		props.disabled
			? props.theme.colorTextHalf
			: props.theme.colorTextActive};
	text-align: ${(props) => (props.alignment ? props.alignment : "left")};

	${(props) =>
		props.disabled &&
		props.defaultValue != null &&
		`background: ${props.theme.backgroundCard} !important;`}

	border: ${(props) =>
		props.error
			? `1px solid ${COLOR.NEGATIVE}`
			: props.disabled
			? `1px solid ${props.theme.colorBorderInputDisabled}`
			: `1px solid ${props.theme.colorBorderInputTransparent}`};
	border-radius: 8px;
	outline: none;

	${(props) => props.disabled && "cursor: not-allowed;"}

	&:focus {
		background: ${(props) =>
			props.isTransparent
				? props.theme.backgroundInputFocusTransparent
				: props.theme.backgroundInputFocus};
		border: ${({ theme }) => `1px solid ${theme.colorTextFaded}`};
	}

	${transitionTween}
`;
	// ${stylesNoSelect}

export default InputField;

// background: ${(props) => (props.transparent ? 'rgba(255, 255, 255, 0.33)' : 'rgba(255, 255, 255, 1)')};
