import styled from 'styled-components';
import { DEVICE } from 'Constants';

const ReviewHeaderScoreScore = styled.h2`
	margin: 0;
	padding: 0;
	font-size: 22px;
	font-weight: 400;
	line-height: 22px;
	color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, 0.75)')};
	/*background: rgba(255, 0, 0, 0.1);*/

	@media (min-width: ${DEVICE.minDesktop}) {
		font-size: 32px;
		line-height: 40px;
	}
`;

export default ReviewHeaderScoreScore;