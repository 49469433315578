import styled from 'styled-components';
import { transitionTween } from 'Constants';

const PrivateText = styled.h5`
	margin: 0 0 0 8px;
	padding: 2px 0 0 0;
	font-size: 15px;
	line-height: 15px;
	font-weight: 400;
	opacity: 0.66;
	${transitionTween}
`;

export default PrivateText;
