import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { transitionCurveMotion, stylesNoSelect } from "Constants";

import ShareReviewButton from "components/Review/ShareReviewButton";

import ReviewContentText from "./ReviewContentText";
import ReviewContentTextText from "./ReviewContentTextText";
import ReviewContentTextGhost from "./ReviewContentTextGhost";
import Details from "./Details";
import TextDetails from "./TextDetails";
import TextDetailsDetail from "./TextDetailsDetail";
import TextDetailsDetailHeader from "./TextDetailsDetailHeader";
import TextDetailsDetailText from "./TextDetailsDetailText";

function ReviewContent({
	children,
	hasExpanded,
	setShouldCutoff,
	reviewId,
	termSheet,
	round,
	situationYear,
	isContentPlaceholder,
	isManipulatable,
}) {
	const [ghostHeight, setGhostHeight] = useState(-1);

	const refGhost = useRef();

	useEffect(() => {
		if (refGhost && refGhost.current) {
			let ghostHeightCalculated =
				refGhost.current.getBoundingClientRect().height;
			setGhostHeight(ghostHeightCalculated);
			// console.log('ghostHeight', ghostHeightCalculated);

			if (setShouldCutoff != null) {
				setShouldCutoff(ghostHeightCalculated > 270);
			}
		}
	});

	return (
		<ReviewContentText hasExpanded={hasExpanded} ghostHeight={ghostHeight}>
			<ReviewContentTextText isContentPlaceholder={isContentPlaceholder}>
				{children}
			</ReviewContentTextText>
			<Details>
				<TextDetails hasExpanded={hasExpanded}>
					{termSheet && (
						<TextDetailsDetail>
							<TextDetailsDetailHeader>
								투자 상황
							</TextDetailsDetailHeader>
							<TextDetailsDetailText>
								{termSheet}
							</TextDetailsDetailText>
						</TextDetailsDetail>
					)}
					{round && (
						<TextDetailsDetail>
							<TextDetailsDetailHeader>
								라운드
							</TextDetailsDetailHeader>
							<TextDetailsDetailText>
								{round}
							</TextDetailsDetailText>
						</TextDetailsDetail>
					)}
					{situationYear && (
						<TextDetailsDetail>
							<TextDetailsDetailHeader>
								상황 기준
							</TextDetailsDetailHeader>
							<TextDetailsDetailText>
								{situationYear}
							</TextDetailsDetailText>
						</TextDetailsDetail>
					)}
				</TextDetails>
				{!isManipulatable && (
					<ShareReviewButton reviewId={reviewId}>
						리뷰 공유하기
					</ShareReviewButton>
				)}
			</Details>
			<ReviewContentTextGhost ref={refGhost}>
				{children}
			</ReviewContentTextGhost>
		</ReviewContentText>
	);
}

export default ReviewContent;
