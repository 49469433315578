import styled from 'styled-components';
import { transitionTween } from 'Constants';

const SubmitPasswordButton = styled.button`
	position: absolute;
	/*top: 0px;*/
	top: 2px;
	right: 12px;

	width: auto;
	height: 32px;
	padding: 2px 16px 0 16px;
	/*background: rgba(255, 255, 255, 1);*/
	background: ${(props) => (props.isHovering ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 1)')};
	color: #ffffff;
	/*background: rgba(42, 0, 12, 0.06);*/
	border: 0;
	border-radius: 6px;
	/*box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.07), 0px 1.8px 2.7px -6px rgba(0, 0, 0, 0.042),
		0px 0.5px 0.8px -6px rgba(0, 0, 0, 0.028);*/

	font-size: 13px;
	font-weight: 400;

	transform: translateY(12px);
	outline: none;
	cursor: pointer;

	opacity: ${(props) => (props.value && props.validate(props.value) ? 1 : 0)};

	${transitionTween} /*&:hover {
		background: rgba(42, 0, 12, 0.1);
	}*/

	a {
		width: 100%;
		height: 100%;
		color: inherit;
		text-decoration: none;
	}
`;

export default SubmitPasswordButton;
