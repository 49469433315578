import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useVC } from "contexts/VCContext";

import ContainerIndividualReview from "./ContainerIndividualReview";
import IndividualReviewHeader from "./IndividualReviewHeader";
import ReviewHeaderVC from "./ReviewHeaderVC";
import ReviewHeaderScore from "./ReviewHeaderScore";
import ReviewHeaderReviewCount from "./ReviewHeaderReviewCount";
import Review from "../Review";

function IndividualReview({ vcScore, vcReviewCount, review }) {
  const {
    reviewId,
    vc,
    score,
    isTrending,
    content,
    termSheet,
    round,
    situationYear,
    replies,
    isRejected
  } = review;

  const [hasExpanded, setHasExpanded] = useState(false);

  const [isHoveringVC, setIsHoveringVC] = useState(false);
  const [isHoveringReviewCount, setIsHoveringReviewCount] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const { VCData } = useVC();

  useEffect(() => {
    setIsHovering(isHoveringVC || isHoveringReviewCount);
  });

  return (
    <ContainerIndividualReview>
      <IndividualReviewHeader>
        <ReviewHeaderVC
          onMouseEnter={() => setIsHoveringVC(true)}
          onMouseLeave={() => setIsHoveringVC(false)}
          isHovering={isHovering}
        >
          <Link to={`/vc/${vc}`}>{vc}</Link>
        </ReviewHeaderVC>
        <ReviewHeaderScore vcScore={VCData[vc]?.score ?? vcScore} />
        <Link to={`/vc/${vc}`}>
          <ReviewHeaderReviewCount
            vcReviewCount={vcReviewCount}
            isHoveringReviewCount={isHoveringReviewCount}
            setIsHoveringReviewCount={setIsHoveringReviewCount}
            isHovering={isHovering}
          />
        </Link>
      </IndividualReviewHeader>
      <Review
        reviewId={reviewId}
        score={score}
        isTrending={isTrending}
        content={content}
        termSheet={termSheet}
        round={round}
        situationYear={situationYear}
        replies={replies}
        vc={vc}
        isRejected={isRejected}
      />
    </ContainerIndividualReview>
  );
}

export default IndividualReview;
