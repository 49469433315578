import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const TextDetailsDetailText = styled.p`
	margin: 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 27px;
	opacity: 0.66;
`;

export default TextDetailsDetailText;