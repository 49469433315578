import styled from 'styled-components';

const InputDescription = styled.p`
	margin: -4px 0 8px 0;

	font-size: 15px;
	line-height: 27px;
	font-weight: 400;

	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.5)};
`;

export default InputDescription;
