const transitionCurveMotion = `
	-webkit-transition: all 330ms ease-in-out;
	-moz-transition: all 330ms ease-in-out;
	-ms-transition: all 330ms ease-in-out;
	-o-transition: all 330ms ease-in-out;
	transition: all 330ms ease-in-out;
`;

const transitionFade = `
	-webkit-transition: opacity 150ms ease-in-out, background 250ms ease-in-out;
	-moz-transition: opacity 150ms ease-in-out, background 250ms ease-in-out;
	-ms-transition: opacity 150ms ease-in-out, background 250ms ease-in-out;
	-o-transition: opacity 150ms ease-in-out, background 250ms ease-in-out;
	transition: opacity 150ms ease-in-out, background 250ms ease-in-out;
`;

const transitionTween = `
	-webkit-transition: all 250ms ease-in-out;
	-moz-transition: all 250ms ease-in-out;
	-ms-transition: all 250ms ease-in-out;
	-o-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
`;

const transitionMotionSpring = { type: 'spring', stiffness: 1000, damping: 100 };
const transitionMotionTweenSlow = { type: 'tween', duration: 0.33 };
const transitionMotionTween = { type: 'tween', duration: 0.15 };

const stylesNoSelect = `
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const ROUTES = {
	HOME: '/',
	FOUNDERMODE: '/founders',
	VCMODE: '/vc-mode',
};

const COLOR = {
	POSITIVE: '#1AA187',
	NEUTRAL: '#E6B000',
	NEGATIVE: '#EE3F4A',
};

const API_ROOT = () => {
	return process.env.NODE_ENV == 'production' ? 'https://api.nugu.money' : 'http://localhost:8000';
};

const DEVICE = {
	minTablet: '568px',
	minTabletLarge: '768px',
	minDesktop: '1080px',
	minDesktopLargeNav: '1434px',
	minDesktopLarge: '1440px',
};

export {
	transitionCurveMotion,
	transitionFade,
	transitionTween,
	transitionMotionSpring,
	transitionMotionTween,
	transitionMotionTweenSlow,
	stylesNoSelect,
	ROUTES,
	COLOR,
	API_ROOT,
	DEVICE,
};
