import styled from "styled-components";

const PlaceholderText = styled.div`
	width: 100%;
	height: 20px;
	margin: 0 0 16px 0;
	background: ${({ theme }) => theme.colorTextPlaceholderBody};
	border-radius: 4px;

	&:last-of-type {
		margin: 0;
	}

	animation-name: pulse;
	animation-duration: 1s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
	animation-delay: ${({ line }) => line * 130}ms;

	@keyframes pulse {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.25;
		}

		100% {
			opacity: 1;
		}
	}
`;

export default PlaceholderText;
