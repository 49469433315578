import styled from 'styled-components';
import { DEVICE } from '../../Constants';

const ContainerIndividualReview = styled.div`
	display: flex;
	/*justify-content: flex-end;*/
	flex-direction: column;
	align-items: center;

	@media (min-width: ${DEVICE.minDesktop}) {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		transform: translateX(-81px);
		/*background: rgba(255, 0, 0, 0.3);*/
	}
`;

export default ContainerIndividualReview;
