import styled from 'styled-components';
import { DEVICE } from 'Constants';

const NavbarCTAText = styled.h4`
	display: none;
	/*color: ${(props) => (props.isDarkModeNavbar || props.isDarkModeMenu ? '#000' : '#fff')};*/
	color: ${(props) => (props.isDarkModeNavbar || props.isDarkModeMenu ? props.theme.colorBase : props.theme.colorBaseOpposite)};
	font-size: 15px;
	font-weight: 400;
	line-height: 15px;

	@media (min-width: ${DEVICE.minDesktop}) {
		display: block;
	}
`;

export default NavbarCTAText;
