import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.backgroundBody};
    color: ${({ theme }) => theme.colorText};
    transition: background 0.25s ease-in-out;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.colorTextFaded};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.colorTextFaded};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.colorTextFaded};
  }

`;

const defaultTheme = {
  backgroundBody: "#eeeeee",
  backgroundCard: "#ffffff",
  backgroundMenu: 'rgba(0, 0, 0, 0.9)',
  backgroundCardAction: "rgba(0, 0, 0, 0.1)",
  backgroundCardActionHover: "rgba(0, 0, 0, 0.15)",
  backgroundCardButton: "rgba(0, 0, 0, 0.1)",
  backgroundCardButtonOnewayHover: "rgba(0, 0, 0, 0.066)",
  backgroundTransparent: "rgba(0, 0, 0, 0)",
  backgroundInputFocus: 'rgba(255, 255, 255, 0.75)',
  backgroundInputFocusTransparent: 'rgba(255, 255, 255, 0.15)',
  backgroundInputSelected: 'rgba(0, 0, 0, 0.66)',
  backgroundSelectInputHover: 'rgba(255, 255, 255, 0.33)',
  backgroundSelectInputHoverTransparent: 'rgba(255, 255, 255, 0.15)',
  backgroundDropdown: 'rgba(0, 0, 0, 0.1)',
  backgroundDropdownHover: 'rgba(0, 0, 0, 0.2)',
  backgroundReply: 'rgba(0, 0, 0, 0.07)',
  backgroundReplyFocused: 'rgba(0, 0, 0, 0.1)',
  backgroundReplyCover: 'rgba(213, 213, 213, 0.25)',
  backgroundReplyButton: 'rgba(255, 255, 255, 0.25)',
  backgroundReplyButtonHover: 'rgba(255, 255, 255, 0.5)',

  colorBase: "#000000",
  colorBaseOpposite: "#ffffff",
  colorText: "rgba(0, 0, 0, 1)",
  colorTextNormal: "rgba(0, 0, 0, 0.66)",
  colorTextActive: "rgba(0, 0, 0, 0.75)",
  colorTextHalf: "rgba(0, 0, 0, 0.5)",
  colorTextFaded: "rgba(0, 0, 0, 0.33)",
  colorTextPlaceholder: "rgba(0, 0, 0, 0.03)",
  colorTextPlaceholderBody: "rgba(0, 0, 0, 0.04)",
  colorBorderSearchPreview: 'rgba(0, 0, 0, 0.07)',
  colorBorderInputDisabled: 'rgba(0, 0, 0, 0.12)',
  colorBorderInputTransparent: 'rgba(0, 0, 0, 0)',
  colorBorderSelectInput: 'rgba(0, 0, 0, 0.25)',
  colorBorderSelectInputHover: 'rgba(0, 0, 0, 0.33)',

  colorBorderSelect: 'rgba(0, 0, 0, 0.15)',
  colorBorderSelectSelected: 'rgba(255, 255, 255, 0.66)',
  colorFillSelect: 'rgba(0, 0, 0, 0.75)',
  colorFillSelectSelected: 'rgba(255, 255, 255, 0.75)',
  colorCheckbox: 'rgba(0, 0, 0, 0.33)',
  colorCheckboxPrivate: 'rgba(0, 0, 0, 0.66)',
  colorReplyConnector: '#c5c5c5',

  shadowCard: "0px 12px 32px rgba(0, 0, 0, 0.07)",
  shadowSwitcher: "0px 2px 4px rgba(0, 0, 0, 0.15)",
  gradientContentCutoff:
    "180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%",
  gradientNavbarCutoff:
    "180deg, rgba(238, 238, 238, 1) 49%, rgba(238, 238, 238, 0) 50%, rgba(238, 238, 238, 0) 100%",
  gradientNavbarFade:
    "180deg, rgba(238, 238, 238, 1) 50%, rgba(238, 238, 238, 0) 100%",
  gradientSearchPreview:
    "180deg, rgba(238, 238, 238, 0) 0%, rgba(238, 238, 238, 1) 100%",
  
};

const founderTheme = {
  backgroundBody: "#000000",
  backgroundCard: "#333333",
  backgroundMenu: 'rgba(255, 255, 255, 0.9)',
  backgroundCardAction: "rgba(255, 255, 255, 0.1)",
  backgroundCardActionHover: "rgba(255, 255, 255, 0.15)",
  backgroundCardButton: "rgba(255, 255, 255, 0.1)",
  backgroundCardButtonOnewayHover: "rgba(255, 255, 255, 0.05)",
  backgroundTransparent: "rgba(255, 255, 255, 0)",
  backgroundInputFocus: 'rgba(51, 51, 51, 0.75)',
  backgroundInputFocusTransparent: 'rgba(51, 51, 51, 0.15)',
  backgroundInputSelected: 'rgba(255, 255, 255, 0.66)',
  backgroundSelectInputHover: 'rgba(0, 0, 0, 0.33)',
  backgroundSelectInputHoverTransparent: 'rgba(0, 0, 0, 0.15)',
  backgroundDropdown: 'rgba(255, 255, 255, 0.1)',
  backgroundDropdownHover: 'rgba(255, 255, 255, 0.2)',
  backgroundReply: 'rgba(255, 255, 255, 0.07)',
  backgroundReplyFocused: 'rgba(255, 255, 255, 0.1)',
  backgroundReplyCover: 'rgba(26, 26, 26, 0.25)',
  backgroundReplyButton: 'rgba(255, 255, 255, 0.07)',
  backgroundReplyButtonHover: 'rgba(255, 255, 255, 0.12)',

  colorBase: "#ffffff",
  colorBaseOpposite: "#000000",
  colorText: "rgba(255, 255, 255, 1)",
  colorTextNormal: "rgba(255, 255, 255, 0.66)",
  colorTextActive: "rgba(255, 255, 255, 0.75)",
  colorTextHalf: "rgba(255, 255, 255, 0.5)",
  colorTextFaded: "rgba(255, 255, 255, 0.33)",
  colorTextPlaceholder: "rgba(255, 255, 255, 0.07)",
  colorTextPlaceholderBody: "rgba(255, 255, 255, 0.05)",
  colorBorderSearchPreview: 'rgba(255, 255, 255, 0.1)',
  colorBorderInputDisabled: 'rgba(255, 255, 255, 0.12)',
  colorBorderInputTransparent: 'rgba(255, 255, 255, 0)',
  colorBorderSelectInput: 'rgba(255, 255, 255, 0.25)',
  colorBorderSelectInputHover: 'rgba(255, 255, 255, 0.33)',

  colorBorderSelect: 'rgba(255, 255, 255, 0.33)',
  colorBorderSelectSelected: 'rgba(0, 0, 0, 0.66)',
  colorFillSelect: 'rgba(255, 255, 255, 0.75)',
  colorFillSelectSelected: 'rgba(0, 0, 0, 0.75)',
  colorCheckbox: 'rgba(255, 255, 255, 0.33)',
  colorCheckboxPrivate: 'rgba(255, 255, 255, 0.66)',
  colorReplyConnector: '#404040',

  // shadowCard: "0px 12px 32px rgba(255, 255, 255, 0.10)",
  shadowCard: "0px 12px 32px rgba(255, 255, 255, 0)",
  shadowSwitcher: "0px 2px 4px rgba(0, 0, 0, 0.33)",
  gradientContentCutoff:
    "180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.9) 100%",
  gradientNavbarCutoff:
    "180deg, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%",
  gradientNavbarFade:
    "180deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%",
  gradientSearchPreview:
    "180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%",
};

const vcTheme = {
  backgroundBody: "#000000",
  backgroundCard: "#333333",
  backgroundMenu: 'rgba(255, 255, 255, 0.9)',
  backgroundCardAction: "rgba(255, 255, 255, 0.1)",
  backgroundCardActionHover: "rgba(255, 255, 255, 0.15)",
  backgroundCardButton: "rgba(255, 255, 255, 0.1)",
  backgroundCardButtonOnewayHover: "rgba(255, 255, 255, 0.05)",
  backgroundTransparent: "rgba(255, 255, 255, 0)",
  backgroundInputFocus: 'rgba(51, 51, 51, 0.75)',
  backgroundInputFocusTransparent: 'rgba(51, 51, 51, 0.15)',
  backgroundInputSelected: 'rgba(255, 255, 255, 0.66)',
  backgroundSelectInputHover: 'rgba(0, 0, 0, 0.33)',
  backgroundSelectInputHoverTransparent: 'rgba(0, 0, 0, 0.15)',
  backgroundDropdown: 'rgba(255, 255, 255, 0.1)',
  backgroundDropdownHover: 'rgba(255, 255, 255, 0.2)',
  backgroundReply: 'rgba(255, 255, 255, 0.07)',
  backgroundReplyFocused: 'rgba(255, 255, 255, 0.1)',
  backgroundReplyCover: 'rgba(26, 26, 26, 0.25)',
  backgroundReplyButton: 'rgba(255, 255, 255, 0.07)',
  backgroundReplyButtonHover: 'rgba(255, 255, 255, 0.12)',

  colorBase: "#ffffff",
  colorBaseOpposite: "#000000",
  colorText: "rgba(255, 255, 255, 1)",
  colorTextNormal: "rgba(255, 255, 255, 0.66)",
  colorTextActive: "rgba(255, 255, 255, 0.75)",
  colorTextHalf: "rgba(255, 255, 255, 0.5)",
  colorTextFaded: "rgba(255, 255, 255, 0.33)",
  colorTextPlaceholder: "rgba(255, 255, 255, 0.07)",
  colorTextPlaceholderBody: "rgba(255, 255, 255, 0.05)",
  colorBorderSearchPreview: 'rgba(255, 255, 255, 0.1)',
  colorBorderInputDisabled: 'rgba(255, 255, 255, 0.12)',
  colorBorderInputTransparent: 'rgba(255, 255, 255, 0)',
  colorBorderSelectInput: 'rgba(255, 255, 255, 0.25)',
  colorBorderSelectInputHover: 'rgba(255, 255, 255, 0.33)',

  colorBorderSelect: 'rgba(255, 255, 255, 0.33)',
  colorBorderSelectSelected: 'rgba(0, 0, 0, 0.66)',
  colorFillSelect: 'rgba(255, 255, 255, 0.75)',
  colorFillSelectSelected: 'rgba(0, 0, 0, 0.75)',
  colorCheckbox: 'rgba(255, 255, 255, 0.33)',
  colorCheckboxPrivate: 'rgba(255, 255, 255, 0.66)',
  colorReplyConnector: '#404040',

  // shadowCard: "0px 12px 32px rgba(255, 255, 255, 0.10)",
  shadowCard: "0px 12px 32px rgba(255, 255, 255, 0)",
  shadowSwitcher: "0px 2px 4px rgba(0, 0, 0, 0.33)",
  gradientContentCutoff:
    "180deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.9) 100%",
  gradientNavbarCutoff:
    "180deg, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%",
  gradientNavbarFade:
    "180deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%",
  gradientSearchPreview:
    "180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%",
};

export { GlobalStyles, defaultTheme, founderTheme, vcTheme };
