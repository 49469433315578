import styled from 'styled-components';
import { transitionTween } from 'Constants';

// max-height: ${(props) => (props.error ? '100%' : '0%')};
const InputError = styled.div`
	/*min-height: 16px;*/
	/*position: relative;*/
	max-height: ${(props) => (props.error ? '24px' : '0px')};
	/*margin: 0 0 -16px 0;*/
	/*border: 1px solid blue;*/
	/*overflow: hidden;*/
	/*padding: 8px 0 0 0;*/
	opacity: ${(props) => (props.error ? 1 : 0)};
	/*background: rgba(0, 255, 0, 0.2);*/
	text-align: ${props => props.alignment ? props.alignment : 'left'};
	${transitionTween}
`;
// opacity: ${(props) => (props.error ? 1 : 0)};

export default InputError;
