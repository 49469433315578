import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const ReviewContentTextText = styled.p`
	margin: 0;
	padding: 0;
	font-size: 15px;
	font-weight: 400;
	line-height: 27px;
	text-align: left;
	opacity: ${(props) => (props.isContentPlaceholder ? 0.4 : 0.66)};
	white-space: pre-wrap;
	word-break: break-all;

	/*opacity: 0;*/
`;

export default ReviewContentTextText;