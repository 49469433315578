import styled from 'styled-components';
import { stylesNoSelect, transitionCurveMotion } from "Constants";

const DisclaimerButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 32px;
	background: ${({ theme }) => theme.backgroundCardAction};
	border: 0;
	border-radius: 8px;
	outline: none;
	overflow: hidden;
	cursor: pointer;

	${transitionCurveMotion}

	@media (hover: hover) {
		&:hover {
			background: ${({ theme }) => theme.backgroundCardActionHover};
		}
	}
`;

export default DisclaimerButton;