import styled from 'styled-components';
import { stylesNoSelect } from 'Constants';

const ContainerMenu = styled.div`
  /*position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;*/
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  ${stylesNoSelect}
`;

export default ContainerMenu;