import { useState } from 'react';
import SubmitPasswordButton from './SubmitPasswordButton';

function SubmitPassword({ children, isHovering, value, validate }) {
	const [isHoveringState, setIsHoveringState] = useState(false);

	const onMouseEnter = () => {
		setIsHoveringState(true);
	};

	const onMouseLeave = () => {
		setIsHoveringState(false);
	};

	return (
		<SubmitPasswordButton
			isHovering={isHovering || isHoveringState}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			action="submit"
			value={value}
			validate={validate}
		>
			{children}
		</SubmitPasswordButton>
	);
}

export default SubmitPassword;
