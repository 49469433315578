import styled from "styled-components";
import { transitionFade } from "Constants";

const ButtonPrimary = styled.button`
	display: flex;
	min-width: 194px;
	padding: 3px 32px 0px 32px;

	/*display: flex;*/

	/*display: flex;*/
	flex-direction: row;
	align-items: center;

	width: auto;
	min-width: 44px;
	height: 44px;
	/*margin: 0 64px;*/
	/*margin: 0 auto;*/
	margin: 0;
	background: ${({ theme }) => theme.colorBase};
	color: ${({ theme }) => theme.colorBaseOpposite};
	font-weight: 500;

	border: 0;
	border-radius: 8px;
	outline: 0;
	cursor: pointer;

	${transitionFade}
`;

export default ButtonPrimary;
