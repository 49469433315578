import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { transitionTween, stylesNoSelect } from "Constants";

const Switcher = styled(motion.div)`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	margin: -2px -4px 0px -4px;
	padding: 2px 4px 0px 4px;

	${transitionTween}

	@media (hover: hover) {
		&:hover {
			background: ${({ theme }) => theme.backgroundReplyButtonHover};
			border-radius: 6px;
		}
	}

	${stylesNoSelect}
`;

export default Switcher;
