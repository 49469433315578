// import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { COLOR } from '../../Constants';

const ContainerReviewScore = styled.div`
	display: flex;
	align-items: flex-end;
`;

const ReviewScoreScore = styled.h2`
	margin: 0;
	padding: 0;
	font-size: 22px;
	font-weight: 400;
	line-height: 22px;
	color: ${(props) => (props.color ? props.color : props.theme.colorTextFaded)};
	/*background: rgba(255, 0, 0, 0.1);*/
`;

const ReviewScoreBase = styled.div`
	flex: 0 auto;
	margin: 0 0 0 6px;
	padding: 0 0 2px 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	color: ${({theme}) => theme.colorTextFaded};
	/*background: rgba(0, 0, 255, 0.1);*/
`;

function ReviewScore({ score }) {
	const theme = useTheme();

	return (
		<ContainerReviewScore>
			<ReviewScoreScore
				color={
					score == '?'
						? theme.colorTextHalf
						: score >= 4
						? COLOR.POSITIVE
						: score >= 2.5
						? COLOR.NEUTRAL
						: COLOR.NEGATIVE
				}
			>
				{score ? score : 0}
			</ReviewScoreScore>
			<ReviewScoreBase> / 5</ReviewScoreBase>
		</ContainerReviewScore>
	);
}

export default ReviewScore;
