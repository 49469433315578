import styled from 'styled-components';
import { DEVICE } from 'Constants';

const VCHeaderScoreBase = styled.div`
	flex: 0 auto;
	margin: 0 0 0 6px;
	padding: 0 0 2px 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	color: ${({theme}) => theme.colorTextFaded};

	@media (min-width: ${DEVICE.minDesktop}) {
		padding: 0 0 9px 0;
	}
`;

export default VCHeaderScoreBase;