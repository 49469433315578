
import React from 'react';
import { useState, cloneElement } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_ROOT } from 'Constants';

const FormContent = styled.form`
  margin: 0;
  padding: 0;
`;

function Form({ onSubmit, handleSubmit, children }) {
  const [focusIndex, setFocusIndex] = useState(-1);
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [messageSuccess, setMessageSuccess] = useState(null);

  // useEffect(() => {
  //   console.log('errorObject has changed', errorObject);
  //   setErrorData(errorObject);
  // }, [errorObject]);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (onSubmit != null) {
      // console.log('Form onSubmit');
      // console.log(formData);
      // console.log(
      //   `${process.env.IS_LIVE ? 'https://nugu-back-v2.vercel.app' : 'http://localhost:8000'}${onSubmit}`
      // );
      // console.log('---------');

      axios
        .post(
          `${API_ROOT()}${onSubmit}`,
          formData
        )
        .then(function(response) {
          console.log('submitted and received', response);
          
          setHasSubmitted(true);

          if (response.data) {
            setMessageSuccess(response.data);
          }

          return response.data;
        })
        .catch(function(error, response) {
          // console.log('Catch', error, error.response.data);
          setHasSubmitted(true);

          if (error.response && error.response.data) {
            setErrorData(error.response.data);
          }

          return { error: 'something went wrong!' };
        });

      // axios
      //   .get(onSubmit)
      //   .then(response => {
      //     console.log("response", response);
      //     setHasSubmitted(true);
      //     // alert('form submitted!');
      //   })
      //   .catch(error => {
      //     // alert('form error!');
      //   });
    } else if (handleSubmit != null) {
      handleSubmit(formData);
      setHasSubmitted(true);
    }

    return false;
  };

  const isComponent = (component) => {
    return (
      (typeof component === 'function' && !!component.prototype.isReactComponent) ||
      (typeof component === 'function' && String(component).includes('return React.createElement')) ||
      React.isValidElement(component)
    );
  };

  const onChange = (name, value) => {
    let formDataCopy = formData;
    formDataCopy[name] = value;
    setFormData(formDataCopy);

    setHasSubmitted(false);

    let errorDataCopy = errorData;
    errorDataCopy[name] = ``;
    setErrorData(errorDataCopy);
  };

  const onFocus = (index) => {
    setFocusIndex(index);
  };

  const onBlur = (index) => {
    if (index == focusIndex) {
      setFocusIndex(-1);
    }
  };

  // console.log('children', children, children.length);
  return (
    <FormContent onSubmit={handleOnSubmit}>
      {children.map((child, index) => {
        if (isComponent(child)) {
          let childProps = {
            key: `${onSubmit}-formChild-${index}`,
            onChange: onChange,
            value: formData[child.props.name],
            onFocus: onFocus,
            onBlur: onBlur,
            index: index,
            isFormValid: isFormValid,
            isFormElement: true,
            hasSubmitted: hasSubmitted,
            messageSuccess: messageSuccess,
          };

          if (onSubmit != null && handleSubmit == null) {
            // console.log('Error for', child.props.name, errorData[child.props.name], hasSubmitted);
            childProps['error'] = errorData[child.props.name];
            // childProps['error'] = hasSubmitted ? errorData[child.props.name] : null;
          }

          return cloneElement(child, childProps);
        }

        return child;
        // ADD ELEMENT CHECK FOR BUTTONS VS INPUTS
        // SO WE CAN PREFILL BUTTON ONCLICK
        // AND ALSO SET WHEN IT SHOULD BE DISABLED
      })}
    </FormContent>
  );
}

export default Form;
