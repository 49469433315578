import { useEffect, useState, useRef, createRef } from "react";
import { useTheme } from "styled-components";
import { motion, useAnimation } from "framer-motion";
// import VCNames from 'utils/VCNames';
import { useVC } from "contexts/VCContext";
import Consonants from "utils/Consonants";
import { transitionMotionSpring } from "Constants";

import ContainerSuggestions from "./ContainerSuggestions";
import SuggestionsItem from "./SuggestionsItem";

function SearchSuggestions({
	searchValue,
	updateSearchValue,
	activeIndex,
	updateActiveIndex,
	suggestionsCount,
	setSuggestionsCount,
	isFocused,
	recentAction,
	setRecentAction,
	setSuggestionsListParent,
}) {
	const [shouldRender, setShouldRender] = useState(isFocused);
	const [suggestionList, setSuggestionList] = useState([]);
	// const [activeIndex, setActiveIndex] = useState(-1);

	const theme = useTheme();
	const { VCNames } = useVC();

	// const spacer =

	const animateSuggestions = useAnimation();
	const animateActive = useAnimation();
	const refsItems = useRef(
		[null, null, null, null, null].map(() => createRef())
	);

	useEffect(() => {
		let shouldRenderLocal =
			searchValue != "" && searchValue != null && isFocused;
		setShouldRender(shouldRenderLocal);

		if (shouldRenderLocal) {
			// console.log(
			// 	'suggestionList1',
			// 	Consonants.includes(searchValue.slice(-1)),
			// 	searchValue.length > 1
			// );
			let suggestionsListLocal;
			if (
				Consonants.includes(searchValue.slice(-1)) &&
				searchValue.length > 1
			) {
				// console.log('Constrainted search for', searchValue.slice(0, -1));
				let searchValueLower = searchValue.toLowerCase();
				// console.log("searchValueLower", searchValueLower);
				suggestionsListLocal = VCNames.filter((firm) =>
					firm.toLowerCase().includes(searchValueLower.slice(0, -1))
				).sort((a, b) =>
					a.indexOf(searchValueLower) < b.indexOf(searchValueLower)
						? -1
						: 1
				);
			} else {
				// console.log(
				// 	'General search for',
				// 	searchValue,
				// 	VCNames.filter((firm) => firm.includes(searchValue))
				// );
				let searchValueLower = searchValue.toLowerCase();
				suggestionsListLocal = VCNames.filter((firm) =>
					firm.toLowerCase().includes(searchValueLower)
				).sort((a, b) =>
					a.indexOf(searchValueLower) < b.indexOf(searchValueLower)
						? -1
						: 1
				);
			}

			setSuggestionList(suggestionsListLocal);
			setSuggestionsCount(
				suggestionsListLocal.length > 5
					? 5
					: suggestionsListLocal.length
			);
			setSuggestionsListParent(suggestionsListLocal);
			animateSuggestions.start({
				opacity: 1,
				transition: transitionMotionSpring,
			});
		} else {
			animateSuggestions.start({
				opacity: 0,
				transition: { type: "tween", duration: 0.15 },
			});
			setTimeout(() => {
				setSuggestionList([]);
				setSuggestionsCount(0);
				setSuggestionsListParent([]);
			}, 150);
		}
	}, [searchValue, isFocused]);

	useEffect(() => {
		if (activeIndex >= 0) {
			animateActive.start({
				y: activeIndex * 42,
				width: refsItems.current[
					activeIndex
				].current.getBoundingClientRect().width,
				opacity: 1,
				transition: transitionMotionSpring,
			});
			updateSearchValue(suggestionList[activeIndex]);
			animateSuggestions.start({
				opacity: 1,
				transition: transitionMotionSpring,
			});
		} else {
			animateActive.start({
				y: -44,
				opacity: 0,
				transition: transitionMotionSpring,
			});
			updateSearchValue(searchValue); // freezedValue
			animateSuggestions.start({
				opacity: 0,
				transition: { type: "tween", duration: 0.15 },
			});
		}

		// if (activeIndex >= 0) {
		// 	// console.log(refsItems, refsItems.current[activeIndex], refsItems.current[activeIndex].current);
		// }
	}, [activeIndex]);

	// console.log(
	// 	VCNames.filter((firm) =>
	// 		Consonants.includes(searchValue.slice(-1))
	// 			? firm.includes(searchValue)
	// 			: firm.includes(searchValue.slice(0, -1))
	// 	)
	// );

	const onMouseMove = () => {
		setRecentAction("MOUSE");
	};

	return (
		<motion.div animate={animateSuggestions} style={{ position: 'relative' }}>
			<ContainerSuggestions onMouseMove={onMouseMove}>
				<motion.div
					animate={animateActive}
					initial={{
						position: "absolute",
						top: 1,
						zIndex: -1,
						width: 100,
						height: 34,
						background: theme.backgroundCard,
						borderRadius: 8,
						pointerEvents: "none",
						opacity: 0,
					}}
				/>
				{suggestionList.slice(0, 5).map((firm, index) => (
					<SuggestionsItem
						refsItems={refsItems}
						isActive={index == activeIndex}
						index={index}
						updateSearchValue={updateSearchValue}
						updateActiveIndex={updateActiveIndex}
						recentAction={recentAction}
					>
						{firm}
					</SuggestionsItem>
				))}
			</ContainerSuggestions>
		</motion.div>
	);
}

export default SearchSuggestions;
