import styled from 'styled-components';

const H1 = styled.h1`
	margin: 0 0 20px 0;
	padding: 0;
	font-size: 32px;
	line-height: 44px;
	font-weight: 400;

	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 1)};
`;

const H2 = styled.h2`
	color: ${({ theme }) => theme.colorText};
`;

const H3 = styled.h3`
	font-size: 18px;
	line-height: 27px;
	
	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};
`;

const H4 = styled.h4`
	color: ${({ theme }) => theme.colorText};
`;

const H5 = styled.h5`
	margin: 0;
	padding: 0;
	font-size: 15px;
	line-height: 27px;
	font-weight: ${props => props.isLabel ? 600 : 400};

	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};
`;

const H6 = styled.h6`
	position: relative;
	top: 8px;
	/*margin: 8px 0 0 0;*/
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;

	text-align: ${(props) => (props.alignment ? props.alignment : 'left')};

	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};
`;

const P = styled.p`
	/*max-width: 504px;*/
	margin: 0 0 20px 0;

	font-size: 15px;
	line-height: 27px;
	font-weight: 400;

	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.66)};

	&:last-child {
		margin: 0;
	}

	a {
		font-weight: 600;
	}
`;

export { H1, H2, H3, H4, H5, H6, P };