import styled, { css } from "styled-components";
import { motion } from "framer-motion";

const ReplyContent = styled(motion.div)`
	flex: 1;
	width: 100%;
	margin: 16px 0 0 0;
	padding: 0;
	background: ${({ theme, hasReplied }) =>
		hasReplied ? "rgba(26, 161, 135, 0.1)" : theme.backgroundReply};
	border-radius: 8px;

	-webkit-transition: background 150ms ease-in-out, padding 150ms ease-in-out;
	-moz-transition: background 150ms ease-in-out, padding 150ms ease-in-out;
	-ms-transition: background 150ms ease-in-out, padding 150ms ease-in-out;
	-o-transition: background 150ms ease-in-out, padding 150ms ease-in-out;
	transition: background 150ms ease-in-out, padding 150ms ease-in-out;

	${({ isFocused }) =>
		isFocused &&
		css`
			padding: 0 0 48px 0;
			background: ${({ theme }) => theme.backgroundReplyFocused};
		`}
`;

export default ReplyContent;
