import styled from "styled-components";
import { API_ROOT, DEVICE } from "Constants";

const ContainerReviewPreview = styled.div`
  width: 100%;
  height: auto;
  /*margin: 186px 0 0 0;*/
  margin: 150px 0 0 0;
  padding: 0;

  @media (min-width: ${DEVICE.minTablet}) {
    margin: 142px 0 0 0;
  }
`;

export { ContainerReviewPreview };