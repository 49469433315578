import styled from 'styled-components';

import ContainerIndividualReview from '../ContainerIndividualReview';
import ReviewPlaceholder from 'components/Review/ReviewPlaceholder';
import IndividualReviewHeaderPlaceholder from './IndividualReviewHeaderPlaceholder';

function IndividualReviewPlaceholder() {
	return (
		<ContainerIndividualReview>
			<IndividualReviewHeaderPlaceholder />
			<ReviewPlaceholder />
		</ContainerIndividualReview>
	);
}

export default IndividualReviewPlaceholder;
