import styled from 'styled-components';
import { stylesNoSelect, transitionTween, COLOR } from 'Constants';

const InputField = styled.textarea`
	max-width: ${(props) => (props.maxWidth ? '672px' : '100%')};

	width: 100%;
	min-height: 200px;

	margin: 0;
	padding: 15px 22px 0px 22px;

	font-size: 15px;
	line-height: 27px;
	font-weight: 400;

	/*background: rgba(255, 237, 237, 0.2);*/
	/*background: rgba(0, 0, 0, 0.02);*/
	background: ${({ theme }) => theme.backgroundCard};
	color: ${(props) =>
		props.disabled
			? props.theme.colorTextHalf
			: props.theme.colorTextActive};
	text-align: ${(props) => (props.alignment ? props.alignment : 'left')};

	${(props) =>
		props.disabled &&
		props.defaultValue != null &&
		`background: ${props.theme.backgroundCard} !important;`}

	border: ${(props) =>
		props.error
			? `1px solid ${COLOR.NEGATIVE}`
			: props.disabled
			? `1px solid ${props.theme.colorBorderInputDisabled}`
			: `1px solid ${props.theme.colorBorderInputTransparent}`};
	border-radius: 8px;
	outline: none;

	${(props) => props.disabled && 'cursor: not-allowed;'}

	&:focus {
		background: ${(props) =>
			props.isTransparent
				? props.theme.backgroundInputFocusTransparent
				: props.theme.backgroundInputFocus};
		border: ${({ theme }) => `1px solid ${theme.colorTextFaded}`};
	}

	resize: vertical;

	${stylesNoSelect}

	-webkit-transition: opacity 250ms ease-in-out, background 250ms ease-in-out, color 250ms ease-in-out, border 250ms ease-in-out;
	-moz-transition: opacity 250ms ease-in-out, background 250ms ease-in-out, color 250ms ease-in-out, border 250ms ease-in-out;
	-ms-transition: opacity 250ms ease-in-out, background 250ms ease-in-out, color 250ms ease-in-out, border 250ms ease-in-out;
	-o-transition: opacity 250ms ease-in-out, background 250ms ease-in-out, color 250ms ease-in-out, border 250ms ease-in-out;
	transition: opacity 250ms ease-in-out, background 250ms ease-in-out, color 250ms ease-in-out, border 250ms ease-in-out;
`;
	// ${transitionTween}

export default InputField;

// background: ${(props) => (props.transparent ? 'rgba(255, 255, 255, 0.33)' : 'rgba(255, 255, 255, 1)')};
