import styled from 'styled-components';
import { DEVICE } from '../../Constants';

const IndividualReviewHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: auto;
	max-width: 536px;
	margin: 0 0 24px 0;
	padding: 0;

	@media (min-width: ${DEVICE.minDesktop}) {
		max-width: calc(266px + 32px);
		align-items: flex-end;
		padding: 8px 32px 0 0;
		/*background: rgba(0, 0, 255, 0.3);*/
	}
`;

export default IndividualReviewHeader;
