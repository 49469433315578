import styled from 'styled-components';
import { DEVICE } from 'Constants';

const NavbarContent = styled.nav`
  position: relative;
  /*display: grid;
  grid-auto-flow: column;
  align-items: center;*/

  display: block;
  /*display: flex;
  flex-direction: row;
  align-items: center;*/

  width: 100%;
  /*max-width: calc(1352px + 40px + 40px);*/

  /*height: 112px;*/
  height: auto;

  margin: 0 auto;
  padding: 34px 16px;
  /*background: rgba(255, 0, 0, 0.2);*/

  /*background: rgba(0, 255, 0, 0.3);*/

  @media (min-width: ${DEVICE.minTablet}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${(props) => (props.isNavbarMinimal ? 'space-between' : 'flex-start')};
    height: 112px;
  }

  @media (min-width: ${DEVICE.minDesktop}) {
    max-width: calc(1352px + 40px + 40px);
    height: 112px;
    padding: 34px 40px;
  }
`;

export default NavbarContent;
