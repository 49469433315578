import { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { API_ROOT, DEVICE } from 'Constants';
import Cookies from 'universal-cookie';

import { useLocation } from 'react-router-dom';

import Review from 'components/Review';
import VC from 'components/VC';

const ContainerVCPage = styled.div`
  max-width: calc(536px + 32px);
  width: 100%;
  height: auto;
  margin: 186px auto 0 auto;
  padding: 0 16px;

  @media (min-width: ${DEVICE.minTablet}) {
    margin: 142px auto 0 auto;
  }
`;

function VCPage({ match, score, isTrending, content }) {
  const location = useLocation();
  const cookies = new Cookies();

  const [dataReviews, setDataReviews] = useState([]);
  const [dataVC, setDataVC] = useState({
    vc: match ? match.params.vc : '',
    count: 0,
    score: 0,
  });
  const [hasDataSet, setHasDataSet] = useState(false);
  // const [isReplyable, setIsReplyable] = useState(false);

  useEffect(() => {
    console.log('COOKIE COMPARISON', cookies.get('nugu-vc'), match.params.vc);

    // if (cookies.get('nugu-vc') == match.params.vc) {
    //   setIsReplyable(true);
    // }
  }, []);

  useEffect(() => {
    // console.log('Change VC Page', match.params.vc, location.pathname);
    setDataVC({
      vc: match ? match.params.vc : '',
      count: 0,
      score: 0,
    });

    callData();
    // console.log('on use effect', match, dataVC);
    // console.log('api', `${API_ROOT()}/vc/${dataVC.vc ? dataVC.vc : match ? match.params.vc : ''}`);
  }, [match.params.vc]);

  const callData = () => {
    // console.log('callData', dataVC.vc);
    axios
      .get(`${API_ROOT()}/vc/${match.params.vc}`)
      // .get(`${API_ROOT()}/vc/${dataVC.vc ? dataVC.vc : match ? match.params.vc : ''}`)
      .then(function(response) {
        // console.log('data', response.data.result);
        let responseData =
          response && response.data && response.data.result
            ? response.data.result
            : { vc: null, score: 0, reviews: [] };

        console.log('VC RESPONSE', response);
        setDataReviews(responseData.reviews);
        setDataVC({ vc: responseData.vc, score: responseData.score, count: responseData.reviews.length });
        setHasDataSet(true);
      })
      .catch(function(error) {
        console.log(error);
      })
      .then(function() {
        /* aka done */
      });
  };

  return (
    <VC
      vc={dataVC.vc}
      count={dataVC.count}
      score={dataVC.score}
      dataReviews={dataReviews}
      hasDataSet={hasDataSet}
      // isReplyable={isReplyable}
    />
    // <ContainerVCPage>
    //   <VC/>
    //   <VCHeader  />
    //   {dataReviews.map((data, index) => (
    //     <Review {...data} />
    //   ))}
    // </ContainerVCPage>
  );
}

export default VCPage;
