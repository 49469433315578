import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { transitionCurveMotion, stylesNoSelect } from '../../../Constants';

const RateUnhelpful = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 44px;
	background: ${(props) => props.active ? props.theme.backgroundCardButton : props.theme.backgroundTransparent};
	border-radius: 8px;
	cursor: pointer;
	padding: 0;

	${transitionCurveMotion}
	${stylesNoSelect}

	@media (hover: hover) {
		&:hover {
			background: ${(props) =>
				props.active ? props.theme.backgroundCardButton : props.theme.backgroundCardButtonOnewayHover};
		}
	}
`;

const RateUnhelpfulIcon = styled.div`
	margin: 0 8px 0 0;
	padding: 4px 0 0 0;
`;

const RateUnhelpfulText = styled.h4`
	margin: 0;
	padding: 2px 0 0 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	opacity: ${(props) => (props.active ? 0.75 : 0.66)};
	${transitionCurveMotion}
`;

function ReviewRateUnhelpful({ rating, setRating }) {
	const animateIcon = useAnimation();
	const theme = useTheme();

	const onRateChange = () => {
		if (rating != 'unhelpful') {
			// animateIcon.start({ scale: 1.3, rotate: 15 });
			animateIcon.start({ scale: 1.15, y: 4 });

			setTimeout(() => {
				// animateIcon.start({ scale: 1, rotate: 0 });
				animateIcon.start({ scale: 1, y: 0 });
			}, 400);
		} else {
		}

		setRating('unhelpful');
	};

	return (
		<RateUnhelpful active={rating == 'unhelpful'} onClick={onRateChange}>
			<RateUnhelpfulIcon>
				<motion.div animate={animateIcon}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="none"
						viewBox="0 0 16 16"
					>
						<path
							stroke={theme.colorText}
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M11.333 1.333h1.78a1.54 1.54 0 011.554 1.334v4.666a1.54 1.54 0 01-1.554 1.334h-1.78M6.667 10v2.667a2 2 0 002 2l2.666-6V1.333h-7.52A1.333 1.333 0 002.48 2.467l-.92 6A1.333 1.333 0 002.893 10h3.774z"
							opacity="0.75"
						/>
					</svg>
				</motion.div>
			</RateUnhelpfulIcon>
			<RateUnhelpfulText active={rating == 'unhelpful'}>도움이 안됩니다</RateUnhelpfulText>
		</RateUnhelpful>
	);
}

export default ReviewRateUnhelpful;
