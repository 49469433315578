import styled from 'styled-components';

const VCHeaderCount = styled.div`
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	color: ${(props) => (props.color ? props.color : props.theme.colorTextNormal)};
`;

export default VCHeaderCount;
