import styled from 'styled-components';
import { DEVICE } from 'Constants';

const DisclaimerMessage = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 16px;

	@media (min-width: ${DEVICE.minTablet}) {
		padding: 0 32px;
	}
`;

export default DisclaimerMessage;