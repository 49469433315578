import styled from 'styled-components';

const MenuDividerLine = styled.div`
	width: 100%;
	height: 1px;
	/*background: #ffffff; @0.33 OPACITY*/
	background: #646464;
	border-radius: 1px;
	transform-origin: 0 0;
`;

export default MenuDividerLine;
