import styled from 'styled-components';
import { transitionTween } from 'Constants';

const MenuContentItem = styled.div`
	display: flex;
	align-items: center;
	margin: 0 0 16px 0;
	opacity: ${(props) => (props.isActive ? 1 : 0.33)};

	@media (hover: hover) {
		&:hover {
			opacity: 1;
		}
	}

	${transitionTween}
`;

export default MenuContentItem;
