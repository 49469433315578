import styled from 'styled-components';
import { transitionTween } from 'Constants';
import { Link } from 'react-router-dom';

const AnchorLink = styled(Link)`
	margin: 0 0 20px 0;

	font-size: 15px;
	line-height: 27px;
	font-weight: 600;

	color: ${(props) => (props.color ? props.color : 'inherit')};
	opacity: ${(props) => (props.opacity ? props.opacity : 0.75)};

	text-decoration: none;
	/*border-bottom: 1px solid ${(props) => (props.color ? props.color : 'inherit')};*/

	&:last-child {
		margin: 0;
	}

	@media (hover: hover) {
		&:hover {
			opacity: 1;
		}
	}

	${transitionTween}
`;

export default AnchorLink;