import { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import { API_ROOT, DEVICE } from "Constants";
import {
  ContainerAuth,
  AuthRegister,
  AuthLogin,
  AuthLoginContent,
} from "layout/LayoutFounderAuth";
import { H1, H6, P } from "components/Typography";
import Form from "components/Form";
import InputText from "components/Input/InputText";
import InputSelectVertical from "components/Input/InputSelectVertical";
import InputSelectHorizontal from "components/Input/InputSelectHorizontal";
import InputPassword from "components/Input/InputPassword";
import ButtonForm from "components/Button/ButtonForm";
import { Spacer4, Spacer8 } from "components/Spacer";

import InputSuccessText from "components/Input/InputSuccessText";
import InputErrorText from "components/Input/InputErrorText";

const LoginMessage = styled.div`
  margin: -16px 0 0 0;
`;

function FounderAuthPage() {
  const { currentUser, requestLogin } = useAuth();
  const { isFounder, isVC } = useUser();

  const [loginSuccessMessage, setLoginSuccessMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    console.log(currentUser, requestLogin);

    if (currentUser) {
      if (isFounder) {
        history.push(`/founders/write-review`);
      }
      // else if (isVC) {
      //   history.push(`/vc-mode`);
      // }
    }
  }, [currentUser]);

  function handleLoginSuccess(res) {
    console.log("Login successful", res);
    setLoginSuccessMessage("이메일로 로그인 링크를 보내드렸습니다.");
    setLoginErrorMessage("");
    setIsLoading(false);
  }

  function handleLoginError(err) {
    console.log("handleLoginError", err);
    setLoginSuccessMessage("");
    setLoginErrorMessage(
      "이메일을 다시 확인해주세요. 올바르지 않거나 초대가 안 되어있는 이메일 입니다."
    );
    setIsLoading(false);
  }

  const handleLoginRequest = (formData) => {
    console.log("handleLogin", formData);
    setIsLoading(true);
    setLoginSuccessMessage("");
    setLoginErrorMessage("");
    requestLogin(formData.email, handleLoginSuccess, handleLoginError);
  };

  return (
    <ContainerAuth>
      <AuthRegister>
        <H1>
          리뷰를 작성하시기 전에
          <br />
          신원 확인이 필요합니다
        </H1>
        <P>
          취급하는 모든 개인정보는 익명으로 처리되며, 개인정보 보호법 등 관련 법령상의 개인정보보호 규정을 충실히 준수하여 관리됩니다.
        </P>
        <Spacer8 />
        <Form onSubmit="/founder/request-access">
          <InputText
            name="name"
            label="이름"
            placeholder="이름"
            isTransparent={true}
            required
          />
          <InputText
            name="company"
            label="회사명"
            placeholder="회사명"
            isTransparent={true}
            required
          />
          <InputSelectVertical
            name="isFounder"
            label="이 회사의 창립멤버인가요?"
            placeholder="이 회사의 창립멤버인가요?"
            isTransparent={true}
            required
            data={[
              { text: "네", value: "네" },
              { text: "아니요", value: "아니요" },
            ]}
          />
          <InputText
            name="position"
            label="직책"
            placeholder="직책"
            isTransparent={true}
            required
          />
          <InputText
            name="website"
            label="회사 공식 웹사이트"
            placeholder="회사 공식 웹사이트"
            isTransparent={true}
            required
          />
          <InputText
            name="email"
            type="email"
            label="회사 이메일"
            description="본인 인증을 위해 반드시 회사 이메일을 입력해 주세요. Gmail 등 타 도메인은 가입이 어렵습니다."
            placeholder="founder@nugu.money"
            isTransparent={true}
            required
          />
          <InputText
            name="profile"
            label="프로필"
            description="링크드인 프로필, 블로그, 개인 웹사이트 등을 알려주시면 본인 확인에 도움이 됩니다."
            placeholder=""
            isTransparent={true}
          />
          <ButtonForm action="submit">창업자로 등록하기</ButtonForm>
        </Form>
      </AuthRegister>
      <AuthLogin>
        <AuthLoginContent>
          <H1>창업자 모드</H1>
          <P>이미 초대 받으셨으면, 초대 받은 회사 이메일로 로그인 해주세요.</P>
          <Spacer8 />
          <Form handleSubmit={handleLoginRequest}>
            <InputPassword
              name="email"
              label={false}
              placeholder="누구머니 이메일"
              isPasswordVisible={true}
              isTransparent={false}
              defaultValue={cookies.get("nugu-email")}
              validate={(value) => {
                return (
                  value &&
                  value.length > 3 &&
                  value.includes("@") &&
                  value.includes(".")
                );
              }}
              required
            />
            <LoginMessage>
              {isLoading &&
                loginSuccessMessage == "" &&
                loginErrorMessage == "" && (
                  <H6 alignment="center">연결중입니다..</H6>
                )}
              {loginSuccessMessage && loginErrorMessage == "" && (
                <InputSuccessText alignment="center">
                  {loginSuccessMessage}
                </InputSuccessText>
              )}
              {InputErrorText && loginSuccessMessage == "" && (
                <InputErrorText alignment="center">
                  {loginErrorMessage}
                </InputErrorText>
              )}
            </LoginMessage>
          </Form>
        </AuthLoginContent>
      </AuthLogin>
    </ContainerAuth>
  );
}

export default FounderAuthPage;
