import Private from './Private';
import PrivateCheckbox from './PrivateCheckbox';
import PrivateText from './PrivateText';

function InputPrivate({ isPrivate, onChangePrivate }) {
	return (
		<Private onClick={() => onChangePrivate(!isPrivate)}>
			<PrivateCheckbox type="checkbox" isPrivate={isPrivate} />
			<PrivateText>비공개로 출시하기</PrivateText>
		</Private>
	);
}

export default InputPrivate;
