import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

import { transitionMotionSpring, transitionMotionTween } from 'Constants';
import ContainerNavbarMenu from './ContainerNavbarMenu';
import MenuIcon from './MenuIcon';
import MenuIconLine from './MenuIconLine';

function NavbarMenu({ onNavbarToggle, shouldRenderMenu, isDarkModeNavbar, isDarkModeMenu }) {
	const [isHovering, setIsHovering] = useState(false);

	const animateLine1 = useAnimation();
	const animateLine2 = useAnimation();
	const animateLine3 = useAnimation();

	useEffect(() => {
		if (shouldRenderMenu) {
			animateLine2.start({ opacity: 0, transition: transitionMotionTween });
			animateLine1.start({ rotate: 45, y: 1, transition: transitionMotionSpring });
			animateLine3.start({ rotate: -45, y: -1, transition: transitionMotionSpring });
		} else {
			animateLine2.start({ opacity: 1, transition: transitionMotionTween });
			animateLine1.start({ rotate: 0, y: -7, transition: transitionMotionSpring });
			animateLine3.start({ rotate: 0, y: 7, transition: transitionMotionSpring });
		}
	}, [shouldRenderMenu]);

	return (
		<ContainerNavbarMenu onClick={onNavbarToggle} shouldRenderMenu={shouldRenderMenu}>
			<MenuIcon>
				<motion.div animate={animateLine1} initial={{ y: -7, transformOrigin: '50% 50%' }}>
					<MenuIconLine
						shouldRenderMenu={shouldRenderMenu}
						isDarkModeNavbar={isDarkModeNavbar}
						isDarkModeMenu={isDarkModeMenu}
					/>
				</motion.div>
				<motion.div animate={animateLine2}>
					<MenuIconLine
						shouldRenderMenu={shouldRenderMenu}
						isDarkModeNavbar={isDarkModeNavbar}
						isDarkModeMenu={isDarkModeMenu}
					/>
				</motion.div>
				<motion.div animate={animateLine3} initial={{ y: 7, transformOrigin: '50% 50%' }}>
					<MenuIconLine
						shouldRenderMenu={shouldRenderMenu}
						isDarkModeNavbar={isDarkModeNavbar}
						isDarkModeMenu={isDarkModeMenu}
					/>
				</motion.div>
			</MenuIcon>
		</ContainerNavbarMenu>
	);
}

export default NavbarMenu;
