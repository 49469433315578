import styled from 'styled-components';
import { DEVICE } from 'Constants';

const ContainerReview = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: calc(100% + 32px);
	min-height: 100vh;
	margin: 0 -16px;
	padding: 0;

	@media (min-width: ${DEVICE.minTabletLarge}) {
		/*flex-direction: row;
		align-items: stretch;
		justify-content: center;*/
		/*transform: translateX(229px);*/
		/*transform: translateX(-81px);*/
		/*transform: translateX(81px);*/
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		flex-direction: row;
		align-items: stretch;
		justify-content: center;

		padding-left: 81px;
		/*transform: translateX(81px);*/
	}
`;

const ReviewWrite = styled.div`
	flex: 0 auto;
	/*max-width: 834px;*/
	max-width: calc(536px + 32px);
	padding: 96px 16px 64px 16px;

	@media (min-width: ${DEVICE.minTablet}) {
		padding: 128px 16px 64px 16px;
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		max-width: calc(536px + 80px);
		flex: 1;
		padding: 142px 40px 142px 40px;
	}

	@media (min-width: ${DEVICE.minDesktopLargeNav}) {
		/*padding: 142px 108px 142px calc((100vw - (1352px + 40px + 40px)) / 2 + 40px);*/
		padding: 142px 72px 142px 0;
	}
`;

const ReviewPreview = styled.div`
	flex: 1;
	width: 100%;
	max-width: 536px;

	/*padding: 186px 108px 0 108px;*/
	flex: 0 auto;
	padding: 40px 0;

	border-top: 1px solid rgba(0, 0, 0, 0.2);

	@media (min-width: ${DEVICE.minTablet}) {
		/*padding: 0px 16px 0 24px;*/
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		flex: 1;
		padding: 142px 40px 142px 40px;
		border-top: none;
	}

	@media (min-width: ${DEVICE.minDesktopLargeNav}) {
		/*padding: 142px calc((100vw - (1352px + 40px + 40px)) / 2 + 40px) 142px 108px;*/
		padding: 0;
	}
`;

const ReviewPreviewContent = styled.div`
	@media (min-width: ${DEVICE.minTablet}) {
		position: -webkit-sticky;
		position: sticky;
		top: 181px;
		/*top: calc((100vh - 181px) / 2);*/
	}
`;

const ReviewSuccess = styled.div`
	padding: 96px 16px;
	transform: translateX(-81px);

	@media (min-width: ${DEVICE.minTablet}) {
		padding: 128px 16px;
	}

	@media (min-width: ${DEVICE.minDesktop}) {
		padding: 142px 16px;
	}

	@media (min-width: ${DEVICE.minDesktopLargeNav}) {
		padding: 142px 16px;
	}
`;

export { ContainerReview, ReviewWrite, ReviewPreview, ReviewPreviewContent, ReviewSuccess };
