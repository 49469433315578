import styled from 'styled-components';
import { stylesNoSelect, transitionTween, COLOR } from 'Constants';

// border: ${(props) => (props.isSelected ? `1px solid rgba(0, 0, 0, 0.15)` : '1px solid rgba(0, 0, 0, 0)')};
const SelectOptionRadio = styled.div`
	position: relative;
	display: block;
	width: 18px;
	height: 18px;
	margin: 0 12px 0 0;
	border: ${(props) =>
		props.isSelected ? `1px solid ${props.theme.colorBorderSelectSelected}` : `1px solid ${props.theme.colorBorderSelect}`};
	border-radius: 50%;

	&:after {
		content: '';
		position: absolute;
		top: 3px;
		left: 3px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: ${(props) => (props.isSelected ? props.theme.colorFillSelectSelected : props.theme.colorFillSelect)};
		opacity: ${(props) => (props.isSelected ? 1 : 0)};
		${transitionTween}
	}

	${transitionTween}
`;

export default SelectOptionRadio;