import styled from 'styled-components';

const SpacerTemplate = `
	display: block;
	width: 100%;
	margin: 0;
	padding: 0;
`;

const Spacer4 = styled.div`
	${SpacerTemplate}
	height: 4px;
`;

const Spacer8 = styled.div`
	${SpacerTemplate}
	height: 8px;
`;

const Spacer12 = styled.div`
	${SpacerTemplate}
	height: 12px;
`;

const Spacer16 = styled.div`
	${SpacerTemplate}
	height: 16px;
`;

const Spacer20 = styled.div`
	${SpacerTemplate}
	height: 20px;
`;

const Spacer24 = styled.div`
	${SpacerTemplate}
	height: 24px;
`;

const Spacer32 = styled.div`
	${SpacerTemplate}
	height: 32px;
`;

const Spacer40 = styled.div`
	${SpacerTemplate}
	height: 40px;
`;

const Spacer48 = styled.div`
	${SpacerTemplate}
	height: 48px;
`;

export { Spacer4, Spacer8, Spacer12, Spacer16, Spacer20, Spacer24, Spacer32, Spacer40, Spacer48 };