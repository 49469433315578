import styled from "styled-components";
import { transitionTween } from "Constants";

const MenuIconLine = styled.span`
	display: block;
	width: 32px;
	height: 1px;
	background: ${(props) =>
		props.shouldRenderMenu || props.isDarkModeNavbar || props.isDarkModeMenu
			? props.theme.colorBaseOpposite
			: props.theme.colorBase};

	/*background: ${(props) =>
		props.shouldRenderMenu || props.isDarkModeNavbar || props.isDarkModeMenu
			? "#fff"
			: "#000"};*/
	opacity: ${(props) => (props.shouldRenderMenu ? 1 : 0.75)};

	transform-style: preserve-3d;

	${transitionTween}
`;

export default MenuIconLine;
