import { useState, useEffect } from "react";
import styled from "styled-components";
import { useVC } from "contexts/VCContext";
import { useWindowWidth } from "@react-hook/window-size";

const ContainerVerified = styled.div`
	position: relative;
	top: 2px;
	margin: 0 0 0 8px;
`;

function Verified({ vc }) {
	const { VCData } = useVC();
	const windowWidth = useWindowWidth();
	const [scrollbarWidth, setScrollbarWidth] = useState(0);

	useEffect(() => {
		setScrollbarWidth(window.innerWidth - document.body.offsetWidth);
	}, []);

	if (VCData[vc]?.isVerified) {
		return (
			<ContainerVerified>
				{windowWidth < 1080 - scrollbarWidth ? (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="22"
						height="22"
						fill="none"
						viewBox="0 0 22 22"
					>
						<path
							fill="#0D90D4"
							d="M9.22 1.692a2.75 2.75 0 013.568 0l.854.728c.44.375.989.602 1.566.648l1.118.09A2.751 2.751 0 0118.85 5.68l.089 1.119c.046.577.273 1.125.648 1.565l.728.854a2.75 2.75 0 010 3.569l-.728.854c-.375.44-.602.989-.648 1.566l-.09 1.118a2.751 2.751 0 01-2.523 2.524l-1.118.089a2.753 2.753 0 00-1.566.648l-.854.728a2.751 2.751 0 01-3.569 0l-.854-.728A2.752 2.752 0 006.8 18.94l-1.119-.09a2.751 2.751 0 01-2.523-2.523l-.09-1.118a2.751 2.751 0 00-.648-1.566l-.728-.854a2.751 2.751 0 010-3.569l.728-.854c.375-.44.602-.988.648-1.565l.09-1.119A2.751 2.751 0 015.68 3.158l1.119-.09a2.75 2.75 0 001.565-.648l.854-.728z"
						></path>
						<path
							stroke="#fff"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.5"
							d="M7.104 11.687l2.063 2.292 5.729-5.958"
						></path>
					</svg>
				) : (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						fill="none"
						viewBox="0 0 32 32"
					>
						<path
							fill="#0D90D4"
							d="M13.41 2.461a4.001 4.001 0 015.19 0l1.243 1.059a4.002 4.002 0 002.277.943l1.627.13a4.002 4.002 0 013.67 3.67l.13 1.628c.068.839.398 1.636.944 2.277l1.058 1.242a4.001 4.001 0 010 5.19l-1.058 1.243a4.001 4.001 0 00-.943 2.277l-.13 1.627a4.002 4.002 0 01-3.67 3.67l-1.628.13a4.003 4.003 0 00-2.277.944l-1.242 1.058a4.002 4.002 0 01-5.191 0l-1.242-1.058a4.002 4.002 0 00-2.277-.944l-1.627-.13a4.001 4.001 0 01-3.671-3.67l-.13-1.627a4.001 4.001 0 00-.943-2.277L2.46 18.601a4.001 4.001 0 010-5.191l1.059-1.242c.546-.641.876-1.438.943-2.277l.13-1.627a4.002 4.002 0 013.67-3.671l1.628-.13a4.001 4.001 0 002.277-.943L13.41 2.46z"
						></path>
						<path
							stroke="#fff"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="1.5"
							d="M10.334 17l3 3.333 8.333-8.666"
						></path>
					</svg>
				)}
			</ContainerVerified>
		);
	} else {
		return <></>;
	}
}

export default Verified;
