import styled from 'styled-components';
import { DEVICE } from 'Constants';

const NavbarLogoContent = styled.div`
	padding-top: 0;
	padding-bottom: 0;

	@media (min-width: ${DEVICE.minTablet}) {
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

export default NavbarLogoContent;