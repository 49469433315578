import styled, { useTheme } from 'styled-components';

const ContainerSearchBarIcon = styled.div`
	position: absolute;
	top: 13px;
	left: 16px;
	width: 18px;
	height: 18px;
`;

function SearchBarIcon({}) {
	const theme = useTheme();

	return (
		<ContainerSearchBarIcon>
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
				<g stroke={theme.colorText} strokeMiterlimit="10" opacity="0.66">
					<path d="M7.773 2.25a5.523 5.523 0 100 11.045 5.523 5.523 0 000-11.045v0z" />
					<path strokeLinecap="round" d="M11.893 11.893l3.857 3.857" />
				</g>
			</svg>
		</ContainerSearchBarIcon>
	);
}

export default SearchBarIcon;
