import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { motion, useAnimation } from "framer-motion";
import {
	transitionMotionSpring,
	transitionCurveMotion,
	stylesNoSelect,
} from "../../../Constants";

const RateHelpful = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 44px;
	background: ${(props) =>
		props.active ? props.theme.backgroundCardButton : props.theme.backgroundTransparent};
	border-radius: 8px;
	cursor: pointer;

	${transitionCurveMotion}
	${stylesNoSelect}

	@media (hover: hover) {
		&:hover {
			background: ${(props) =>
				props.active ? props.theme.backgroundCardButton : props.theme.backgroundCardButtonOnewayHover};
		}
	}
`;

const RateHelpfulIcon = styled.div`
	margin: 2px 8px 0 0;
`;

const RateHelpfulText = styled.h4`
	margin: 0;
	padding: 2px 0 0 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	color: ${({ theme }) => theme.colorText};
	opacity: ${(props) => (props.active ? 0.75 : 0.66)};
	${transitionCurveMotion}
`;

function ReviewRateHelpful({ rating, setRating }) {
	const animateIcon = useAnimation();
	const theme = useTheme();

	const onRateChange = () => {
		if (rating != "helpful") {
			animateIcon.start({ scale: 1.3, rotate: 15 });
			// animateIcon.start({ scale: 1.3, y: -2 });

			setTimeout(() => {
				animateIcon.start({ scale: 1, rotate: 0 });
				// animateIcon.start({ scale: 1, y: 0 });
			}, 400);
		} else {
		}

		setRating("helpful");
	};

	return (
		<RateHelpful active={rating == "helpful"} onClick={onRateChange}>
			<RateHelpfulIcon>
				<motion.div animate={animateIcon}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						height="16"
						fill="none"
						viewBox="0 0 16 16"
					>
						<path
							stroke={theme.colorText}
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M4.667 14.667h-2a1.333 1.333 0 01-1.334-1.334V8.667a1.333 1.333 0 011.334-1.334h2M9.333 6V3.333a2 2 0 00-2-2l-2.666 6v7.334h7.52a1.333 1.333 0 001.333-1.134l.92-6A1.333 1.333 0 0013.107 6H9.333z"
							opacity="0.75"
						/>
					</svg>
				</motion.div>
			</RateHelpfulIcon>
			<RateHelpfulText active={rating == "helpful"}>
				도움이 됩니다
			</RateHelpfulText>
		</RateHelpful>
	);
}

export default ReviewRateHelpful;
