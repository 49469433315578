import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_ROOT, DEVICE } from "Constants";

import { useAuth } from "contexts/AuthContext";

import Switcher from "components/Switcher";
import IndividualReview from "components/IndividualReview";
import IndividualReviewPlaceholder from "components/IndividualReview/IndividualReviewPlaceholder";
import { ContainerReviewPreview } from 'layout/LayoutReview';

import { Spacer32, Spacer40, Spacer48 } from "components/Spacer";

function FrontPage({ score, isTrending, content }) {
  // const { currentUser, fingerprint } = useAuth();

  const [bestReviews, setBestReviews] = useState([]);
  const [newReviews, setNewReviews] = useState([]);

  const [dataReviews, setDataReviews] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    axios
      .get(`${API_ROOT()}/reviews/frontPage/best`)
      .then(function (response) {
        let properResponseData =
          response && response.data && response.data.reviews
            ? response.data.reviews
            : [];

        console.log("Fetched from best", properResponseData);
        setBestReviews([...properResponseData]);
        setDataReviews([...properResponseData]);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        console.log("done fetching from best");
        /* aka done */
      });

    axios
      .get(`${API_ROOT()}/reviews/frontPage/new`)
      .then(function (response) {
        let properResponseData =
          response && response.data && response.data.reviews
            ? response.data.reviews
            : [];

        setNewReviews([...properResponseData]);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        /* aka done */
      });
  }, []);

  const updateFeed = () => {
    setDataReviews([]);

    setTimeout(() => {
      if (activeIndex == 0) {
        setDataReviews([...bestReviews]);
      } else if (activeIndex == 1) {
        setDataReviews([...newReviews]);
      }
    }, 175);
  };

  useEffect(() => {
    updateFeed();
  }, [bestReviews, newReviews]);

  useEffect(() => {
    updateFeed();
  }, [activeIndex]);

  const onActiveIndexChange = (index) => {
    if (index == 0 || index == 1) {
      // MAKE AXIOS CALL, probably don't need to cache
      // axios.get()
      setActiveIndex(index);
    }
  };

  return (
    <ContainerReviewPreview>
      <Switcher
        options={["주간 베스트", "최근순"]}
        activeIndex={activeIndex}
        setActiveIndex={onActiveIndexChange}
        layoutId="frontPage-reviewOrder"
      />
      <Spacer40 />
      {dataReviews && dataReviews.length == 0 ? (
        <>
          <IndividualReviewPlaceholder />
          <IndividualReviewPlaceholder />
          <IndividualReviewPlaceholder />
          <IndividualReviewPlaceholder />
        </>
      ) : (
        <>
          {dataReviews.map((data, index) => (
            <IndividualReview
              key={`IndividualReview-${index}`}
              vcScore={data.score}
              vcReviewCount={data.reviewCount}
              review={data.review}
            />
          ))}
        </>
      )}
    </ContainerReviewPreview>
  );
}

export default FrontPage;
