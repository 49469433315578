import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { GlobalStyles, defaultTheme, founderTheme, vcTheme } from "theme/Theme";

import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import Navbar from "./components/Navbar";
// import Menu from './components/Menu';
import FrontPage from "./pages/FrontPage";
import VCPage from "./pages/VCPage";
import SharedReview from "./pages/SharedReview";

import FounderAuthPage from "./pages/FounderAuthPage";
import FounderLogin from "./pages/FounderLogin";
import FounderReviewPage from "./pages/FounderReviewPage";

import VCAuthPage from "./pages/VCAuthPage";
import VCLogin from "./pages/VCLogin";
import VCModeAuthPage from "./pages/VCModeAuthPage";

import Loader from "./components/Loader";

function App() {
	// const [shouldRenderMenu, setShouldRenderMenu] = useState(false);
	const [theme, setTheme] = useState(defaultTheme);

	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	const { currentUser } = useAuth();
	const { isFounder, isVC } = useUser();

	useEffect(() => {
		setIsInitialLoad(false);
	}, []);

	useEffect(() => {
		// console.log("APP.JS CURRENTUSER CHANGE", currentUser);
		if (currentUser) {
			if (isFounder) {
				setTheme(founderTheme);
			} else if (isVC) {
				setTheme(vcTheme);
				// setTheme(defaultTheme);
			}
		} else {
			setTheme(defaultTheme);
		}
	}, [currentUser, isFounder, isVC]);

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				{isLoading && (
					<Loader
						isInitialLoad={isInitialLoad}
						setIsLoadingParent={setIsLoading}
					/>
				)}
				<GlobalStyles />
				<Router>
					{/* <Menu shouldRender={shouldRenderMenu} /> */}
					<Route
						path={["/vc/:vc", "/"]}
						component={({ match }) => (
							<Navbar
								match={match}
								// shouldRenderMenu={shouldRenderMenu}
								// setShouldRenderMenu={setShouldRenderMenu}
							/>
						)}
					/>
					<Route exact path="/" component={FrontPage} />
					<Route
						exact
						path="/reviews/:reviewId"
						component={SharedReview}
					/>
					<Route exact path="/vc/:vc" component={VCPage} />
					<Route exact path="/founders" component={FounderAuthPage} />
					<Route
						exact
						path="/founders/login"
						component={FounderLogin}
					/>
					<Route
						exact
						path="/founders/write-review"
						component={FounderReviewPage}
					/>
					<Route exact path="/vc-mode/" component={VCAuthPage} />
					<Route exact path="/vc-mode/login" component={VCLogin} />
					<Route
						exact
						path="/vc-mode/auth/:token"
						component={VCModeAuthPage}
					/>
					{/* <Route exact path="/founders" component={FounderMode} /> */}
					{/* <Route exact path="/vc" component={VCMode} /> */}
				</Router>
			</div>
		</ThemeProvider>
	);
}

export default App;
