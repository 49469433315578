import styled from 'styled-components';
import { stylesNoSelect } from 'Constants';

const Private = styled.div`
	display: inline-flex;
	align-items: flex-start;
	margin: 16px 0 0 0;
	cursor: pointer;
	${stylesNoSelect}
`;

export default Private;
