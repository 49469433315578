import styled from 'styled-components';

const CardReview = styled.div`
	max-width: calc(100vw - 32px);
	width: 536px;
	height: auto;
	margin: 0 0 0 0;
	padding: 16px 16px 12px 16px;
	background: ${({ theme }) => theme.backgroundCard};
	border-radius: 8px;
	filter: drop-shadow(${({ theme }) => theme.shadowCard});
`;

export default CardReview;
