import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const Details = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export default Details;