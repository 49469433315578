import styled from "styled-components";
import { DEVICE, transitionMotionSpring, COLOR, stylesNoSelect } from "Constants";
import { motion, AnimateSharedLayout } from "framer-motion";
import { H6 } from "components/Typography";

const ResponsiveContainer = styled.div`
	display: flex;
	/*justify-content: flex-end;*/
	flex-direction: column;
	align-items: center;

	@media (min-width: ${DEVICE.minDesktop}) {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		transform: translateX(-81px);
	}
`;

const ResponsiveSpacer = styled.div`
	width: 0;

	@media (min-width: ${DEVICE.minDesktop}) {
		width: 100%;
		max-width: calc(266px + 32px);
	}

	background: rgba(0, 0, 255, 0.33);
`;

const ContainerSwitcher = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 3px;

	width: 100%;
	max-width: 536px;
	margin: 0 auto;
	padding: 3px;
	background: ${({ theme }) => theme.backgroundCardButton};
	border-radius: 8px;
`;

const SwitcherItem = styled(motion.div)`
	position: relative;
	flex: 1;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 36px;
	border-radius: 6px;
	text-align: center;

	color: ${({ isActive, theme }) =>
		isActive ? theme.colorTextActive : theme.colorTextNormal};
	cursor: pointer;
`;

const SwitcherItemText = styled(H6)`
	position: relative;
	top: 0;
	z-index: 1;

	font-size: 14px;
	line-height: 14px;
	font-weight: ${({ isActive }) => (isActive ? 600 : 400)};

	color: ${({ isActive, theme }) =>
		isActive ? theme.colorTextActive : theme.colorTextNormal};
	opacity: 1;
	${stylesNoSelect}
`;

const SwitcherItemBackground = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	width: 100%;
	height: 100%;

	border-radius: 6px;
	background: ${({ theme }) => theme.backgroundCard};
	box-shadow: ${({ theme }) => theme.shadowSwitcher};
`;

function Switcher({ options, activeIndex, setActiveIndex, layoutId }) {
	const onSwitch = (index) => {
		if (index != activeIndex) {
			setActiveIndex(index);
		}
	};

	return (
		<ResponsiveContainer>
			<ResponsiveSpacer />
			<ContainerSwitcher>
				<AnimateSharedLayout>
					{options.map((option, index) => (
						<SwitcherItem onClick={() => onSwitch(index)}>
							{activeIndex == index && (
								<SwitcherItemBackground
									layoutId={`${layoutId}-switcher`}
									transition={transitionMotionSpring}
								/>
							)}
							<SwitcherItemText isActive={activeIndex == index}>
								{option}
							</SwitcherItemText>
						</SwitcherItem>
					))}
				</AnimateSharedLayout>
			</ContainerSwitcher>
		</ResponsiveContainer>
	);
}

export default Switcher;
