import { COLOR, DEVICE } from 'Constants';
import ContainerReviewHeaderScore from './ContainerReviewHeaderScore';
import ReviewHeaderScoreScore from './ReviewHeaderScoreScore';
import ReviewHeaderScoreBase from './ReviewHeaderScoreBase';

function ReviewHeaderScore({ vcScore }) {
	return (
		<ContainerReviewHeaderScore>
			<ReviewHeaderScoreScore
				color={vcScore >= 4 ? COLOR.POSITIVE : vcScore >= 2.5 ? COLOR.NEUTRAL : COLOR.NEGATIVE}
			>
				{vcScore ? vcScore.toFixed(1) : '0.0'}
			</ReviewHeaderScoreScore>
			<ReviewHeaderScoreBase> / 5</ReviewHeaderScoreBase>
		</ContainerReviewHeaderScore>
	);
}

export default ReviewHeaderScore;