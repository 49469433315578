import { useState, useEffect, useRef, createRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { H5, P } from "components/Typography";

import ResponseConnector from "./ResponseConnector";

const ContainerResponse = styled(motion.div)``;

const Response = styled(motion.div)`
	position: relative;
	display: flex;
	padding: 0 0 0 16px;
`;

const ResponseContent = styled(motion.div)`
	margin: 26px 0 0 0;
`;

const ResponseContentName = styled(H5)`
	font-weight: 600;
`;
const ResponseContentText = styled(P)``;

function ReviewResponse({ replies, responseHeights, setResponseHeights }) {
	const refReplies = useRef(replies.map(() => createRef()));

	useEffect(() => {
		const calcHeights = refReplies.current.map(
			(ref) => ref.current?.getBoundingClientRect()?.height ?? 0
		);

		let summedHeights = [];
		for (let i = 0; i < calcHeights.length; i++) {
			if (i == 0) summedHeights[i] = 56;
			else {
				summedHeights[i] =
					summedHeights[i - 1] +
					refReplies?.current[i]?.current?.getBoundingClientRect()
						.height;
			}
		}

		setResponseHeights([...summedHeights]);
	}, [replies]);

	return (
		<ContainerResponse>
			{replies.map((reply, index) => (
				<Response ref={refReplies.current[index]}>
					<ResponseConnector index={index} height={responseHeights[index]} />
					<ResponseContent>
						<ResponseContentName>{reply.name}</ResponseContentName>
						<ResponseContentText>
							(누구머니 업데이트 전까지는 창업자들한테는
							비공개입니다)
							<br />
							{reply.content}
						</ResponseContentText>
						{/* <ResponseContentText>{reply.content}</ResponseContentText> */}
					</ResponseContent>
				</Response>
			))}
		</ContainerResponse>
	);
}

export default ReviewResponse;
