import styled from 'styled-components';
import { DEVICE } from 'Constants';

const ContainerAuth = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: calc(100% + 32px);
  min-height: 100vh;
  margin: 0 -16px;
  padding: 0;

  @media (min-width: ${DEVICE.minTabletLarge}) {
    flex-direction: row;
    align-items: stretch;
  }
`;

const AuthRegister = styled.div`
  flex: 1;
  padding: 96px 16px 96px 16px;

  @media (min-width: ${DEVICE.minTablet}) {
    padding: 128px 16px 128px 16px;
  }

  @media (min-width: ${DEVICE.minDesktop}) {
    /*padding: 142px 40px 142px 40px;*/
    padding: calc((100vh - 181px) / 2) 40px 142px 40px;
  }

  @media (min-width: ${DEVICE.minDesktopLargeNav}) {
    padding: calc((100vh - 181px) / 2) 108px 142px calc((100vw - (1352px + 40px + 40px)) / 2 + 40px);
  }
`;

const AuthLogin = styled.div`
  flex: 1;
  background: #111111;
  color: #ffffff;

  /*padding: 186px 108px 0 108px;*/
  padding: 186px 16px 96px 16px;

  @media (min-width: ${DEVICE.minTablet}) {
    padding: 128px 16px 128px 16px;
  }

  @media (min-width: ${DEVICE.minDesktop}) {
    padding: 142px 40px 142px 40px;
  }

  @media (min-width: ${DEVICE.minDesktopLargeNav}) {
    padding: calc((100vh - 181px) / 2) calc((100vw - (1352px + 40px + 40px)) / 2 + 40px) 142px 108px;
  }
`;

const AuthLoginContent = styled.div`
  @media (min-width: ${DEVICE.minTablet}) {
    position: -webkit-sticky;
    position: sticky;
    top: calc((100vh - 181px) / 2);
  }
`;

export { ContainerAuth, AuthRegister, AuthLogin, AuthLoginContent };
