import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_ROOT, DEVICE } from "Constants";

import IndividualReview from "components/IndividualReview";
import IndividualReviewPlaceholder from "components/IndividualReview/IndividualReviewPlaceholder";
import { ContainerReviewPreview } from "layout/LayoutReview";

import { Spacer32, Spacer40, Spacer48 } from "components/Spacer";

function SharedReview({ match, location }) {
	const [reviewData, setReviewData] = useState();

	useEffect(() => {
		axios
			.get(`${API_ROOT()}/reviews/getReview?reviewId=${match.params.reviewId}`)
			.then(function (response) {
				// console.log('Got response', response.data);
				setReviewData(response.data);
			})
			.catch(function (error) {});
	}, []);

	return (
		<ContainerReviewPreview>
			<Spacer40 />
			{!reviewData && !reviewData?.review ? (
				<IndividualReviewPlaceholder />
			) : (
				<IndividualReview
					vcScore={reviewData.score}
					vcReviewCount={reviewData.reviewCount}
					review={reviewData.review}
				/>
			)}
		</ContainerReviewPreview>
	);
}

export default SharedReview;