import { useState, useEffect } from "react";
import {
  motion,
  useAnimation,
  AnimatePresence,
  useViewportScroll,
} from "framer-motion";
import styled, { useTheme } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useLocationChange } from "utils/Helpers";
import { useWindowWidth } from "@react-hook/window-size";

import { useAuth } from "contexts/AuthContext";
import { useUser } from "contexts/UserContext";

import ContainerNavbar from "./ContainerNavbar";
import NavbarFade from "./NavbarFade";
import NavbarContent from "./NavbarContent";
import NavbarLogo from "./NavbarLogo";
import NavbarLogoFlex from "./NavbarLogoFlex";
import NavbarLogoContent from "./NavbarLogoContent";

import Search from "../Search";
import NavbarCTA from "./NavbarCTA";
import NavbarMenu from "./NavbarMenu";
import NavbarUser from "./NavbarUser";

import Menu from "components/Menu";

function Navbar({ match }) {
  const { currentUser } = useAuth();
  const { isFounder, isVC } = useUser();

  const [isRenderingMenu, setIsRenderingMenu] = useState(false);
  const [isDarkModeNavbar, setIsDarkModeNavbar] = useState(false);
  const [isDarkModeMenu, setIsDarkModeMenu] = useState(false);
  const [isNavbarMinimal, setIsNavbarMinimal] = useState(false);

  const { scrollY } = useViewportScroll();
  const windowWidth = useWindowWidth();
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [innerWidth, setInnerWidth] = useState(windowWidth);

  useEffect(() => {
    setScrollbarWidth(window.innerWidth - document.body.offsetWidth);
    setInnerWidth(windowWidth + window.innerWidth - document.body.offsetWidth);

    onNavbarRerender();
  }, [windowWidth]);

  const location = useLocation();
  const theme = useTheme();

  useLocationChange((location, prevLocation) => {
    if (location && prevLocation) {
      if (location.pathname != prevLocation.pathname) {
        window.scroll(0, 0);
      }
    }
  });

  useEffect(() => {
    onNavbarRerender();

    if (location.pathname == "/founders/write-review") {
      setIsNavbarMinimal(true);
    } else {
      setIsNavbarMinimal(false);
    }
  }, [location.pathname]);

  const onNavbarRerender = () => {
    if (location?.pathname == "/founders" || location?.pathname == "/vc-mode") {
      setIsDarkModeMenu(true);

      if (isFounder || isVC) {
        setIsDarkModeMenu(false);
        setIsDarkModeNavbar(false);
      } else if (innerWidth < 768) {
        setIsDarkModeNavbar(true);
      }
    } else {
      setIsDarkModeMenu(false);

      if (innerWidth < 768) {
        setIsDarkModeNavbar(false);
      }
    }
  };

  //   const onNavbarRerender = () => {
  //     if (
  //       location.pathname &&
  //       (location.pathname == "/founders" || location.pathname == "/vc-mode")
  //     ) {
  //       setIsDarkModeMenu(true);
  //
  //       if (window.innerWidth < 768) {
  //         setIsDarkModeNavbar(true);
  //       }
  //     } else {
  //       setIsDarkModeMenu(false);
  //
  //       if (window.innerWidth < 768) {
  //         setIsDarkModeNavbar(false);
  //       }
  //     }
  //   };

  const onNavbarToggle = () => {
    // MENU is now closing
    if (isRenderingMenu) {
    }

    // MENU is now opening
    else if (!isRenderingMenu) {
    }

    setIsRenderingMenu(!isRenderingMenu);
  };

  return (
    <ContainerNavbar>
      {location.pathname != null &&
        (location.pathname == "/" ||
          location.pathname.includes("/vc/") ||
          (location.pathname.includes("/founders/write-review") &&
            innerWidth < 1320)) && (
          <NavbarFade
            style={{
              opacity: scrollY,
            }}
          />
        )}
      <AnimatePresence>
        {isRenderingMenu && (
          <Menu
            isRenderingMenu={isRenderingMenu}
            onNavbarToggle={onNavbarToggle}
          />
        )}
      </AnimatePresence>
      <NavbarContent isNavbarMinimal={isNavbarMinimal}>
        <NavbarLogo>
          <NavbarLogoFlex>
            <Link to="/" onClick={() => setIsRenderingMenu(false)}>
              <NavbarLogoContent>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="103"
                  height="16"
                  fill="none"
                  viewBox="0 0 103 16"
                >
                  <g
                    className="svg--animated"
                    fill={
                      isRenderingMenu ||
                      ((location?.pathname == "/founders" ||
                        location?.pathname == "/vc-mode") &&
                        innerWidth < 767)
                        ? theme.colorBaseOpposite
                        : theme.colorBase
                    }
                    opacity={
                      isRenderingMenu ||
                      ((location?.pathname == "/founders" ||
                        location?.pathname == "/vc-mode") &&
                        innerWidth < 767)
                        ? 1
                        : 0.75
                    }
                  >
                    <path d="M19.097 12.355v3.387H0V.226h4.29v12.129h14.807zM42.371 0v16h-4.193V3.387H23.597V0H42.37zM72.143.065v15.71H53.336V.064h18.807zM57.53 3.484v8.903h10.387V3.484H57.53zM102.043 12.355v3.387H82.947V.226h4.29v12.129h14.806z" />
                  </g>
                </svg>
              </NavbarLogoContent>
            </Link>
            <NavbarUser />
          </NavbarLogoFlex>
          {false &&
            "IS_LIVE: " + process.env.IS_LIVE + " - " + process.env.NODE_ENV}
        </NavbarLogo>
        {!isNavbarMinimal && (
          <>
            <Search match={match} />
            {currentUser && isVC ? (
              <Link to="/vc-mode" onClick={() => setIsRenderingMenu(false)}>
                <NavbarCTA
                  isDarkModeNavbar={isDarkModeNavbar}
                  isDarkModeMenu={isDarkModeMenu}
                  text="리뷰 답글 달기"
                />
              </Link>
            ) : (
              <Link to="/founders" onClick={() => setIsRenderingMenu(false)}>
                <NavbarCTA
                  isDarkModeNavbar={isDarkModeNavbar}
                  isDarkModeMenu={isDarkModeMenu}
                  text="투자사 후기 쓰기"
                />
              </Link>
            )}
          </>
        )}
        <NavbarMenu
          onNavbarToggle={() => setIsRenderingMenu(!isRenderingMenu)}
          shouldRenderMenu={isRenderingMenu}
          isDarkModeNavbar={isDarkModeNavbar}
          isDarkModeMenu={isDarkModeMenu}
        />
      </NavbarContent>
    </ContainerNavbar>
  );
}

export default Navbar;
