import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

import { useAuth } from "contexts/AuthContext";

import { API_ROOT, DEVICE } from "Constants";
import {
  AuthRegister,
  AuthLogin,
  AuthLoginContent,
} from "layout/LayoutFounderAuth";
import { ContainerAuth, AuthContent } from "layout/LayoutVCAuth";

import { H1, H3, H6, P } from "components/Typography";
import Anchor from "components/Typography/Anchor";
import AnchorLink from "components/Typography/AnchorLink";

import Form from "components/Form";
import InputText from "components/Input/InputText";
import InputSelectVertical from "components/Input/InputSelectVertical";
import InputSelectHorizontal from "components/Input/InputSelectHorizontal";
import InputPassword from "components/Input/InputPassword";
import ButtonForm from "components/Button/ButtonForm";
import { Spacer4, Spacer8 } from "components/Spacer";

import InputSuccessText from "components/Input/InputSuccessText";
import InputErrorText from "components/Input/InputErrorText";

const LoginMessage = styled.div`
  margin: -16px 0 0 0;
`;

function FounderLogin({ match }) {
  const { currentUser, login } = useAuth();
  const { isFounder, isVC } = useAuth();

  const history = useHistory();
  const location = useLocation();
  const cookies = new Cookies();

  const [requiresEmail, setRequiresEmail] = useState(true);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(currentUser, login);

    if (currentUser) {
      if (isFounder) {
        history.push(`/founders/write-review`);
      } else if (isVC) {
        history.push(`/vc-mode/login`);
      }
    }

    if (location.search) {
      if (cookies.get("nugu-email")) {
        checkValidAuth();
      }
    } else {
      sendBackToAuthPage();
    }
  }, []);

  const sendBackToAuthPage = () => {
    history.push(`/founders`);
  };

  function handleLoginSuccess(res) {
    let emailValue = email ?? cookies.get("nugu-email");

    setIsLoading(false);
    cookies.set("nugu-email", emailValue, {});

    if (res) {
      setRequiresEmail(false);

      history.push(`/founders/write-review`);
    }
  }

  function handleLoginError(err) {
    setIsLoading(false);
    let errorData = err;
    let code = errorData ? errorData.code : "unknown";

    let errorMessage = "";

    if (code == "auth/invalid-email") {
      setError(`입력하신 이메일이 맞는지 다시 확인해주세요.`);
    } else if (code == "auth/invalid-action-code") {
      setError(`링크의 유효기간이 지났거나, 올바르지 않습니다.`);
    } else {
      setError(`에러가 발생했습니다.`);
      console.log("Error", errorData);
    }
  }

  const checkValidAuth = () => {
    let emailValue = email ?? cookies.get("nugu-email");

    if (requiresEmail) {
      setIsLoading(true);
      setError(null);

      login(
        window.location.href.replace(
          "http://localhost:3000",
          "https://nugu.money"
        ),
        emailValue,
        'founder',
        handleLoginSuccess,
        handleLoginError
      );
    }
  };

  const handleUpdate = (name, value) => {
    if (name == "email") setEmail(value);
  };

  return (
    <ContainerAuth>
      <AuthContent>
        <H1>
          환영합니다.
          <br />
          보안을 위해 이메일을 다시 확인해주세요.
        </H1>
        <P>
          초대권을 받은 이메일로만 로그인 가능합니다.{" "}
          <Link to="/founders">초대권을 못 받으셨나요?</Link>
        </P>
        <Spacer8 />
        {requiresEmail && (
          <Form handleSubmit={checkValidAuth}>
            <InputPassword
              name="email"
              label={false}
              placeholder="회사 이메일"
              isTransparent={false}
              isPasswordVisible={true}
              validate={(value) => {
                return value && value.length > 3 && value.includes("@");
              }}
              updateOnChange={handleUpdate}
              error={error}
              required
            />
            <React.Fragment />
            {!isLoading && error && (
              <>
                <Spacer8 />
                <H6 opacity={1} color="rgba(0, 0, 0, 0.66)">
                  혹시 에러가 계속 발생하면 다시{" "}
                  <AnchorLink to="/founders" color="rgba(0, 0, 0, 1)">
                    처음부터 로그인을
                  </AnchorLink>{" "}
                  시도해주시거나{" "}
                  <Anchor
                    href="mailto:founder@nugu.money"
                    color="rgba(0, 0, 0, 1)"
                  >
                    founder@nugu.money
                  </Anchor>
                  로 피드백 부탁드립니다.
                </H6>
              </>
            )}
            {isLoading && !error && <H6>연결 중입니다..</H6>}
          </Form>
        )}
      </AuthContent>
    </ContainerAuth>
  );
}

export default FounderLogin;
