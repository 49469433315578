import styled from "styled-components";
import { DEVICE, transitionFade } from "Constants";

const ContainerNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  /*height: 78px;*/
  height: 224px;

  /*background: ${({theme}) => `linear-gradient(${theme.gradientNavbarCutoff})`};*/

  @media (min-width: ${DEVICE.minTablet}) {
    height: 112px;
    /*background: ${({theme}) => `linear-gradient(${theme.gradientNavbarCutoff})`};*/
  }

  @media (min-width: ${DEVICE.minDesktop}) {
    height: 112px; /* Should be 78px but 112px works well */
    /*background: linear-gradient(180deg, #eeeeee 50%, rgba(238, 238, 238, 0) 100%);*/
  }

  pointer-events: none; // Just so the fade doesn't get in the way.

  * {
    pointer-events: auto;
  }

  ${transitionFade}
`;

export default ContainerNavbar;
