// import { useState } from 'react';
import styled from 'styled-components';
// import {Colors} from '/constants';

const ContainerReviewTrending = styled.div`
	display: flex;
	align-items: center;
`;

const ReviewTrendingText = styled.h5`
	flex: 1;
	margin: 0 6px 0 0;
	font-size: 13px;
	font-weight: 400;
	line-height: 13px;
	color: ${(props) => (props.color ? props.color : '#777777')};
`;

const ReviewTrendingIcon = styled.div`
	flex: 0 15px;
	width: 15px;
	height: 15px;
	margin: -2px 0 0 0;
`;

function ReviewTrending() {
	return (
		<ContainerReviewTrending>
			<ReviewTrendingText>트렌딩</ReviewTrendingText>
			<ReviewTrendingIcon>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="15"
					height="15"
					fill="none"
					viewBox="0 0 15 15"
				>
					<path
						stroke="#777777"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M14.375 3.75L8.437 9.688 5.313 6.562.625 11.25"
					/>
					<path
						stroke="#777777"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M10.625 3.75h3.75V7.5"
					/>
				</svg>
			</ReviewTrendingIcon>
		</ContainerReviewTrending>
	);
}

export default ReviewTrending;
