import styled from "styled-components";
import { transitionTween } from "Constants";

const PrivateCheckbox = styled.div`
	position: relative;
	width: 16px;
	height: 16px;
	margin: 0;
	padding: 0;
	border: ${(props) =>
		props.isPrivate
			? `1px solid ${props.theme.colorCheckboxPrivate}`
			: `1px solid ${props.theme.colorCheckbox}`};
	border-radius: 3px;
	${transitionTween}

	&:after {
		content: "";
		position: absolute;
		top: 2px;
		left: 2px;
		width: 10px;
		height: 10px;
		background: ${({ theme }) => theme.colorCheckboxPrivate};
		border-radius: 2px;
		opacity: ${(props) => (props.isPrivate ? 1 : 0)};
		${transitionTween}
	}
`;

export default PrivateCheckbox;
