import styled from 'styled-components';

const ContainerSuggestions = styled.div`
	position: absolute;
	top: 96px;
	z-index: 999999;

	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;

	padding: 0 calc(22px - 8px);
`;

export default ContainerSuggestions;