import styled from 'styled-components';
import { transitionCurveMotion, stylesNoSelect } from 'Constants';

const ReviewContentText = styled.div`
	position: relative;
	max-height: ${(props) =>
		props.hasExpanded
			? props.ghostHeight > 0
				? props.ghostHeight + 20 + 43 + 4 + 'px'
				: '100%'
			: props.ghostHeight > 0 && props.ghostHeight < 270
			? props.ghostHeight + 'px'
			: '270px'};
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
	${transitionCurveMotion}
`;

export default ReviewContentText;