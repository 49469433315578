import styled from "styled-components";

const ContainerConnector = styled.div`
	position: relative;
	z-index: -1;
	flex: 0 16px;
	width: 16px;
	height: 40px;
`;

const Connector = styled.div`
	position: absolute;
	top: ${({ height, replyCount }) => (replyCount > 0 ? -height + 40 - 64 : -24)}px;
	width: 16px;
	height: ${({ height, replyCount }) => replyCount ? height + 64 : 64}px;

	border-top: none;
	border-right: none;
	/*border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	border-left: 1px solid rgba(0, 0, 0, 0.15);*/
	border-bottom: ${({ theme }) => `1px solid ${theme.colorReplyConnector}`};
	border-left: ${({ theme }) => `1px solid ${theme.colorReplyConnector}`};
	border-radius: 0 0 0 16px;
`;

function ReplyConnector({ responseHeights }) {
	return (
		<ContainerConnector>
			<Connector
				height={responseHeights[responseHeights.length - 1]}
				replyCount={responseHeights.length}
			/>
		</ContainerConnector>
	);
}

export default ReplyConnector;
