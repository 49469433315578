import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import ScrollLock from "react-scrolllock";

import {
  ROUTES,
  transitionTween,
  transitionMotionTween,
  transitionMotionTweenSlow,
} from "Constants";
import { H1, P } from "components/Typography";
import Anchor from "components/Typography/Anchor";

import ContainerMenu from "./ContainerMenu";
import MenuContent from "./MenuContent";
import MenuContentItem from "./MenuContentItem";
import MenuContentItemText from "./MenuContentItemText";
import MenuContentItemActive from "./MenuContentItemActive";
import MenuDivider from "./MenuDivider";
import MenuDividerLine from "./MenuDividerLine";
import MenuDescription from "./MenuDescription";

function Menu({ isRenderingMenu, onNavbarToggle }) {
  // const [isRendering, setIsRendering] = useState(false);
  const theme = useTheme();
  const animateOverlay = useAnimation();
  const location = useLocation();

  const animateItem1 = useAnimation();
  const animateItem2 = useAnimation();
  const animateItem3 = useAnimation();
  const animateDivider = useAnimation();
  const animateDescriptionTitle = useAnimation();
  const animateDescriptionBody = useAnimation();

  useEffect(() => {
    if (isRenderingMenu) {
      // start framer motion to render
      // animateOverlay.start({
      //   // maxHeight: '100%',
      //   // display: 'block',
      //   opacity: 0.9,
      //   transition: transitionMotionTween,
      // });

      animateItem1.start({
        opacity: 1,
        transition: { ...transitionMotionTweenSlow, delay: 0 },
      });

      animateItem2.start({
        opacity: 1,
        transition: { ...transitionMotionTweenSlow, delay: 0.15 },
      });

      animateItem3.start({
        opacity: 1,
        transition: { ...transitionMotionTweenSlow, delay: 0.3 },
      });

      animateDivider.start({
        opacity: 1,
        scaleX: 1,
        transition: { ...transitionMotionTweenSlow, delay: 0.45 },
      });

      animateDescriptionTitle.start({
        opacity: 1,
        transition: { ...transitionMotionTweenSlow, delay: 0.65 },
      });

      animateDescriptionBody.start({
        opacity: 1,
        transition: { ...transitionMotionTweenSlow, delay: 0.75 },
      });

      // setIsRendering(true);
    }
    //     else if (false && !isRenderingMenu && isRendering) {
    //       // use framer-motion to stop rendering
    //       animateOverlay.start({
    //         // maxHeight: 0,
    //         opacity: 0,
    //         transition: transitionMotionTween,
    //       });
    //
    //       animateItem1.start({ opacity: 0, transition: transitionMotionTween });
    //       animateItem2.start({ opacity: 0, transition: transitionMotionTween });
    //       animateItem3.start({ opacity: 0, transition: transitionMotionTween });
    //       animateDivider.start({ opacity: 0, scaleX: 0.66, transition: transitionMotionTween });
    //       animateDescriptionTitle.start({ opacity: 0, transition: transitionMotionTween });
    //       animateDescriptionBody.start({ opacity: 0, transition: transitionMotionTween });
    //
    //       // setTimeout(() => {
    //       //   animateOverlay.start({ display: 'none', transition: { type: 'tween', duration: 0 } });
    //       // }, 150); // 50ms extra to be safe
    //
    //       // setIsRendering(false);
    //     }
  }, []);

  return (
    <motion.div
      // animate={animateOverlay}
      initial={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999999,
        // display: 'none',
        width: "100vw",
        height: "100vh",
        // maxHeight: 0,
        // window.innerWidth >= 568
        //   ? `${(112 / window.innerHeight) * 100}%`
        //   : `${(224 / window.innerHeight) * 100}%`,
        background: theme.backgroundMenu,
        opacity: 0,
        backdropFilter: "blur(6px)",
        // transitionOrigin: '100% 100%',
      }}
      animate={{ opacity: 1, transition: transitionMotionTween }}
      exit={{ opacity: 0, transition: transitionMotionTween }}
    >
      <ScrollLock />
      <ContainerMenu>
        <MenuContent>
          <Link to="/" onClick={onNavbarToggle}>
            <motion.div animate={animateItem1} initial={{ opacity: 0 }}>
              <MenuContentItem
                isActive={
                  location.pathname == ROUTES.HOME ||
                  (location.pathname && location.pathname.includes("/vc/"))
                }
              >
                <MenuContentItemText>홈</MenuContentItemText>
                {(location.pathname == ROUTES.HOME ||
                  (location.pathname &&
                    location.pathname.includes("/vc/"))) && (
                  <MenuContentItemActive />
                )}
              </MenuContentItem>
            </motion.div>
          </Link>
          <Link to="/founders" onClick={onNavbarToggle}>
            <motion.div animate={animateItem2} initial={{ opacity: 0 }}>
              <MenuContentItem
                isActive={
                  location.pathname &&
                  location.pathname.includes(ROUTES.FOUNDERMODE)
                }
              >
                <MenuContentItemText>창업자 모드</MenuContentItemText>
                {location.pathname &&
                  location.pathname.includes(ROUTES.FOUNDERMODE) && (
                    <MenuContentItemActive />
                  )}
              </MenuContentItem>
            </motion.div>
          </Link>
          <Link to="/vc-mode" onClick={onNavbarToggle}>
            <motion.div animate={animateItem3} initial={{ opacity: 0 }}>
              <MenuContentItem isActive={location.pathname == ROUTES.VCMODE}>
                <MenuContentItemText>VC 모드</MenuContentItemText>
                {location.pathname == ROUTES.VCMODE && (
                  <MenuContentItemActive />
                )}
              </MenuContentItem>
            </motion.div>
          </Link>
        </MenuContent>
        <MenuDivider>
          {/* <motion.div animate={animateDivider} initial={{ opacity: 0, scaleX: 0, transformOrigin: '0 0' }}> */}
          <motion.div
            animate={animateDivider}
            initial={{ opacity: 0, scaleX: 0.66, transformOrigin: "0 0" }}
          >
            <MenuDividerLine />
          </motion.div>
        </MenuDivider>
        <MenuDescription>
          <motion.div
            animate={animateDescriptionTitle}
            initial={{ opacity: 0 }}
          >
            <H1 opacity={0.75}>
              진짜 스타트업 창업자들이 남기는
              <br />
              솔직한 VC 리뷰
            </H1>
          </motion.div>
          <motion.div animate={animateDescriptionBody} initial={{ opacity: 0 }}>
            <P>
              누구머니는 스타트업계 내 창업자와 투자자간의 정보의 비대칭을
              없애기 위해, 창업자들이 직접 경험한 투자사들에 대한 진솔한 리뷰를
              남길 수 있는 공간을 제공합니다.
            </P>
            <P>
              저희는 주변에 창업을 하는 선후배들이 이 정보의 비대칭으로 인해
              여러가지 어려움을 느끼는 것을 보았고, 이를 해결하기 위한 노력의
              일환으로 이 사이트를 만들게 되었습니다. 누구머니에 올라오는
              투자사들에 대한 솔직한 리뷰를 통해 스타트업계가 한층 더 건강해질
              수 있다고 믿습니다.
            </P>
            <P>
              서비스와 리뷰의 퀄리티를 보장하기 위해 철저한 인증 절차를 통과한
              창업자만이 리뷰를 남길 수 있으며, 모든 리뷰는 익명으로 게시됩니다.
              더 나은 스타트업 생태계 조성을 위해 직접 경험한 투자사들에 한해
              진실되고 유익한 리뷰를 남겨 주시기 바랍니다.
            </P>
            <P>감사합니다.</P>
            <Anchor
              href="mailto:founder@nugu.money"
              color={theme.colorBaseOpposite}
            >
              ~ founder@nugu.money
            </Anchor>
          </motion.div>
        </MenuDescription>
      </ContainerMenu>
    </motion.div>
  );
}

export default Menu;
