import { useEffect, useState, useRef, createRef } from "react";
import { useTheme } from "styled-components";
import { motion, useAnimation } from "framer-motion";
// import VCNames from "utils/VCNames";
import { useVC } from 'contexts/VCContext';
import Consonants from "utils/Consonants";
import { transitionMotionSpring } from "Constants";

import ContainerSuggestions from "./ContainerSuggestions";
import SuggestionsItem from "./SuggestionsItem";

function InputTextSelectSuggestions({
	value,
	updateValue,
	activeIndex,
	updateActiveIndex,
	suggestionsCount,
	setSuggestionsCount,
	isFocused,
	recentAction,
	setRecentAction,
	setSuggestionsListParent,
}) {
	const { VCNames } = useVC();
	const [shouldRender, setShouldRender] = useState(isFocused);
	const [suggestionList, setSuggestionList] = useState([]);
	// const [activeIndex, setActiveIndex] = useState(-1);

	const theme = useTheme();

	// const spacer =

	const animateSuggestions = useAnimation();
	const animateActive = useAnimation();
	const refsItems = useRef(
		[null, null, null, null, null].map(() => createRef())
	);

	// useEffect(() => {
	// 	console.log('Input Suggestion', suggestionList, ' : ', value);
	// }, [suggestionList]);

	useEffect(() => {
		let shouldRenderLocal =
			value != "" && value != null && isFocused;
		setShouldRender(shouldRenderLocal);

		if (shouldRenderLocal) {
			let suggestionsListLocal;
			if (
				value.slice(-1) &&
				value.length > 1
			) {
				let valueLower = value.toLowerCase();
				// console.log("valueLower", valueLower);
				suggestionsListLocal = VCNames.filter((firm) =>
					firm.toLowerCase().includes(valueLower.slice(0, -1))
				).sort((a, b) =>
					a.indexOf(valueLower) < b.indexOf(valueLower)
						? -1
						: 1
				);
			} else {
				let valueLower = value.toLowerCase();
				suggestionsListLocal = VCNames.filter((firm) =>
					firm.toLowerCase().includes(valueLower)
				).sort((a, b) =>
					a.indexOf(valueLower) < b.indexOf(valueLower)
						? -1
						: 1
				);
			}

			setSuggestionList(suggestionsListLocal);
			setSuggestionsCount(
				suggestionsListLocal.length > 5
					? 5
					: suggestionsListLocal.length
			);
			setSuggestionsListParent(suggestionsListLocal);
			animateSuggestions.start({
				opacity: 1,
				transition: transitionMotionSpring,
			});
		} else {
			animateSuggestions.start({
				opacity: 0,
				transition: { type: "tween", duration: 0.15 },
			});
			setTimeout(() => {
				setSuggestionList([]);
				setSuggestionsCount(0);
				setSuggestionsListParent([]);
			}, 150);
		}
	}, [value, isFocused]);

	useEffect(() => {
		if (activeIndex >= 0) {
			animateActive.start({
				y: activeIndex * 42,
				width: refsItems.current[
					activeIndex
				].current.getBoundingClientRect().width,
				opacity: 1,
				transition: transitionMotionSpring,
			});
			updateValue(suggestionList[activeIndex]);
			animateSuggestions.start({
				opacity: 1,
				transition: transitionMotionSpring,
			});
		} else {
			animateActive.start({
				y: -44,
				opacity: 0,
				transition: transitionMotionSpring,
			});
			updateValue(value); // freezedValue
			animateSuggestions.start({
				opacity: 0,
				transition: { type: "tween", duration: 0.15 },
			});
		}
	}, [activeIndex]);

	const onMouseMove = () => {
		setRecentAction('MOUSE');
	};

	return (
		<motion.div animate={animateSuggestions}>
			<ContainerSuggestions onMouseMove={onMouseMove}>
				<motion.div
					animate={animateActive}
					initial={{
						position: "absolute",
						top: 1,
						zIndex: -1,
						width: 100,
						height: 34,
						background: theme.backgroundCard,
						borderRadius: 8,
						pointerEvents: "none",
						opacity: 0,
					}}
				/>
				{suggestionList.slice(0, 5).map((firm, index) => (
					<SuggestionsItem
						refsItems={refsItems}
						isActive={index == activeIndex}
						index={index}
						updateValue={updateValue}
						updateActiveIndex={updateActiveIndex}
						recentAction={recentAction}
					>
						{firm}
					</SuggestionsItem>
				))}
			</ContainerSuggestions>
		</motion.div>
	);
}

export default InputTextSelectSuggestions;
