import styled from "styled-components";
import { DEVICE } from "Constants";

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: auto;
	max-width: 536px;
	margin: 0 0 24px 0;
	padding: 0;

	@media (min-width: ${DEVICE.minDesktop}) {
		max-width: calc(266px + 32px);
		align-items: flex-end;
		padding: 8px 32px 0 0;
		/*background: rgba(0, 0, 255, 0.3);*/
	}
`;

const HeaderTitle = styled.div`
	width: 100%;
	height: 32px;
	background: ${({ theme }) => theme.colorTextPlaceholder};
	border-radius: 8px;
`;

const HeaderText = styled.div`
	width: 66%;
	height: 20px;
	background: ${({ theme }) => theme.colorTextPlaceholder};
	border-radius: 4px;
	margin: 8px 0 0 0;
`;

function IndividualReviewHeaderPlaceholder() {
	return (
		<Header>
			<HeaderTitle />
			<HeaderText />
			<HeaderText />
		</Header>
	);
}
export default IndividualReviewHeaderPlaceholder;
