import { useState, useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { H5 } from "components/Typography";

import Input from "../Input";
import InputLabel from "../InputLabel";
import InputDescription from "../InputDescription";
import InputField from "../InputField";
import InputError from "../InputError";
import InputErrorText from "../InputErrorText";
import InputPrivate from "../InputPrivate";

import InputTextSelectSuggestions from "./InputTextSelectSuggestions";

function InputSelectText({
	name,
	label,
	description,
	placeholder,
	defaultValue,
	onChange,
	updateOnChange,
	onFocus,
	onBlur,
	onTunnelVision,
	onTunnelVisionOff,
	index,
	isFaded,
	required,
	error,
	disabled,
	type,
	isTransparent,
	isPrivateEnabled,
}) {
	const theme = useContext(ThemeContext);
	const [value, setValue] = useState(defaultValue);
	const [freezedValue, setFreezedValue] = useState(defaultValue);
	const [delayedError, setDelayedError] = useState(error);
	const [valueOnDelayedError, setValueOnDelayedError] = useState(value);
	const [isPrivate, onChangePrivate] = useState(false);

	const [freezedSearchValue, setFreezedSearchValue] = useState("");
	const [activeIndex, setActiveIndex] = useState(-1);
	const [suggestionsCount, setSuggestionsCount] = useState(0);
	const [isFocused, setIsFocused] = useState(false);
	const [recentAction, setRecentAction] = useState("KEYBOARD");
	const [suggestionsListParent, setSuggestionsListParent] = useState([]);

	useEffect(() => {
		let shouldRenderLocal = value != "" && value != null && isFocused;

		// console.log("shouldRenderLocal?", value, isFocused);

		if (shouldRenderLocal) {
			onTunnelVision();
		} else {
			onTunnelVisionOff();
		}
	}, [value, isFocused]);

	useEffect(() => {
		onChangeHelper(defaultValue);
	}, [defaultValue]);

	useEffect(() => {
		// console.log('hasError?', value, error);
		if (error != "" && error != null) {
			setDelayedError(error);
			setValueOnDelayedError(value);
		}
	}, [error]);

	useEffect(() => {
		onChangeHelper(value);
	}, [isPrivate]);

	const onChangeHelper = (newValue) => {
		// console.log("onChangeHelper", newValue);
		setValue(newValue);
		setFreezedValue(newValue);
		setActiveIndex(-1);
		setRecentAction("KEYBOARD");
		setIsFocused(true);

		onChange(name, newValue + (isPrivate ? ";private/" : ""));
		if (updateOnChange)
			updateOnChange(name, isPrivate ? "비공개" : newValue);
	};

	const onKeyDown = (e) => {
		setRecentAction("KEYBOARD");

		if (suggestionsCount > 0) {
			if (e.keyCode == 40) {
				// DOWN
				setActiveIndex(
					activeIndex < suggestionsCount - 1 ? activeIndex + 1 : 0
				);

				if (!isFocused) setIsFocused(true);
			} else if (e.keyCode == 38) {
				// UP
				setActiveIndex(
					activeIndex > 0 ? activeIndex - 1 : suggestionsCount - 1
				);

				if (!isFocused) setIsFocused(true);
			} else if (e.keyCode == 13) {
				// ENTER
				e.preventDefault();

				onChangeHelper(value);
				setActiveIndex(-1);
				setIsFocused(false);
			} else {
				if (!isFocused) setIsFocused(true);
			}
		}
	};

	const handleFocusHelper = (index) => {
		setIsFocused(true);
		onFocus(index);
	};

	const handleBlurHelper = (index) => {
		setIsFocused(false);
		onBlur(index);
		if (onTunnelVisionOff) onTunnelVisionOff();
	};

	const updateValue = (value) => {
		setValue(value);
	};

	const updateActiveIndex = (index) => {
		setActiveIndex(index);
	};

	return (
		<Input isFaded={isFaded}>
			<InputLabel error={error} required={required}>
				<H5 opacity={0.66} isLabel={true}>
					{label}
				</H5>
			</InputLabel>
			{description && <InputDescription>{description}</InputDescription>}
			<InputField
				type={type ? type : "text"}
				placeholder={placeholder}
				onChange={(e) => onChangeHelper(e.target.value)}
				onFocus={() => handleFocusHelper(index)}
				onBlur={() => handleBlurHelper(index)}
				onKeyDown={onKeyDown}
				value={value}
				required={required}
				isFaded={isFaded}
				error={
					error != null && error != "" && valueOnDelayedError == value
				}
				disabled={disabled}
				isTransparent={isTransparent}
			/>
			{isPrivateEnabled && (
				<InputPrivate
					isPrivate={isPrivate}
					onChangePrivate={onChangePrivate}
				/>
			)}
			<InputError error={error}>
				<InputErrorText error={error}>{delayedError}</InputErrorText>
			</InputError>
			<InputTextSelectSuggestions
				activeIndex={activeIndex}
				updateActiveIndex={updateActiveIndex}
				value={freezedValue}
				updateValue={updateValue}
				suggestionsCount={suggestionsCount}
				setSuggestionsCount={setSuggestionsCount}
				isFocused={isFocused}
				recentAction={recentAction}
				setRecentAction={setRecentAction}
				setSuggestionsListParent={setSuggestionsListParent}
			/>
		</Input>
	);
}

export default InputSelectText;
