import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { API_ROOT } from "Constants";

const VCContext = React.createContext();

export function useVC() {
  return useContext(VCContext);
}

export function VCProvider({ children }) {
  const [VCNames, setVCNames] = useState([]);
  const [VCData, setVCData] = useState([]);

  useEffect(() => {
    axios.get(`${API_ROOT()}/vc/getVCList`).then((res) => {
      let VCList = res.data.list;
      // console.log("VCNames", Object.keys(VCList));
      // console.log("VCList", VCList);
      setVCNames(Object.keys(VCList));
      setVCData(VCList);
    });
  }, []);

  const value = {
    VCNames,
    VCData,
  };

  return <VCContext.Provider value={value}>{children}</VCContext.Provider>;
}
